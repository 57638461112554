import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  Grid,
  Stack,
  CircularProgress,
} from '@mui/material';

import { useCreateTasksMutation, useGetTasksQuery } from 'features/tasks/store/task.api';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useGetAreaToTagMappingQuery,
  useGetImportQuery,
  useGetTemplatesQuery,
  usePerformTemplateTaskMatchingMutation,
} from '../../api/imports.api';

import { useLaborEstimateItems } from '../../hooks/useLaborEstimateItems';
import { useAreaTasks } from '../../hooks/useAreaTasks';
import { useTaskMappings } from '../../hooks/useTaskMappings';
import { LaborEstimateItemsList } from './LaborEstimateItemsList';
import { AreaSelector } from '../AreaSelector';
import { TaskToLaborEstimates } from './TaskToLaborEstimates';
import { BarryTemplatesMenu } from '../BarryTemplatesMenu';
namespace Model {
  export interface LaborEstimateItem {
    id: number;
    ['tag:area']: string;
    ['task:name']: string;
    ['task:demand']: number;
  }
  export interface Task {
    id: number;
    name: string;
    demand: number;
  }
  export interface LaborEstimateToTask {
    id: number;
    task_id: number;
    labor_estimate_item_id: number;
    area_id: number;
    percentage: number;
  }
}

export function MatchLaborEstimateToSchedule() {
  const navigate = useNavigate();
  const { projectId, importId } = useParams();

  // const selectedLaborEstimateArea = '2A';
  // const selectedAreaTags = [{ name: 'Sector A', id: 39 }];

  const { data: allTasks = { entities: {} }, isLoading: isLoadingTasks } = useGetTasksQuery({
    projectId,
    include: 'tags',
  });
  const { data: importData, isLoading: isLoadingImport } = useGetImportQuery({
    backendImportId: importId,
  });

  const { data: templates = [] } = useGetTemplatesQuery({ backendImportId: importId });
  const [createTemplate] = useCreateTemplateMutation();
  const [deleteTemplate] = useDeleteTemplateMutation();

  const { mappings, addMapping, addMappings, removeMapping, updateMapping } =
    useTaskMappings<Model.LaborEstimateToTask>();

  const { data: areas = [] } = useGetAreaToTagMappingQuery({ backendImportId: importId });
  const [selectedLaborEstimateAreaId, setSelectedLaborEstimateAreaId] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (areas.length) {
      const [area] = areas;
      setSelectedLaborEstimateAreaId(area.id);
    }
  }, [areas]);

  const [selectedLaborEstimateArea] = areas.filter((a) => a.id === selectedLaborEstimateAreaId);
  const { filtered: laborEstimateItems, mapped: allLaborEstimateItems } =
    useLaborEstimateItems<Model.LaborEstimateItem>(
      importData,
      selectedLaborEstimateArea?.area_name
    );

  const tasks: any = useAreaTasks(
    allTasks,
    selectedLaborEstimateArea
      ? [{ name: selectedLaborEstimateArea.area_name, id: selectedLaborEstimateArea.tag_id }]
      : []
  );

  const outputTasks = tasks.map((t) => ({ id: t.id, name: t.name }));
  const outputLaborEstimateItems = laborEstimateItems.map((l) => ({
    id: l.id,
    'task:name': l['task:name'],
  }));

  function formatMappingsAsTemplate() {
    const outputMappings = mappings
      .filter((m) => m.area_id === selectedLaborEstimateAreaId)
      .map((m) => {
        const [task] = outputTasks.filter((t) => t.id === m.task_id);
        const [laborItem] = outputLaborEstimateItems.filter(
          (l) => l.id === m.labor_estimate_item_id
        );
        const percentage = m.percentage;
        return {
          task: {
            [task.id]: task.name,
          },
          laborItem: {
            [laborItem.id]: laborItem['task:name'],
          },
          percentage,
        };
      });

    return outputMappings;
  }

  async function handleSaveTemplate() {
    const areaName = selectedLaborEstimateArea.area_name;

    try {
      await createTemplate({
        backendImportId: importId,
        template: {
          name: areaName,
          template: formatMappingsAsTemplate(),
        },
      });
    } catch (e) {}
  }

  const [performTemplateMatching] = usePerformTemplateTaskMatchingMutation();
  const [isBarryThinking, setIsBarryThinking] = useState(false);
  const [isCreatingTasks, setIsCreatingTasks] = useState(false);

  async function handleApplyTemplate(templateId: any) {
    const [template] = templates.filter((t) => t.id === templateId);
    const tasks = outputTasks.reduce(
      (all, t) => ({
        ...all,
        [t.id]: t.name,
      }),
      {} as any
    );

    const laborItems = outputLaborEstimateItems.reduce(
      (all, l) => ({
        ...all,
        [l.id]: l['task:name'],
      }),
      {} as any
    );

    const mappings = template.template;
    const backendImportId = importId;

    setIsBarryThinking(true);
    try {
      const result = await performTemplateMatching({
        backendImportId,
        mappings,
        tasks,
        laborItems,
      });

      console.log(result);
      addMappings(
        result.data.map((r) => {
          const [taskId] = Object.keys(r.task);
          const [laborItemId] = Object.keys(r.laborItem);
          return {
            id: Date.now(),
            task_id: parseInt(taskId, 10),
            area_id: selectedLaborEstimateAreaId,
            labor_estimate_item_id: parseInt(laborItemId, 10),
            percentage: r.percentage,
          };
        })
      );
    } catch (e) {
    } finally {
      setIsBarryThinking(false);
    }
  }

  const [createTasks] = useCreateTasksMutation();

  function findParentTaskDates(taskId: number) {
    const task = allTasks.entities[taskId];
    if (!task) return null;

    return {
      startDate: task.scheduled_start,
      endDate: task.scheduled_end,
    };
  }

  async function handleFinishImport() {
    setIsCreatingTasks(true);
    try {
      const items = allLaborEstimateItems.reduce((all, item) => {
        return {
          ...all,
          [item.id]: item,
        };
      }, {});

      const tasksToCreate = mappings.map((m) => {
        const item = items[m.labor_estimate_item_id];
        const parentTask = findParentTaskDates(m.task_id);
        const parentStartDate = parentTask?.startDate ?? 1;
        const parentEndDate = parentTask?.endDate ?? 1;

        return {
          parent_id: m.task_id,
          demand: (item['task:demand'] * m.percentage) / 100,
          name: item['task:name'],
          project_id: projectId,
          optimal_crew: 1,
          scheduled_start: parentStartDate,
          scheduled_end: parentEndDate,
        };
      });

      console.log(tasksToCreate);

      await createTasks({ tasks: tasksToCreate });
      navigate('../../../../planning-board', { relative: 'path' });
    } catch (err) {
      console.log(err);
    } finally {
      setIsCreatingTasks(false);
    }
  }

  function handleOutputState() {
    console.log(JSON.stringify(mappings, null, 2));
  }

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <AreaSelector
          areas={areas}
          selectedAreaId={selectedLaborEstimateAreaId}
          onAreaChange={setSelectedLaborEstimateAreaId}
        />

        <Box sx={{ display: 'flex', gap: 2 }}>
          <BarryTemplatesMenu
            templates={templates}
            thinking={isBarryThinking}
            onApply={handleApplyTemplate}
            onSave={handleSaveTemplate}
          />

          <Button variant="contained" onClick={handleFinishImport}>
            Finish Import
          </Button>
        </Box>
      </Box>
      {isLoadingTasks && isLoadingImport ? (
        <CircularProgress size={80} />
      ) : (
        <Grid container spacing={4}>
          <Grid item md={5} xs={12}>
            <Typography variant="h6">Labor Estimate</Typography>
            <LaborEstimateItemsList items={laborEstimateItems} mappings={mappings} />
          </Grid>

          <Grid item md={7} xs={12}>
            <Paper className="p-4 bg-gray-50" elevation={0}>
              <Typography variant="h6">Tasks</Typography>
              <Stack spacing={1}>
                {tasks.map((task) => (
                  <TaskToLaborEstimates
                    items={laborEstimateItems}
                    key={task.id}
                    mappings={mappings}
                    task={task}
                    onChangeMapping={updateMapping}
                    onRemoveMapping={removeMapping}
                    onAddMapping={(mapping) =>
                      addMapping(task.id, selectedLaborEstimateAreaId, mapping)
                    }
                  />
                ))}
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      )}

      <Box>
        {/* <Button variant="outlined" onClick={() => navigate('../areas', { relative: 'path' })}>
          Back
        </Button>
        <Button variant="contained" onClick={() => navigate('../../')}>
          Done
        </Button> */}

        <Button onClick={handleOutputState}>OUTPUT STATE JSON</Button>
      </Box>
    </Container>
  );
}
