import { GanttConfigOptions, GanttStatic, WorkDaysTuple } from '@blackhyve/dhtmlx-gantt';
import { Resource } from 'features/resources/api/resources.models';

function renderTradeRoleChip(
  gantt: GanttStatic,
  trade_id: string | number,
  role_id: string | number
): string {
  const trade = gantt.getCollectionEntity('trade', trade_id);
  const role = gantt.getCollectionEntity('role', role_id);
  const tradeName = trade?.name || '';
  const roleName = role?.name || '';
  const tradeColor = trade?.color || '#f0f0f0';

  return `<span style="padding: 4px;line-height: 1em; font-size: .8em; font-weight: 500; border-radius: 12px; background-color: ${tradeColor}; color: white;">
    ${tradeName}${roleName ? ' -' + roleName : ''}
  </span>`;
}

export const getResourceConfig = (gantt: GanttStatic): Partial<GanttConfigOptions> => ({
  scale_height: 30,
  row_height: 30,
  static_background: false,
  open_tree_initially: false,
  columns: [
    {
      name: 'name',
      label: 'Name',
      tree: true,
      align: 'left',
      template: function (resource) {
        if (resource.$role === 'task') {
          const resourceRequest = gantt.getTask(gantt.getParent(resource.$task_id));
          if (!resourceRequest?.project_id) return 'Missing Project Name';

          const project = gantt.getCollectionEntity('project', resourceRequest.project_id);
          return `${project?.name ?? 'Unknown Project'}${resourceRequest.uuid ? ` - ${resourceRequest.uuid}` : ''}`;
        } else {
          const tradeRoleChips = resource.trades
            .map(({ trade_id, role_id }) => renderTradeRoleChip(gantt, trade_id, role_id))
            .join('');

          return `<div style="display: flex; align-items: center; gap: 4px;">
            <span style="margin-right: 8px; font-weight: 600;">${resource.text}</span>
            ${tradeRoleChips}
          </div>`;
        }
      },
      resize: true,
    },
  ],
});
