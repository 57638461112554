import { RouteObject, Navigate } from 'react-router-dom';
import { ProductTable } from '../components/ProductTable';

import { Box, Container, Typography, Button } from '@mui/material';
import { ContentLayout } from 'components/layouts';
import { Add } from '@mui/icons-material';
import { ProductFormDialog } from '../components/ProductFormDialog';
import { useState } from 'react';

const Product = () => {
  const [openAddProductDialog, setOpenAddProductDialog] = useState(false);

  return (
    <ContentLayout>
      <Container maxWidth="xl" sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5">Product List</Typography>
          <Button
            size="small"
            startIcon={<Add />}
            variant="contained"
            onClick={() => setOpenAddProductDialog(true)}
          >
            Add Product
          </Button>
        </Box>
        <ProductTable />
        {openAddProductDialog && (
          <ProductFormDialog
            creating
            handleClose={() => setOpenAddProductDialog(false)}
            open={openAddProductDialog}
          />
        )}
      </Container>
    </ContentLayout>
  );
};

export const productRoutes: RouteObject[] = [
  {
    path: '',
    children: [{ index: true, element: <Product /> }],
  },
  { path: '*', element: <Navigate to={'.'} /> },
];
