export function transformToTable<T extends { row_index: number; column_index: number }>(
  cells: T[]
): T[][] {
  if (!cells.length) return [];

  const maxRow = Math.max(...cells.map((c) => c.row_index));
  const maxCol = Math.max(...cells.map((c) => c.column_index));

  const table: T[][] = Array(maxRow + 1)
    .fill(undefined)
    .map(() => Array(maxCol + 1).fill(undefined));

  cells.forEach((cell) => {
    table[cell.row_index][cell.column_index] = cell;
  });

  return table;
}

export function transformScheduleToTable<T extends { row: number; column: number; page?: number }>(
  cells: T[]
): T[][] {
  if (!cells.length) return [];

  const cellsByPage = cells.reduce(
    (acc, cell) => {
      const page = cell.page ?? 0;
      if (!acc[page]) acc[page] = [];
      acc[page].push(cell);
      return acc;
    },
    {} as Record<number, T[]>
  );

  const sortedPages = Object.keys(cellsByPage)
    .map(Number)
    .sort((a, b) => a - b);

  const pages = sortedPages.map((pageNum) => {
    const pageCells = cellsByPage[pageNum];

    // Get max dimensions for this page
    const maxRow = Math.max(...pageCells.map((c) => c.row));
    const maxCol = Math.max(...pageCells.map((c) => c.column));

    const table: T[][] = Array(maxRow + 1)
      .fill(undefined)
      .map(() => Array(maxCol + 1).fill(undefined));

    pageCells.forEach((cell) => {
      table[cell.row][cell.column] = cell;
    });

    table.shift(); // Remove first row
    return table;
  });

  return pages.reduce((acc, page) => [...acc, ...page], []);
}
