import {
  Box,
  Paper,
  IconButton,
  Typography,
} from '@mui/material';
import { forwardRef, useState } from 'react';
import { addWeeks, getWeek } from 'date-fns';
import { useSelector } from 'react-redux';
import { Print } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { StyledDialog } from '@blackhyve/common';
import { selectCurrentUserId } from 'features/auth';
import { useGetProjectQuery } from 'features/projects/store/project.api';
import { useGetWorkspacesQuery } from 'features/workspace/api/workspace.api';
import { useGanttCustomPrint } from 'features/gantt/hooks/useGanttCustomPrint';
import PreviewPDF from 'components/projectOverview/gantt/components/dialog/PrintComponents/PreviewPdf';
import PdfSkeleton from 'components/projectOverview/gantt/components/dialog/PrintComponents/PdfSkeleton';
import { calculateColumnWidth, createPdfPayload, extractChunkNo } from 'components/projectOverview/gantt/ganttConfig/printHelper';


const PRINT_CONFIGS = {
  LAYOUT: 'landscape',
  PAGE_FORMAT: 'Tabloid',
  GRID_CONFIGS: {
    DEFAULT_TEXT_WIDTH: 390,
    PRINT_TEXT_WIDTH: 490,
  }
};

const LookaheadPrintMenu = forwardRef(({ gantt, ganttDate, lookaheadWeeks }, ref) => {

  const { projectId } = useParams();
  const [printingState, setPrintingState] = useState({})
  const userId = useSelector((state) => selectCurrentUserId(state));
  const { data: workspaces = [] } = useGetWorkspacesQuery({ userId });
  const currentWorkspaceId = useSelector((state) => state.auth.workspaceId);
  const { data: project, isLoading: isLoadingProject } = useGetProjectQuery(projectId);
  const currentWorkspace = workspaces.find((workspace) => workspace.id == currentWorkspaceId);

  const pdfName = `${project.name}-lookahead-Week-${getWeek(ganttDate)}`

  const handlePrint = () => {
    setPrintingState({ open: true, message: 'Preparing layout for printing' })

    // Configure gantt grid for printing
    gantt.getGridColumn("action").hide = true;
    gantt.getGridColumn("text").width = PRINT_CONFIGS.GRID_CONFIGS.DEFAULT_TEXT_WIDTH;

    const columnWidth = calculateColumnWidth({
      startDate: ganttDate,
      lookaheadWeeks,
      pageFormat: PRINT_CONFIGS.PAGE_FORMAT,
      layout: PRINT_CONFIGS.LAYOUT,
    })


    const payload = createPdfPayload({
      printTitle: project.name,
      pageFormat: PRINT_CONFIGS.PAGE_FORMAT,
      layout: PRINT_CONFIGS.LAYOUT,
      logo: currentWorkspace?.logo?.original_url,
      isFitToPage: true,
      startDate: ganttDate,
      endDate: addWeeks(ganttDate, lookaheadWeeks),
    })

    setPrintingState((prev) => ({ ...prev, message: 'Preparing pdf' }))
    gantt.exportToPDF({
      ...payload,
      timescaleColumnWidth: columnWidth,
      is_lookahead: true,
      zoomLevel: 0,
      callback: function (res) {
        setPrintingState((prev) => ({ ...prev, message: 'PDF is ready' }))
        // Reset gantt grid
        gantt.getGridColumn("action").hide = false;
        gantt.getGridColumn("text").width = 390;
        gantt.init(ref.current)

        if (res?.fileChunks) {
          res.fileChunks.sort((a, b) => extractChunkNo(a) - extractChunkNo(b));
        }

        setPrintingState((prev) => ({
          ...prev,
          url: `${res.url}.pdf`,
          fileChunks: res.fileChunks
        }));

      }
    });
  }

  useGanttCustomPrint(gantt)


  return <>
    <IconButton size={'small'} onClick={handlePrint}>
      <Print />
    </IconButton>
    {printingState?.open &&
      <StyledDialog
        handleClose={() => setPrintingState(null)}
        maxWidth="lg"
        open={printingState?.open}
        title="Printing Lookahead"
      >
        <Typography style={{ marginBottom: "1rem" }} variant="body1">
          {printingState?.message}
        </Typography>
        <Box>

          <Paper sx={{ width: '100%' }}>
            {printingState?.url ?
              <PreviewPDF
                pdfName={pdfName}
                chunks={printingState.fileChunks}
                url={printingState.url}
              /> :
              <PdfSkeleton />
            }
          </Paper>
        </Box>
      </StyledDialog>}
  </>
})

export default LookaheadPrintMenu;