import { useParams } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import { ContentLayout } from 'components/layouts';
import { ImportDropBox } from '../components/ImportDropBox';
import { ImportsTable } from '../components/ImportsTable';
import { useGetAllImportsQuery } from '../api/imports.api';

export function Imports() {
  const projectId = Number(useParams().projectId) || undefined;
  const {
    data: imports = [],
    refetch: refetchImports,
    isLoading,
  } = useGetAllImportsQuery(
    { projectId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <ContentLayout>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Typography variant="h4">Imports</Typography>
          {projectId && (
            <Box pt={2}>
              <ImportDropBox projectId={projectId} />
            </Box>
          )}
          <Box pt={2}>
            <Typography variant="h6">Past Imports</Typography>
            <ImportsTable
              imports={imports}
              isLoading={isLoading}
              TableProps={{
                defaultOrder: 'asc',
              }}
              onPoll={refetchImports}
            />
          </Box>
        </Box>
      </Container>
    </ContentLayout>
  );
}
