import { api, providesList } from 'api';
import { Product } from '../types/product.models';

export const PRODUCT_TAG = 'Product';

const productAPI = api.enhanceEndpoints({ addTagTypes: [PRODUCT_TAG] }).injectEndpoints({
  endpoints: (build) => ({
    getProducts: build.query<Product[], void>({
      query: () => `/products`,
      providesTags: (result, error, args) => (result ? providesList(result, PRODUCT_TAG) : []),
      transformResponse: (response: { data: Product[] }) => response.data,
    }),

    createProduct: build.mutation<Product, Product>({
      query: (body) => ({
        url: `/products`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, _error, params) =>
        result ? [{ type: PRODUCT_TAG, id: 'LIST' }] : [],
    }),

    deleteProduct: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/products/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: PRODUCT_TAG }],
    }),

    updateProduct: build.mutation<Product, Product>({
      query: ({ id, ...body }) => ({
        url: `/products/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, _error, params) =>
        result
          ? [
              { type: PRODUCT_TAG, id: 'LIST' },
              { type: PRODUCT_TAG, id: params.id },
            ]
          : [],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
} = productAPI;
