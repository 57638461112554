import { useState } from 'react';
import Edit from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import ActionComponent from 'components/projectOverview/gantt/ganttConfig/column/actionColumn';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import ContextMenuComponent from '../components/ContextMenu';
import EditPredecessorDialog from 'components/projectOverview/gantt/components/dialog/EditPredecessorDialog';
import { handleSyncDependency } from 'features/drawers/components/SelectDependency';
import { useDispatch } from 'react-redux';
import { syncDependency } from '../store/recipeDependenciesSlice';
import Stack from '@mui/material/Stack';
import Tag from 'features/tags/components/Tag';
import Typography from '@mui/material/Typography';
import { loadRecipeTagsMenuType } from '../components/RecipeTagMenu';

const tagIconString = `<svg width="24" height="24" style="pointer-events: none;" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="label_24px 2"><path id="icon/action/label_24px 2" fill-rule="evenodd" clip-rule="evenodd" d="M15.5 5C16.17 5 16.77 5.33 17.13 5.84L21.5 12L17.13 18.16C16.77 18.67 16.17 19 15.5 19L4.5 18.99C3.40002 18.99 2.5 18.1 2.5 17V7C2.5 5.9 3.40002 5.01 4.5 5.01L15.5 5ZM4.5 17H15.5L19.05 12L15.5 7H4.5V17Z" fill="black" fill-opacity="0.54"/></g></svg>`;

const formatterLabels = {
  minute: {
    full: 'minute',
    plural: 'minutes',
    short: 'min',
  },
  hour: {
    full: 'hour',
    plural: 'hours',
    short: 'h',
  },
  day: {
    full: 'day',
    plural: 'days',
    short: 'd',
  },
  week: {
    full: 'week',
    plural: 'weeks',
    short: 'w',
  },
  month: {
    full: 'month',
    plural: 'months',
    short: 'm',
  },
  year: {
    full: 'year',
    plural: 'years',
    short: 'y',
  },
};

export const getRecipeGanttColumns = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  const formatter = gantt.ext.formatters.durationFormatter({
    enter: 'day',
    store: 'day',
    format: 'day',
    labels: formatterLabels,
  });
  const linksFormatter = gantt.ext.formatters.linkFormatter({ durationFormatter: formatter });
  const recipeGanttColumns = [
    {
      name: 'action',
      label: '',
      width: 120,
      template: gantt?.getWBSCode,
      onrender: (item, node) => {
        return (
          <ActionComponent ganttId={ganttId} isCheckBox={false} item={item} key={item.id}>
            {({ anchor, handleClose, id }) => {
              return (
                <ContextMenuComponent
                  anchor={anchor}
                  ganttId={ganttId}
                  handleClose={handleClose}
                  id={id}
                />
              );
            }}
          </ActionComponent>
        );
      },
    },
    {
      name: 'text',
      label: 'Name',
      align: 'left',
      width: 200,
      editor: { type: 'text', map_to: 'text' },
      tree: true,
      resize: true,
    },
    {
      name: 'percentage',
      label: '<div class="gantt_grid_head_cell_two_lines"> % of Recipe<br/>Duration</div>',
      width: 100,
      resize: true,
      template: (item) => {
        if (item.type === 'placeholder') {
          return `<span class="cell_disabled flex"></span>`;
        }
        return `<span class="${item.id !== -1 ? 'cell_disabled' : undefined}">${
          item.percentage ? parseFloat(item.percentage).toFixed(2) : ''
        }</span>`;
      },
    },
    {
      name: 'duration',
      label: 'Duration',
      align: 'center',
      width: 100,
      editor: { type: 'number', map_to: 'duration', min: 1 },
      resize: true,
      resize: true,
    },
    { name: 'start_date', label: 'Start Date', hide: true },
    {
      name: 'predecessors',
      label: 'Predecessors',
      width: 150,
      align: 'left',
      editor: { type: 'predecessor', map_to: 'auto', formatter: linksFormatter },
      resize: true,
      sort: false,
      hide: false,
      canToggle: true,
      onrender: (item, node) => {
        const labels = item.$target.map((linkId) => linksFormatter?.format(gantt.getLink(linkId)));
        return (
          <RecipePredecessorsCell gantt={gantt} key={`${item.id}-predecessors-column`} task={item}>
            {labels}
          </RecipePredecessorsCell>
        );
      },
    },
    {
      name: 'fixed_duration',
      label: 'Insert Duration As',
      align: 'center',
      width: 200,
      resize: true,
      editor: {
        type: 'select',
        map_to: 'fixed_duration',
        options: [
          { key: 'false', label: 'Percentage' },
          { key: 'true', label: 'Duration' },
        ],
      },
      template: (task) => {
        if (task.type === 'placeholder') {
          return `<span class="cell_disabled flex"></span>`;
        }
        if (task.hasOwnProperty('fixed_duration')) {
          return task.fixed_duration === 'true' ? 'Duration' : 'Percentage';
        }
      },
    },
    {
      onClick: loadRecipeTagsMenuType(ganttId),
      name: 'tags',
      label: 'Tags',
      align: 'center',
      resize: true,
      width: '250',
      canToggle: true,
      hide: false,
      editor: { type: 'tags', map_to: 'auto' },
      onrender: (item, node) => {
        if (item.id === -1 || gantt.config.readonly) {
          return `<span class="cell_disabled flex" style="opacity: 0.3"> ${tagIconString}</span>`;
        }
        const tags = item?.tags;
        return tags?.length ? (
          <Stack
            alignItems={'center'}
            direction="row"
            key={`tag-${item.id}-0`}
            spacing={0.5}
            sx={{ height: '20px' }}
          >
            {tags?.slice(0, 2).map((tag) => {
              return (
                <Tag
                  color={tag.color}
                  key={tag.id}
                  name={tag.name}
                  size="small"
                  type={tag.type}
                  tagProps={{
                    fontSize: '12px',
                    padding: 0,
                    minHeight: '20px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '125px',
                  }}
                />
              );
            })}
            {tags?.length > 2 ? (
              <Typography fontSize="small" sx={{ display: 'flex', alignItems: 'center' }}>
                +{tags.length - 2}{' '}
              </Typography>
            ) : (
              ''
            )}
          </Stack>
        ) : (
          tagIconString
        );
      },
    },
  ];
  return recipeGanttColumns;
};

const RecipePredecessorsCell = ({ gantt, task, children }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isPredecessorDialogOpen, setIsPredecessorDialogOpen] = useState(false);

  const disabled = task.readonly === true || gantt.config.readonly;
  const tasks = gantt.getTaskBy('recipe_id', task.recipe_id);

  const handleUpdate = async (selectedDependencies) => {
    setIsLoading(true);
    const response = await dispatch(
      syncDependency({
        dependencies: selectedDependencies,
        taskId: task.id,
      })
    ).unwrap();
    setIsLoading(false);
    handleSyncDependency(gantt, response);
  };

  return (
    <div className={`predecessor-column ${disabled ? 'cell_disabled' : ''}`}>
      <span className="predecessor-label">{children}</span>
      <span className="edit-span">
        <IconButton
          size="small"
          sx={{ p: 0 }}
          onClick={(event) => {
            event.stopPropagation();
            setIsPredecessorDialogOpen(true);
          }}
        >
          <Edit color={'inherit'} />
        </IconButton>
        {isPredecessorDialogOpen && (
          <EditPredecessorDialog
            gantt={gantt}
            handleClose={() => setIsPredecessorDialogOpen(false)}
            handleUpdate={handleUpdate}
            isLoading={isLoading}
            open={true}
            taskId={task.id}
            tasks={tasks}
          />
        )}
      </span>
    </div>
  );
};
