import { api } from 'api';
import { Import, CreatedImportResponse, ImportType } from './imports.models';
import axios from 'axios';

export const IMPORT_TAG = 'Import';
export const AREA_TAG = 'AreaTag' as const;
export const BARRY_TEMPLATE_TAG = 'BarryTemplate' as const;

export const importsAPI = api
  .enhanceEndpoints({ addTagTypes: [IMPORT_TAG, AREA_TAG, BARRY_TEMPLATE_TAG] })
  .injectEndpoints({
    endpoints: (build) => ({
      getAllImports: build.query({
        query: ({ projectId }) => ({
          url: `/projects/${projectId}/barry/extracts`,
        }),
        providesTags: (result) => (result ? [{ type: 'Import', id: 'LIST' }] : []),
        transformResponse: (response: { data: Import[] }) => response.data,
      }),

      getImport: build.query<Import, any>({
        query: ({ backendImportId }) => ({
          url: `/barry/extracts/${backendImportId}`,
        }),
        // providesTags: (result) => (result ? [{ type: 'Import', id: 'LIST' }] : []),
        transformResponse: (response: {
          data: Import & { url?: string };
          url: { url: string };
        }) => {
          response.data.previewUrl = response.url.url; // Merge previewUrl into response.data
          return response.data;
        },
      }),

      createImport: build.mutation<
        CreatedImportResponse,
        { projectId: number; type: ImportType; name: string }
      >({
        query: ({ projectId, type, name }) => ({
          url: `/projects/${projectId}/barry/extracts`,
          method: 'POST',
          body: { type, name },
        }),
        invalidatesTags: [{ type: IMPORT_TAG, id: 'LIST' }],
      }),

      updateImport: build.mutation<any, { backendImportId: number; updateData: any }>({
        query: ({ backendImportId, updateData }) => ({
          url: `/barry/extracts/${backendImportId}`,
          method: 'PATCH',
          body: updateData,
        }),
      }),

      getAreaToTagMapping: build.query({
        query: ({ backendImportId }) => ({
          url: `/barry/extracts/${backendImportId}/areas`,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }: { id: number }) => ({
                  type: AREA_TAG,
                  id,
                })),
                { type: AREA_TAG, id: 'LIST' },
              ]
            : [{ type: AREA_TAG, id: 'LIST' }],
        transformResponse: (response: {
          data: Array<{
            id: number;
            area_name: string;
            tag_id: number | null;
            barry_extract_id: number;
          }>;
        }) => response.data,
      }),

      getTemplates: build.query<any[], { backendImportId: any }>({
        query: ({ backendImportId }) => ({
          url: `/barry/extracts/${backendImportId}/tasks/templates`,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: BARRY_TEMPLATE_TAG, id })),
                { type: BARRY_TEMPLATE_TAG, id: 'LIST' },
              ]
            : [{ type: BARRY_TEMPLATE_TAG, id: 'LIST' }],
        transformResponse: (response: { data: any[] }) => response.data,
      }),

      createTemplate: build.mutation<
        any,
        { backendImportId: any; template: { name: string; template: any } }
      >({
        query: ({ backendImportId, template }) => ({
          url: `/barry/extracts/${backendImportId}/tasks/templates`,
          method: 'POST',
          body: template,
        }),
        invalidatesTags: [{ type: BARRY_TEMPLATE_TAG, id: 'LIST' }],
      }),

      deleteTemplate: build.mutation<void, { backendImportId: any; templateId: number }>({
        query: ({ backendImportId, templateId }) => ({
          url: `/barry/extracts/${backendImportId}/tasks/templates/${templateId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [{ type: BARRY_TEMPLATE_TAG, id: 'LIST' }],
      }),

      performTemplateAreaMatching: build.mutation<
        any,
        { backendImportId: any; matchingData: any, }
      >({
        query: ({ backendImportId, matchingData }) => ({
          url: `/barry/extracts/${backendImportId}/areas/match`,
          method: 'POST',
          body: matchingData,
        }),
      }),
      performTemplateTaskMatching: build.mutation<
        any,
        { backendImportId: any; mappings: any; tasks: any; laborItems: any }
      >({
        query: ({ backendImportId, mappings, tasks, laborItems }) => ({
          url: `/barry/extracts/${backendImportId}/tasks/match`,
          method: 'POST',
          body: {
            mappings,
            tasks,
            labor_items: laborItems,
          },
        }),
      }),
      createLaborEstimateAreas: build.mutation<any, { backendImportId: number; createData: any }>({
        query: ({ backendImportId, createData }) => ({
          url: `/barry/extracts/${backendImportId}/areas`,
          method: 'POST',
          body: createData,
        }),
      }),
    }),
  });

export const {
  useGetAllImportsQuery,
  useGetImportQuery,
  useGetAreaToTagMappingQuery,
  useCreateImportMutation,
  useUpdateImportMutation,
  useCreateLaborEstimateAreasMutation,
  useGetTemplatesQuery,
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  usePerformTemplateAreaMatchingMutation,
  usePerformTemplateTaskMatchingMutation,
} = importsAPI;

export async function uploadFileToS3(url: string, file: File): Promise<void> {
  try {
    await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
    console.log('File uploaded successfully.');
  } catch (error) {
    console.error('File upload failed:', error);
    throw new Error('Failed to upload the file to S3.');
  }
}
