import { api, providesList } from 'api';
import { Section } from '../types/section.models';
import { ESTIMATE_TAG } from './estimate.api';

export const SECTION_TAG = 'Section';
export const SECTION_DETAIL_TAG = 'SectionDetail';

const sectionAPI = api
  .enhanceEndpoints({ addTagTypes: [SECTION_TAG, SECTION_DETAIL_TAG, ESTIMATE_TAG] })
  .injectEndpoints({
    endpoints: (build) => ({
      getSections: build.query<Section[], { estimateId: number }>({
        query: ({ estimateId }) => ({
          url: `estimates/${estimateId}/sections`,
          method: 'GET',
        }),
        providesTags: (result, error, args) =>
          result?.length
            ? [
                ...providesList(result, SECTION_TAG),
                { type: SECTION_TAG, id: `ESTIMATE_${args.estimateId}` },
              ]
            : [{ type: SECTION_TAG, id: `ESTIMATE_${args.estimateId}` }],
        transformResponse: (response: { data: Section[] }) => response.data,
      }),

      getSection: build.query<Section, { estimateId: number; id: number }>({
        query: ({ estimateId, id }) => ({
          url: `estimates/${estimateId}/sections/${id}`,
          method: 'GET',
        }),
        providesTags: (result, error, args) =>
          result
            ? [
                { type: SECTION_TAG, id: args.id },
                { type: SECTION_DETAIL_TAG, id: args.id },
              ]
            : [],
        transformResponse: (response: { data: Section }) => response.data,
      }),

      createSection: build.mutation<Section, Section>({
        query: (body) => ({
          url: `estimates/${body.estimate_id}/sections`,
          method: 'POST',
          body,
        }),
        invalidatesTags: (result, _error, arg) =>
          result
            ? [
                { type: SECTION_TAG, id: `ESTIMATE_${arg.estimate_id}` },
                { type: ESTIMATE_TAG, id: arg.estimate_id },
              ]
            : [],
        transformResponse: (response: { data: Section }) => response.data,
      }),

      updateSection: build.mutation<Section, Section>({
        query: (body) => ({
          url: `estimates/${body.estimate_id}/sections/${body.id}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: (result, _error, args) =>
          result
            ? [
                { type: SECTION_TAG, id: args.id },
                { type: SECTION_DETAIL_TAG, id: args.id },
                { type: SECTION_TAG, id: `ESTIMATE_${args.estimate_id}` },
                { type: ESTIMATE_TAG, id: args.estimate_id },
              ]
            : [],
        transformResponse: (response: { data: Section }) => response.data,
      }),

      deleteSection: build.mutation<void, { id: number; estimateId: number }>({
        query: ({ id, estimateId }) => ({
          url: `/estimates/${estimateId}/sections/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, _error, args) => [
          { type: SECTION_TAG, id: 'LIST' },
          { type: SECTION_TAG, id: `ESTIMATE_${args.estimateId}` },
          { type: ESTIMATE_TAG, id: args.estimateId },
        ],
      }),
    }),
  });

export const {
  useGetSectionsQuery,
  useGetSectionQuery,
  useCreateSectionMutation,
  useUpdateSectionMutation,
  useDeleteSectionMutation,
} = sectionAPI;
