import { GanttStatic, GanttTemplates } from '@blackhyve/dhtmlx-gantt';
import { parseDate } from '@blackhyve/utilities/dates';
import { Interval, isWithinInterval, addDays } from 'date-fns';
import {
  getAssignedResourceRollup,
  getOpenRequestCount,
  attachCacheInvalidationEvents,
} from '../utils/resourceAllocationHelper';

export const templates = (gantt: GanttStatic): Partial<GanttTemplates> => {
  // Attaches events to invalidate the resource roll up count
  attachCacheInvalidationEvents(gantt);

  return {
    task_text: (startDate, endDate, task) => {
      return '';
    },

    timeline_cell_class: (task, date) => {
      // Check if in resource grid or timeline. Could probably find a better way to do this
      if (Boolean(task.type)) {
        if (!gantt.isWorkTime({ date: date, task: task })) return 'weekend';
      } else {
        const calendarId = gantt.config.resource_calendars[task.id];
        const calendar = gantt.getCalendar(typeof calendarId === 'object' ? undefined : calendarId);
        if (calendar && !calendar.isWorkTime({ date: date })) {
          return 'weekend';
        }
      }
      return '';
    },

    timeline_cell_content: (task, date) => {
      if (task.type === gantt.config.types.request_status) {
        const { requiredResourceCount, allocatedResourceCount } = getOpenRequestCount(
          gantt,
          task.id,
          date
        );
        return formatResourceCell(allocatedResourceCount, requiredResourceCount);
      }

      if (task.type === gantt.config.types.resource_request) {
        const dateInterval: Interval = {
          start: parseDate(task.start_date),
          end: addDays(parseDate(task.end_date), -1),
        };

        if (isWithinInterval(date, dateInterval)) {
          const allocatedResourceCount = getAssignedResourceRollup(gantt, task.id, date);
          return formatResourceCell(allocatedResourceCount, task.resource_count);
        }
      }
      return '';
    },

    resource_cell_value: function (startDate, endDate, resource, tasks, assignments) {
      if (resource.$role === gantt.config.types.task) {
        return '';
      } else {
        // let sum = assignments.reduce((total, assignment) => {
        //   return total + Number(assignment.value);
        // }, 0);

        const cellClass =
          assignments.length <= 1 ? 'resource_cell--at-capacity' : 'resource_cell--over-capacity';

        return `<div class="resource_cell ${cellClass}">${assignments.length}/${1}</div>`;
      }
    },

    resource_cell_class: function (startDate, endDate, resource, tasks, assignments) {
      const classes: string[] = [];
      classes.push('gantt_resource_marker');

      if (resource.$role === gantt.config.types.task) {
        classes.push('task_cell');
      } else {
        classes.push('resource_cell');
      }

      return classes.join(' ');
    },
  };
};

const formatResourceCell = (allocated: number | string, required: number | string) => {
  const cellClass =
    allocated > required
      ? 'resource_cell--over-capacity'
      : allocated < required
        ? 'resource_cell--under-capacity'
        : 'resource_cell--at-capacity';
  return `<div class='resource-request-cell-value' style="padding: 4px; box-sizing: border-box;">
          <div class="resource_request_cell ${cellClass}">${allocated}/${required}
          </div>
  </div>`;
};
