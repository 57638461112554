import React from 'react';
import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import { OperationsBoard } from './OperationsBoard';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { SecondaryNav } from 'components/navigation/SecondaryNav';
import ModalProvider from 'components/common/ModalProvider';

export const OperationsLayout: React.FC = () => {
  return (
    <ModalProvider>
      <ContentLayout header={<SecondaryNav tabs={[]} title={'Operations'} />}>
        <Outlet />
      </ContentLayout>
    </ModalProvider>
  );
};

export const operationsRoutes: RouteObject[] = [
  {
    path: '',
    element: <OperationsLayout />,
    children: [{ index: true, element: <OperationsBoard /> }],
  },
  { path: '*', element: <Navigate to={'.'} /> },
];
