import { Box, BoxProps, Stack, Typography, TypographyProps } from '@mui/material';

import { formatCurrency } from 'helpers/numberFormatter';

interface MetricItemProps {
  label: string;
  value: number;
  color?: string;
  margin?: number;
  valueColor?: TypographyProps['color'];
}

export const MetricItem: React.FC<MetricItemProps> = ({
  label,
  value,
  valueColor = 'text.primary',
  margin,
}) => (
  <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
    <Typography color={'text.secondary'} component={'span'} fontSize={'0.875rem'}>
      {label}
    </Typography>
    <Box>
      <Typography color={valueColor} component={'span'} fontWeight={600}>
        {formatCurrency(value)}
      </Typography>
      {margin !== undefined && (
        <Typography
          color={valueColor}
          component={'span'}
          fontSize={'0.875rem'}
          sx={{
            ml: 1,
          }}
        >
          ({margin.toFixed(2)}%)
        </Typography>
      )}
    </Box>
  </Stack>
);
