import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';

export function LaborEstimateItemsList({ items, mappings }) {
  const getScheduled = (item) => {
    return mappings
      .filter((m) => m.labor_estimate_item_id === item.id)
      .reduce((total, i) => total + (item['task:demand'] * i.percentage) / 100, 0);
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight={600}>Task</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography fontWeight={600}>Estimated</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography fontWeight={600}>Scheduled</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => {
              const scheduled = getScheduled(item);
              return (
                <TableRow key={item.id}>
                  <TableCell>{item['task:name']}</TableCell>
                  <TableCell align="right">{item['task:demand'].toFixed(1)}</TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: scheduled < item['task:demand'] ? 'error.main' : 'success.main',
                    }}
                  >
                    {scheduled.toFixed(1)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
