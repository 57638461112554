import { api, providesList } from 'api';
import { Estimate, EstimateGraphData, NewEstimate } from '../types/estimate.models';

export const ESTIMATE_TAG = 'Estimate';
export const ESTIMATE_GRAPH_TAG = 'EstimateGraphData';

const estimateApi = api
  .enhanceEndpoints({ addTagTypes: [ESTIMATE_TAG, ESTIMATE_GRAPH_TAG] })
  .injectEndpoints({
    endpoints: (build) => ({
      getEstimates: build.query<Estimate[], { params? }>({
        query: ({ params }) => ({
          url: `/estimates/`,
          params,
        }),
        providesTags: (result, error, args) => (result ? providesList(result, ESTIMATE_TAG) : []),
        transformResponse: (response: { data: Estimate[] }) => response.data,
      }),

      getEstimate: build.query<Estimate, { id: number }>({
        query: ({ id }) => `/estimates/${id}`,
        providesTags: (result, error, args) =>
          result ? [{ id: result.id, type: ESTIMATE_TAG }] : [],
        transformResponse: (response: { data: Estimate }) => response.data,
      }),

      createEstimate: build.mutation<Estimate, NewEstimate>({
        query: (newEstimate) => ({
          url: `/estimates`,
          body: newEstimate,
          method: 'post',
        }),
        invalidatesTags: (result, error, args) =>
          result ? [{ id: 'LIST', type: ESTIMATE_TAG }] : [],
        transformResponse: (response: { data: Estimate }) => response.data,
      }),

      updateEstimate: build.mutation<Estimate, Pick<Estimate, 'id'> & Partial<Estimate>>({
        query: ({ id, ...patch }) => ({
          url: `/estimates/${id}`,
          body: patch,
          method: 'put',
        }),
        invalidatesTags: (result, error, args) =>
          result ? [{ id: result.id, type: ESTIMATE_TAG }] : [],
        transformResponse: (response: { data: Estimate }) => response.data,
      }),

      deleteEstimate: build.mutation<void, { id: number }>({
        query: ({ id }) => ({
          url: `/estimates/${id}`,
          method: 'delete',
        }),
        invalidatesTags: (result, error, args) =>
          result ? [{ id: args.id, type: ESTIMATE_TAG }] : [],
      }),

      getEstimateGraphData: build.query<EstimateGraphData, { estimateId: number; params? }>({
        query: ({ estimateId, params }) => ({
          url: `/estimates/${estimateId}/graph`,
          params,
        }),
        providesTags: (result, error, args) => [{ id: args.estimateId, type: ESTIMATE_TAG }],
        transformResponse: (response: { data: EstimateGraphData }) => response.data,
      }),
    }),
  });

export const {
  useGetEstimatesQuery,
  useGetEstimateQuery,
  useCreateEstimateMutation,
  useUpdateEstimateMutation,
  useDeleteEstimateMutation,
  useGetEstimateGraphDataQuery,
} = estimateApi;
