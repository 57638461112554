import { StyledDialog } from '@blackhyve/common';
import { GanttStatic } from '@blackhyve/dhtmlx-gantt';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import {
  useSyncResourceAssignmentsMutation,
  useUpdateResourceRequestMutation,
} from 'features/resources/api/resourceRequest.api';
import {
  NewResourceProjectAssignment,
  ResourceRequestStatus,
  ResourceRequestTask,
} from 'features/resources/api/resources.models';
import { useSnackbar } from 'notistack';

export const ResourceRequestUpdateDialog = ({
  open,
  gantt,
  id,
  handleClose,
}: {
  open: boolean;
  id: number;
  gantt: GanttStatic;
  handleClose: any;
}) => {
  const resourceRequest = gantt.getTask(id);

  //@ts-ignore
  const { enqueueSnackbar } = useSnackbar();
  const [syncResourceRequestAssignment, { isLoading: isUpdatingAssignment }] =
    useSyncResourceAssignmentsMutation();
  const [updateResourceRequest, { isLoading: isUpdatingResourceRequest }] =
    useUpdateResourceRequestMutation();

  const handleSubmit = () => {
    const assignmentIds = gantt.getChildren(resourceRequest.id);

    const assignments: NewResourceProjectAssignment[] = assignmentIds.map((assignmentId) => {
      const assignmentTask: ResourceRequestTask = gantt.getTask(assignmentId);
      return {
        project_id: 1,
        resource_request_id: 1,
        resource_id: Number(assignmentTask.resourceId),
        start_date: assignmentTask.start_date
          ? format(assignmentTask.start_date, 'yyyy-MM-dd')
          : '',
        end_date: assignmentTask.end_date ? format(assignmentTask.end_date, 'yyyy-MM-dd') : '',
      };
    });

    Promise.all([
      syncResourceRequestAssignment({ resourceRequestId: id, assignments: assignments }),
      updateResourceRequest({ id, status: ResourceRequestStatus.Closed }),
    ])
      .then(() => {
        gantt.deleteTask(id);
        enqueueSnackbar('Request updated successfully', { variant: 'success' });
      })
      .catch((error) => {
        console.error('Error =>', error);
        enqueueSnackbar('Error while updating request, please try again.', { variant: 'error' });
      });
  };

  const isLoading = isUpdatingAssignment || isUpdatingResourceRequest;

  return (
    <StyledDialog handleClose={handleClose} open={open} title="Confirm Save and Close">
      <Stack alignItems={'center'} justifyContent={'center'} spacing={1}>
        <Typography>
          Are you sure you want to close <b>{resourceRequest?.uuid}</b>
        </Typography>
        <Typography>This action cannot be undone.</Typography>
        <Stack direction={'row'} spacing={1}>
          <Button disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            color={'secondary'}
            loading={isLoading}
            variant={'contained'}
            onClick={() => {
              handleSubmit();
              handleClose();
            }}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </Stack>
    </StyledDialog>
  );
};
