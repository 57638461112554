import { api, providesList } from 'api';
import { ProductLineItem } from '../types/productLine.models';
import { SECTION_DETAIL_TAG } from './section.api';
import { ESTIMATE_TAG } from './estimate.api';

export const PRODUCT_LINE_ITEM = 'ProductLineItem';

const productLineItemAPI = api
  .enhanceEndpoints({
    addTagTypes: [PRODUCT_LINE_ITEM, SECTION_DETAIL_TAG, ESTIMATE_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getProductLineItems: build.query<
        ProductLineItem[],
        { estimateId: number; sectionId: number }
      >({
        query: ({ estimateId, sectionId }) => ({
          url: `estimates/${estimateId}/sections/${sectionId}/lines`,
          method: 'GET',
        }),
        providesTags: (result, error, { sectionId, estimateId }) =>
          result?.length
            ? [
                ...providesList(result, PRODUCT_LINE_ITEM),
                { type: PRODUCT_LINE_ITEM, id: `SECTION_${sectionId}` },
              ]
            : [{ type: PRODUCT_LINE_ITEM, id: `SECTION_${sectionId}` }],
        transformResponse: (response: { data: ProductLineItem[] }) => response.data,
      }),

      createProductLineItem: build.mutation<ProductLineItem, ProductLineItem>({
        query: (body) => ({
          url: `estimates/${body.estimate_id}/sections/${body.section_id}/lines`,
          method: 'POST',
          body,
        }),
        invalidatesTags: (result, _error, { section_id, estimate_id }) =>
          result
            ? [
                { type: PRODUCT_LINE_ITEM, id: `SECTION_${section_id}` },
                { type: SECTION_DETAIL_TAG, id: section_id },
                { type: ESTIMATE_TAG, id: estimate_id },
              ]
            : [],
        transformResponse: (response: ProductLineItem) => response,
      }),

      updateProductLineItem: build.mutation<ProductLineItem, ProductLineItem>({
        query: (body) => ({
          url: `estimates/${body.estimate_id}/sections/${body.section_id}/lines/${body.id}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: (result, _error, { id, section_id, estimate_id }) => [
          { type: PRODUCT_LINE_ITEM, id },
          { type: PRODUCT_LINE_ITEM, id: `SECTION_${section_id}` },
          { type: SECTION_DETAIL_TAG, id: section_id },
          { type: ESTIMATE_TAG, id: estimate_id },
        ],
        transformResponse: (response: ProductLineItem) => response,
      }),

      deleteProductLineItem: build.mutation<
        void,
        { id: number; estimateId: number; sectionId: number }
      >({
        query: ({ id, estimateId, sectionId }) => ({
          url: `/estimates/${estimateId}/sections/${sectionId}/lines/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_result, _error, { id, sectionId, estimateId }) => [
          { type: PRODUCT_LINE_ITEM, id: `SECTION_${sectionId}` },
          { type: ESTIMATE_TAG, id: estimateId },
          { type: SECTION_DETAIL_TAG, id: sectionId },
        ],
      }),

      reorderProductLineItems: build.mutation<
        ProductLineItem[],
        { estimateId: number; sectionId: number; line_ids: Number[] }
      >({
        query: ({ estimateId, sectionId, line_ids }) => ({
          url: `estimates/${estimateId}/sections/${sectionId}/lines/reorder`,
          method: 'POST',
          body: { line_ids },
        }),
        invalidatesTags: (_result, _error, { sectionId }) => [
          { type: PRODUCT_LINE_ITEM, id: `SECTION_${sectionId}` },
          { type: SECTION_DETAIL_TAG, id: sectionId },
        ],
      }),
    }),
  });

export const {
  useGetProductLineItemsQuery,
  useCreateProductLineItemMutation,
  useUpdateProductLineItemMutation,
  useDeleteProductLineItemMutation,
  useReorderProductLineItemsMutation,
} = productLineItemAPI;
