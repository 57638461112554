import { Box, Divider, FormLabel, Stack, Typography } from '@mui/material';
import { formatCurrency } from 'helpers/numberFormatter';
import React from 'react';
import { MetricGroup } from './MetricGroup';
import { parseDate } from '@blackhyve/utilities/dates';

interface MetricsProps {
  hideSensitive?: boolean;
  startDate?: string;
  endDate?: string;
  notes?: string;
  rollup: {
    sqft_price: number;
    rom_total: number;
    cost_total: number;
    margin_total: number;
    rom_labor: number;
    cost_labor: number;
    margin_labor: number;
    rom_material: number;
    cost_material: number;
    margin_material: number;
  };
}

export const Metrics: React.FC<MetricsProps> = ({
  hideSensitive = false,
  startDate = '',
  endDate = '',
  notes = '',
  rollup,
}) => {
  return (
    <>
      <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={2}>
          <Box>
            <Typography color={'text.secondary'} variant={'body2'}>
              Start Date
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant={'subtitle1'}>
              {parseDate(startDate).toLocaleDateString()}
            </Typography>
          </Box>
          <Box>
            <Typography color="text.secondary" variant={'body2'}>
              End Date
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant={'subtitle1'}>
              {parseDate(endDate).toLocaleDateString()}
            </Typography>
          </Box>
        </Stack>
        <Box>
          <Typography color="text.secondary" variant={'body2'}>
            Total Sqft Price
          </Typography>
          <Typography sx={{ fontWeight: 600 }} variant={'subtitle1'}>
            {formatCurrency(rollup.sqft_price)}
          </Typography>
        </Box>
      </Stack>
      {notes && (
        <>
          <Divider sx={{ marginY: 2 }} />
          <Box>
            <Typography fontWeight={'bold'} variant={'subtitle2'}>
              Notes
            </Typography>
            <Typography>{notes}</Typography>
          </Box>
        </>
      )}
      <Divider sx={{ my: 2 }} />
      <Stack
        useFlexGap
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={'stretch'}
        spacing={{ xs: 3, sm: 4 }}
      >
        <MetricGroup
          cost={rollup.cost_total}
          hideSensitive={hideSensitive}
          margin={rollup.margin_total}
          rom={rollup.rom_total}
          title={'Total Metrics'}
        />
        <Divider flexItem orientation={'vertical'} sx={{ display: { xs: 'none', sm: 'block' } }} />
        <MetricGroup
          cost={rollup.cost_labor}
          hideSensitive={hideSensitive}
          margin={rollup.margin_labor}
          rom={rollup.rom_labor}
          title={'Labor Metrics'}
        />
        <Divider flexItem orientation={'vertical'} />
        <MetricGroup
          cost={rollup.cost_material}
          hideSensitive={hideSensitive}
          margin={rollup.margin_material}
          rom={rollup.rom_material}
          title={'Material Metrics'}
        />
      </Stack>
    </>
  );
};
