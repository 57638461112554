import { Card, CardContent, Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { EstimateTable } from '../components/estimates/EstimateTable';

export const Estimates: React.FC = () => {
  const navigate = useNavigate();
  const projectId = Number(useParams().projectId);
  const workspaceId = Number(useParams().workspaceId);

  return (
    <Container>
      <Card sx={{ marginY: 4 }}>
        <CardContent>
          <EstimateTable
            projectId={projectId}
            TableProps={{
              rowProps: (estimate) => ({
                sx: { cursor: 'pointer' },
                role: 'button',
                onClick: () =>
                  navigate(
                    `/workspace/${workspaceId}/projects/${projectId}/estimates/${estimate.id}`
                  ),
              }),
            }}
          />
        </CardContent>
      </Card>
    </Container>
  );
};
