import { FormLabel, Stack, TextField } from '@mui/material';
import { Estimate, NewEstimate } from 'features/estimates/types/estimate.models';
import { FormEventHandler } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

type EstimateFormProps = {
  form: UseFormReturn<NewEstimate | Estimate>;
  handleSubmit?: FormEventHandler | undefined;
};

export function EstimateForm({ form, handleSubmit }: EstimateFormProps) {
  const { control } = form;
  return (
    <form onSubmit={handleSubmit}>
      <Stack>
        <FormLabel required>Name</FormLabel>
        <Controller
          control={control}
          name={'name'}
          rules={{ required: 'Name is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField error={Boolean(error)} helperText={error && error.message} {...field} />
          )}
        />
      </Stack>
    </form>
  );
}
