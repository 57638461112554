import _ from 'lodash';
import { PropertyMappedTask } from '../hooks/useScheduleEntries';

interface TextBlock {
  id: number;
  text: string;
  bounding_box_left: string;
}

interface ProcessedBlock extends TextBlock {
  indent_level: number;
  confidence: number;
  anomaly_type: 'too_deep' | 'should_be_parent' | null;
}

interface ClusterStats {
  mean: number;
  std: number;
  count: number;
}

const calculateStats = (points: number[]): { mean: number; std: number } => {
  let mean = 0;
  let m2 = 0;
  let count = 0;

  points.forEach((x) => {
    count++;
    const delta = x - mean;
    mean += delta / count;
    const delta2 = x - mean;
    m2 += delta * delta2;
  });

  if (count < 2) {
    return { mean: points[0] || 0, std: 0.0001 };
  }

  const std = Math.max(Math.sqrt(m2 / (count - 1)), 0.0001);
  return { mean, std };
};

const findIndentClusters = (
  xPositions: number[],
  bandwidthMultiplier = 0.1
): Map<number, ClusterStats> => {
  const sorted = [...xPositions].sort((a, b) => a - b);
  const spread = sorted[sorted.length - 1] - sorted[0];
  const bandwidth = spread * bandwidthMultiplier;

  const clusters = new Map<number, number[]>();
  let currentCluster: number[] = [sorted[0]];
  let clusterCenter = sorted[0];

  for (let i = 1; i < sorted.length; i++) {
    const x = sorted[i];
    if (x - clusterCenter <= bandwidth) {
      currentCluster.push(x);
    } else {
      clusters.set(clusterCenter, currentCluster);
      currentCluster = [x];
      clusterCenter = x;
    }
  }

  if (currentCluster.length > 0) {
    clusters.set(clusterCenter, currentCluster);
  }

  return new Map(
    Array.from(clusters.entries()).map(([center, points]) => [
      center,
      {
        ...calculateStats(points),
        count: points.length,
      },
    ])
  );
};

const calculateConfidence = (x: number, clusterStats: ClusterStats, maxStdDevs = 3): number => {
  const zScore = Math.abs((x - clusterStats.mean) / clusterStats.std);
  return Math.max(0, 1 - zScore / maxStdDevs);
};

export const detectNestingAnomalies = (blocks: PropertyMappedTask[]): PropertyMappedTask[] => {
  return blocks.map((block, index) => {
    // Skip first block since it has no parent
    if (index === 0) return block;

    const currentLevel = block.indent_level;
    const parentLevel = blocks[index - 1].indent_level;

    let anomaly_type: string | undefined;

    // Check if indentation skips a level (e.g. level 1 -> level 3)
    if (currentLevel > parentLevel + 1) {
      anomaly_type = 'invalid_indentation';
    }

    // Check if parent dates match children dates
    const hasChildren = index < blocks.length - 1 && blocks[index + 1].indent_level > currentLevel;
    // if (hasChildren) {
    //   const childStart = blocks[index + 1].scheduled_start;
    //   const childEnd = blocks[index + 1].scheduled_end;

    //   if (
    //     new Date(block.scheduled_start).getTime() !== new Date(childStart).getTime() ||
    //     new Date(block.scheduled_end).getTime() !== new Date(childEnd).getTime()
    //   ) {
    //     anomaly_type = 'invalid_date_span';
    //   }
    // }

    return {
      ...block,
      anomaly_type,
    };
  });
};

export const detectIndentLevels = (
  blocks: PropertyMappedTask[],
  options = {
    bandwidthMultiplier: 0.1,
    minClusterSize: 2,
  }
): { processedBlocks: PropertyMappedTask[]; clusters: any } => {
  const xPositions = blocks.map((b) => b.bounding_box_left);
  const clusters = findIndentClusters(xPositions, options.bandwidthMultiplier);

  const sortedClusters = Array.from(clusters.entries())
    .filter(([, stats]) => stats.count >= options.minClusterSize)
    .sort(([a], [b]) => a - b);

  const clusterToLevel = new Map(sortedClusters.map(([center], idx) => [center, idx]));

  const processedBlocks = blocks.map((block) => {
    const x = block.bounding_box_left;
    const [closestCenter, stats] = _.minBy(sortedClusters, ([center]) => Math.abs(center - x)) || [
      sortedClusters[0][0],
      sortedClusters[0][1],
    ];

    const confidence = calculateConfidence(x, stats);
    const level = clusterToLevel.get(closestCenter) || 0;

    return {
      ...block,
      indent_level: level,
      confidence,
    };
  });

  return { processedBlocks, clusters };
};
