import { api } from 'api';
import { PROJECT_TAG } from 'features/projects/store/project.api';
import {
  RESOURCE_REQUEST_ASSIGNMENT_TAG,
  RESOURCE_REQUEST_TAG,
} from 'features/resources/api/resourceRequest.api';
import { TASK_TAG } from 'features/tasks/store/task.api';

export const analyticsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getJWT: build.query({
      query: ({ workspaceId }) => ({
        url: `/analytics/jwt`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.token,
    }),

    getDashboards: build.query({
      query: ({ workspaceId }) => ({
        url: `/analytics/dashboards`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
    }),

    getCrewSize: build.query({
      query: ({ projectId, tagId, startDate, endDate }) => ({
        url: `/analytics/crew_size`,
        method: 'GET',
        params: {
          project: projectId,
          tag_id: tagId,
          date_range: startDate && endDate ? `${startDate}..${endDate}` : undefined,
        },
      }),
      transformResponse: (response) => ({
        scheduled: response.data.scheduled,
        actual: response.data.actual,
      }),
    }),

    getResourceDemandGraphData: build.query({
      query: (params) => ({
        url: `/analytics/resource_demand`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => ({
        bid: response.data.bid,
        active: response.data.active,
        meta: response.meta,
      }),
      providesTags: (result, error) => [
        { type: RESOURCE_REQUEST_TAG, id: 'LIST' },
        { type: RESOURCE_REQUEST_ASSIGNMENT_TAG, id: 'LIST' },
        { type: TASK_TAG, id: 'LIST' },
        { type: PROJECT_TAG, id: 'LIST' },
      ],
    }),

    getResourceRequestGraphData: build.query({
      query: (params) => ({
        url: `/analytics/resource_requests`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => ({
        requested: response.data.requested,
        assigned: response.data.assigned,
        meta: response.meta,
      }),
      providesTags: (result, arg, api) => [
        { type: RESOURCE_REQUEST_TAG, id: 'LIST' },
        { type: RESOURCE_REQUEST_ASSIGNMENT_TAG, id: 'LIST' },
        { type: TASK_TAG, id: 'LIST' },
        { type: PROJECT_TAG, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetJWTQuery,
  useLazyGetJWTQuery,
  useGetDashboardsQuery,
  useGetCrewSizeQuery,
  useGetResourceDemandGraphDataQuery,
  useGetResourceRequestGraphDataQuery,
} = analyticsAPI;
