import { SvgIcon, SvgIconProps } from '@mui/material';

export const FrontHeavyCurve = (props: SvgIconProps) => {
  return (
    <SvgIcon
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path fill="none" d="M2 18 C4 18 6 4 10 4 C16 4 18 16 22 18" />
    </SvgIcon>
  );
};
