import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { ContentLayout } from 'components/layouts/ContentLayout';
import SearchBox from 'components/search/SearchBox';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteBudgetByIdMutation, useGetBudgetsQuery } from '../store/budget.api';

import { OptionsMenu } from '@blackhyve/common';
import { Delete, Edit, MoreHoriz } from '@mui/icons-material';
import Breadcrumbs from 'components/Breadcrumbs';
import ConfirmDeleteDialog from 'components/common/popovers/ConfirmDeleteDialog';
import { useSnackbar } from 'notistack';
import { BudgetFormDialog, StrategyOptions, TOP_DOWN } from './BudgetFormDialog';
import { formatMoney } from './NumericControls';

const BudgetsMoreOptions = ({ budget }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [handleDelete, { isLoading: isDeletingBudget, isError: isErrorDeleting }] =
    useDeleteBudgetByIdMutation();
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  console.log(isErrorDeleting);

  return (
    <>
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        triggerButton={
          <IconButton>
            <MoreHoriz fontSize={'inherit'} />
          </IconButton>
        }
      >
        {(handleClose) => (
          <>
            <ListItemButton
              onClick={(event) => {
                handleClose();
                setIsEditOpen(true);
              }}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary={'Edit'}></ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={(event) => {
                handleClose();
                setIsConfirmDeleteOpen(true);
              }}
            >
              <ListItemIcon>
                <Delete sx={{ color: 'red' }} />
              </ListItemIcon>
              <ListItemText primary={'Delete'} sx={{ color: 'red' }} />
            </ListItemButton>
          </>
        )}
      </OptionsMenu>
      <BudgetFormDialog
        budget={budget}
        handleClose={() => setIsEditOpen(false)}
        open={isEditOpen}
      />
      <ConfirmDeleteDialog
        handleClose={() => setIsConfirmDeleteOpen(false)}
        isLoading={isDeletingBudget}
        item={'budget'}
        open={isConfirmDeleteOpen}
        handleDelete={async () =>
          await handleDelete({ id: budget.id })
            .unwrap()
            .then(() => setIsConfirmDeleteOpen(false))
            .catch(() => enqueueSnackbar({ message: 'Error deleting budget', variant: 'error' }))
        }
      />
    </>
  );
};

const BudgetsList = ({ projectId, workspaceId }) => {
  const { data: budgets = [], isLoading } = useGetBudgetsQuery({ projectId });
  const navigate = useNavigate();

  return isLoading ? (
    <CircularProgress className="loading" />
  ) : (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Name</TableCell>
          <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Strategy</TableCell>
          <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Estimated</TableCell>
          <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Budgeted</TableCell>
          <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Contracted</TableCell>
          <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Invoiced</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {budgets.length > 1 && (
          <TableRow
            hover
            key="all-budgets-summary"
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(`/workspace/${workspaceId}/projects/${projectId}/budgets/forecasts`)
            }
          >
            <TableCell sx={{ whiteSpace: 'nowrap' }}>All Budgets Combined</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>─</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              ${formatMoney(budgets.reduce((acc, budget) => acc + budget.amount, 0))}
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              $
              {formatMoney(
                budgets.reduce((acc, budget) => acc + budget.summaries.forecasted.amount, 0)
              )}
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              $
              {formatMoney(
                budgets.reduce((acc, budget) => acc + budget.summaries.contracted.amount, 0)
              )}
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              $
              {formatMoney(
                budgets.reduce((acc, budget) => acc + budget.summaries.invoiced.amount, 0)
              )}
            </TableCell>
          </TableRow>
        )}
        {budgets.map((budget) => (
          <TableRow
            hover
            key={budget.id}
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(`/workspace/${workspaceId}/projects/${projectId}/budgets/${budget.id}`)
            }
          >
            <TableCell sx={{ whiteSpace: 'nowrap', paddingLeft: budgets.length > 1 ? 4 : 2 }}>
              {budget.name}
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              <div
                style={{
                  fontSize: '10px',
                  display: 'inline',
                  borderRadius: '33px',
                  backgroundColor: budget.strategy === TOP_DOWN ? '#ccc' : '#999',
                  padding: '5px 10px',
                }}
              >
                {StrategyOptions.find((strategy) => strategy.id === budget.strategy).name}
              </div>
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>${formatMoney(budget.amount)}</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              ${formatMoney(budget.summaries.forecasted.amount)}
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              ${formatMoney(budget.summaries.contracted.amount)}
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              ${formatMoney(budget.summaries.invoiced.amount)}
            </TableCell>
            <TableCell align={'right'} onClick={(event) => event.stopPropagation()}>
              <BudgetsMoreOptions budget={budget} />
            </TableCell>
          </TableRow>
        ))}
        {budgets.length === 0 && (
          <TableRow key="empty-state">
            <TableCell colSpan={6} sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
              No Data
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const BudgetsIndex = () => {
  const { workspaceId, projectId } = useParams();
  const [dialog, setDialog] = useState(false);

  return (
    <ContentLayout>
      <Container maxWidth={'xl'}>
        <Box pt={2}>
          <Typography variant={'h4'}>
            <Breadcrumbs links={[{ text: 'Budgets' }]} />
          </Typography>
          <Paper elevation={2}>
            <Toolbar style={{ position: 'sticky', left: '0px' }}>
              <Grid container item>
                <SearchBox
                  bgcolor={'grey.200'}
                  borderRadius={1}
                  fullWidth={false}
                  placeholder="Search By Budget Name"
                  onChange={(e) => console.log(e.target.value)}
                />
                <Box ml={'auto'} />
                <Button size="small" variant="text" onClick={() => setDialog(true)}>
                  New Budget
                </Button>
                <BudgetFormDialog
                  handleClose={() => setDialog(false)}
                  open={dialog}
                  projectId={projectId}
                />
              </Grid>
            </Toolbar>
            <BudgetsList projectId={projectId} workspaceId={workspaceId} />
          </Paper>
        </Box>
      </Container>
    </ContentLayout>
  );
};

export default BudgetsIndex;
