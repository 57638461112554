import { api } from 'api';
import { Resource, ResourceAssignment } from './resources.models';

export const RESOURCE_TAG = 'Resource';
export const RESOURCE_ASSIGNMENT_TAG = 'ResourceAssignment';

type GetAllResourcesParams = {
  trade: number;
  type: Resource['type'];
};

export const resourcesAPI = api
  .enhanceEndpoints({ addTagTypes: [RESOURCE_TAG, RESOURCE_ASSIGNMENT_TAG] })
  .injectEndpoints({
    endpoints: (build) => ({
      // Resource
      getAllResources: build.query({
        query: (params: GetAllResourcesParams | undefined) => ({
          url: `/resources`,
          params: params,
        }),
        providesTags: (result) => (result ? [{ type: 'Resource', id: 'LIST' }] : []),
        transformResponse: (response: { data: Resource[] }) => response.data,
      }),
      getAllResourceGroups: build.query<Resource[], {}>({
        query: () => ({
          url: `/resources/get-all-resource-groups`,
        }),
        providesTags: (result, error) => (result ? [{ type: RESOURCE_TAG, id: 'LIST' }] : []),
        transformResponse: (response: { data: Resource[] }) => response.data,
      }),
      getAllNonGroupResources: build.query<
        { data: Resource[]; meta: any },
        { order_by?: string; per_page?: number; page?: number; q?: string }
      >({
        query: ({ order_by, per_page, page, q }) => ({
          url: `/resources/get-all-nongroup-resources`,
          params: { order_by, per_page, page, q },
        }),
        providesTags: (result, error) => (result ? [{ type: 'Resource', id: 'LIST' }] : []),
        transformResponse: (response: { data: Resource[]; meta: any }) => {
          return {
            data: response.data,
            meta: response.meta,
          };
        },
      }),

      createResource: build.mutation<Resource, { data: Resource }>({
        query: ({ data }) => ({
          url: 'resources',
          method: 'POST',
          body: data,
        }),
        invalidatesTags: [{ type: RESOURCE_TAG, id: 'LIST' }],
      }),
      updateResource: build.mutation<Resource, { resourceId: number; data: Resource }>({
        query: ({ resourceId, data }) => ({
          url: `/resources/${resourceId}`,
          method: 'PATCH',
          body: data,
        }),
        invalidatesTags: (result, error, { resourceId }) => [
          { type: RESOURCE_TAG, id: resourceId },
          { type: RESOURCE_TAG, id: 'LIST' },
        ],
      }),
      deleteResource: build.mutation<void, { resourceId: number }>({
        query: ({ resourceId }) => ({
          url: `resources/${resourceId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [{ type: RESOURCE_TAG, id: 'LIST' }],
      }),

      // Resource Assignments
      fetchResourceAssignments: build.query<ResourceAssignment[], void>({
        query: () => 'resource_assignments',
        providesTags: (result, error) =>
          result ? [{ type: RESOURCE_ASSIGNMENT_TAG, id: 'LIST' }] : [],
      }),
      createResourceAssignments: build.mutation<
        ResourceAssignment[],
        { taskId: number; resourceAssignments: ResourceAssignment[] }
      >({
        query: ({ taskId, resourceAssignments }) => {
          return {
            url: 'resource_assignments',
            method: 'POST',
            body: { taskId, resourceAssignments },
          };
        },
        invalidatesTags: [{ type: RESOURCE_ASSIGNMENT_TAG, id: 'LIST' }],
      }),

      bulkResourceAssignment: build.mutation<
        ResourceAssignment[],
        { tasks: number[]; resources: ResourceAssignment[] }
      >({
        query: ({ tasks, resources }) => {
          return {
            url: 'bulk_resource_assignments',
            method: 'POST',
            body: { tasks, resources },
          };
        },
        invalidatesTags: [{ type: RESOURCE_ASSIGNMENT_TAG, id: 'LIST' }],
      }),
    }),
  });

export const {
  // Resource
  useGetAllResourcesQuery,
  useGetAllNonGroupResourcesQuery,
  useGetAllResourceGroupsQuery,

  useCreateResourceMutation,
  useUpdateResourceMutation,
  useDeleteResourceMutation,

  // Resource Assignments
  useFetchResourceAssignmentsQuery,
  useCreateResourceAssignmentsMutation,
  useBulkResourceAssignmentMutation,
} = resourcesAPI;
