import {
  GridRowModes,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import debounce from 'lodash/debounce';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SearchBox from 'components/search/SearchBox';
import SingleSelectMenu from 'components/menus/SingleSelectMenu';
import { PROCUREMENT_STATUS_OPTIONS } from '../components/ProcurementFormDialog';
import DisplayWithEditAccess from 'components/common/v3/DisplayWithEditAccess';

const initialState = {
  order_number: '',
  name: '',
  description: '',
  category: '',
  status: null,
  lead_time_weeks: 0,
  order_date: null,
  ship_date: null,
  transit_time_days: 0,
  arrival_date: null,
  cost: '',
  vendor: '',
  vendor_contact: '',
  responsible_users: [],
  leader_users: []
}



const ProcurementDataGridToolbar = ({
  setTempRows,
  setRowModesModel,
  setFilters,
  filters,
  projectId
}) => {

  const handleClick = () => {
    const id = Math.random();
    setTempRows([
      { id, isNew: true, ...initialState, project_id: projectId },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'order_number' },
    }));
  };

  const handleSearch = debounce((event) => {
    const searchQuery = event.target.value.trim();
    setFilters((filters) => ({ ...filters, page: 1, q: searchQuery || undefined, }));
  }, 500);

  return (
    <GridToolbarContainer sx={{ borderBottom: '1px solid lightGray', p: 1 }}>
      <Grid container item>
        <DisplayWithEditAccess>
          <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add Procurement
          </Button>
        </DisplayWithEditAccess>
        <Grid container item xs justifyContent={'flex-end'} spacing={1}>
          <Grid item>
            <SingleSelectMenu
              label="Filter By Status"
              options={PROCUREMENT_STATUS_OPTIONS}
              selected={filters?.status}
              handleSelect={(newValue) => {
                setFilters((filters) => ({ ...filters, status: newValue, page: 1 }))
              }}
            />
          </Grid>
          <Grid item>
            <SearchBox
              bgcolor={'grey.200'}
              borderRadius={1}
              fullWidth={false}
              placeholder="Search By Name"
              onChange={handleSearch}
            />
          </Grid>
        </Grid>
      </Grid>



    </GridToolbarContainer>
  );
}

export default ProcurementDataGridToolbar