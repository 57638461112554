import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AutoAwesome, Psychology } from '@mui/icons-material';
import { CircularProgress, Divider, ListItemIcon, ListItemText } from '@mui/material';

export function BarryTemplatesMenu({
  onSave,
  onApply,
  templates = [],
  thinking,
}: {
  thinking: boolean;
  onSave: () => void;
  onApply: (templateId: any) => void;
  templates: any[];
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave = () => {
    onSave();
    handleClose();
  };

  const handleApply = (templateId: any) => {
    onApply(templateId);
    handleClose();
  };

  return (
    <div>
      {thinking ? (
        <Button disabled startIcon={<CircularProgress size={14} />} variant="outlined">
          Thinking...
        </Button>
      ) : (
        <Button startIcon={<AutoAwesome />} variant="outlined" onClick={handleClick}>
          Barry AI
        </Button>
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleSave}>
          <ListItemIcon>
            <Psychology />
          </ListItemIcon>
          <ListItemText>Teach Barry this pattern</ListItemText>
        </MenuItem>
        {templates.length > 0 && [
          <Divider key="divider" />,
          ...templates.map((template) => (
            <MenuItem key={template.id} onClick={() => handleApply(template.id)}>
              Apply pattern from "{template.name}"
            </MenuItem>
          ))
        ]}
      </Menu>
    </div>
  );
}
