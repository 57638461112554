import { Table } from 'components/table/Table';
import { Import, ImportStatus, ImportType } from '../api/imports.models';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';

const STATUS_MESSAGES: Record<ImportStatus, string> = {
  [ImportStatus.UploadProcessing]: 'Uploading...',
  [ImportStatus.UploadFailed]: 'Upload Failed',
  [ImportStatus.UploadSucceeded]: 'Specify Bounding Box',
  [ImportStatus.ExtractQueued]: 'Analyzing Import...',
  [ImportStatus.ExtractDownloading]: 'Pdf Downloading...',
  [ImportStatus.ExtractProcessing]: 'Barry is Analyzing the Schedule...',
  [ImportStatus.ExtractFailed]: 'Analysis Failed',
  [ImportStatus.ExtractSucceeded]: 'Ready for Confirmation',
};

const IMPORT_TYPES: Record<ImportType, string> = {
  [ImportType.Schedule]: 'Schedule',
  [ImportType.LaborEstimate]: 'Labor Estimate',
};

interface ImportsTableProps {
  imports: Import[];
  TableProps: any;
  onPoll: () => Promise<any>;
  isLoading?: boolean;
}

export function ImportsTable({ imports, TableProps, onPoll, isLoading = false }: ImportsTableProps) {
  const navigate = useNavigate();
  const pollInterval = useRef<number | null>(null);

  // Set up polling
  useEffect(() => {
    if (isLoading) return; // Don't start polling if we're still loading

    // Start polling
    const startPolling = async () => {
      try {
        await onPoll(); // Initial poll
        pollInterval.current = window.setInterval(() => {
          onPoll().catch(console.error); // Handle any polling errors gracefully
        }, 5000);
      } catch (error) {
        console.error('Initial polling failed:', error);
      }
    };

    startPolling();

    // Cleanup function to clear interval when component unmounts
    return () => {
      if (pollInterval.current !== null) {
        clearInterval(pollInterval.current);
      }
    };
  }, [onPoll, isLoading]);

  // Pause polling when tab is not visible
  useEffect(() => {
    if (isLoading) return; // Don't set up visibility handling if still loading

    const handleVisibilityChange = () => {
      if (document.hidden && pollInterval.current !== null) {
        clearInterval(pollInterval.current);
        pollInterval.current = null;
      } else if (!document.hidden && pollInterval.current === null) {
        pollInterval.current = window.setInterval(() => {
          onPoll().catch(console.error);
        }, 5000);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [onPoll, isLoading]);

  const formatDateTime = (dateString: string | Date | null | undefined): string => {
    if (!dateString) return '-';
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return '-';
      return `${date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })}, ${date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}`;
    } catch {
      return '-';
    }
  };

  const isRowClickable = (importItem: Import): boolean => {
    if (importItem.type === ImportType.LaborEstimate) return true;

    return (
      importItem.status === ImportStatus.UploadSucceeded ||
      importItem.status === ImportStatus.ExtractSucceeded
    );
  };

  const handleImportSelect = (importItem: Import) => {
    if (importItem.type === ImportType.Schedule && importItem.status === ImportStatus.UploadSucceeded) {
      navigate(`schedule/${importItem.id}/bounding-box`);
    }
    if (importItem.type === ImportType.Schedule && importItem.status === ImportStatus.ExtractSucceeded) {
      navigate(`schedule/${importItem.id}/column-mapper`);
    }
    if (importItem.type === ImportType.LaborEstimate) {
      navigate(`labor/${importItem.id}`);
    }
  };

  const columns = [
    {
      field: 'type',
      label: 'Type',
      sort: true,
      render: (importItem: Import) => IMPORT_TYPES[importItem.type],
    },
    {
      field: 'name',
      label: 'File Name',
      sort: true,
      render: (importItem: Import) => importItem.name,
    },
    {
      field: 'status',
      label: 'Status',
      sort: true,
      render: (importItem: Import) => {
        const clickable = isRowClickable(importItem);
        return (
          <span
            style={{
              color: clickable ? '#2196f3' : 'rgba(0, 0, 0, 0.6)',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontWeight: clickable ? 500 : 400,
            }}
          >
            {STATUS_MESSAGES[importItem.status]}
          </span>
        );
      },
    },
    {
      field: 'created_at',
      label: 'Created At',
      sort: true,
      render: (importItem: Import) => formatDateTime(importItem.created_at),
    },
    {
      field: 'updated_at',
      label: 'Updated At',
      sort: true,
      render: (importItem: Import) => formatDateTime(importItem.updated_at),
    },
  ];

  return (
    <Table
      columns={columns}
      rows={imports}
      {...TableProps}
      rowProps={(row: Import) => {
        const clickable = isRowClickable(row);
        return {
          onClick: clickable ? () => handleImportSelect(row) : undefined,
          style: {
            cursor: clickable ? 'pointer' : 'default',
            transition: 'background-color 0.2s ease',
            '&:hover': clickable
              ? {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                }
              : undefined,
          },
        };
      }}
    />
  );
}
