import { useState } from 'react';

export function useTaskMappings<T extends { id: number }>() {
  const [mappings, setMappings] = useState<T[]>([]);

  const addMapping = (taskId, selectedLaborEstimateAreaId, mapping: T) => {
    setMappings([
      ...mappings,
      {
        ...mapping,
        task_id: taskId,
        area_id: selectedLaborEstimateAreaId,
      },
    ]);
  };

  const addMappings = (newMappings: T[]) => {
    setMappings((currentMappings) => [...currentMappings, ...newMappings]);
  };

  const removeMapping = (mappingToRemove) => {
    setMappings(mappings.filter((m) => m.id !== mappingToRemove.id));
  };

  const updateMapping = (updatedMapping) => {
    setMappings(mappings.map((m) => (m.id === updatedMapping.id ? updatedMapping : m)));
  };

  return {
    mappings,
    addMapping,
    addMappings,
    removeMapping,
    updateMapping,
  };
}
