export const formatDecimals = (value: number | string): string => {
  return parseFloat(value as string)
    .toFixed(2)
    .replace(/\.00$/, '')
    .replace(/\.0$/, '')
    .replace(/(\.\d)0$/, '$1');
};

export const formatCurrency = (value: number, asCents: boolean = true): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(value);
};

export const formatToCents = (value: number): number => {
  return value / 100;
};

export const unformatMoney = (value: string | number, asCents: boolean = false): number => {
  return parseFloat(`${value}`.replace(/,/g, '') || '0') * (asCents ? 100 : 1);
};

interface PercentOptions {
  min?: number;
  max?: number;
  decimalPlaces?: number;
}

export const formatPercent = (value: number | string, options: PercentOptions = {}): string => {
  const { min = 0, max = Infinity, decimalPlaces } = options;

  const parsedMin = parseFloat(min.toString());
  const parsedMax = parseFloat(max.toString());
  let parsedValue = parseFloat(`${value}`.replace(/[^-\d.]/g, '') || '0');

  if (isNaN(parsedValue) || parsedValue < parsedMin) {
    parsedValue = parsedMin;
  } else if (parsedValue > parsedMax) {
    parsedValue = parsedMax;
  }

  const formattedValue =
    decimalPlaces !== undefined ? parsedValue.toFixed(decimalPlaces) : parsedValue.toString();

  return `${formattedValue}%`;
};
