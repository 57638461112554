import { StyledDialog } from '@blackhyve/common';
import { GanttStatic } from '@blackhyve/dhtmlx-gantt';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  Chip,
  LinearProgress,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import SearchBox from 'components/search/SearchBox';
import { useGetAvailableResourcesQuery } from 'features/resources/api/resourceRequest.api';
import { Resource } from 'features/resources/api/resources.models';
import { TradeRole } from 'features/trades/api/trades.models';
import { useCallback, useMemo, useState } from 'react';

interface SelectedResourceMap {
  [key: number]: string; // id: name mapping
}

export const ResourceAvailabilityDialog = ({
  open,
  gantt,
  resourceRequestId,
  handleClose,
}: {
  open: boolean;
  gantt: GanttStatic;
  resourceRequestId: number;
  handleClose: any;
}) => {
  const [searchText, setSearchText] = useState('');
  const [selectedResources, setSelectedResources] = useState<SelectedResourceMap>({});

  const {
    data: availableResources = [],
    isLoading,
    isFetching,
  } = useGetAvailableResourcesQuery({
    resourceRequestId,
  });

  const handleToggleSelection = useCallback((resource: Resource) => {
    setSelectedResources((prev) => {
      if (resource.id in prev) {
        const { [resource.id]: _, ...rest } = prev;
        return rest;
      } else {
        return {
          ...prev,
          [resource.id]: resource.name,
        };
      }
    });
  }, []);

  const preSelectedIds = useMemo(() => {
    const assignments = gantt?.getChildren(resourceRequestId);
    if (!assignments?.length) return [];

    return assignments.map((id) => gantt?.getTask(id).resource_id);
  }, [resourceRequestId, gantt]);

  const filteredResources = useMemo(() => {
    const remainingResources = availableResources.filter(
      (resource) => !preSelectedIds.includes(resource.id)
    );

    if (!searchText.trim()) {
      return remainingResources;
    }
    return remainingResources.filter((resource) =>
      resource.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [availableResources, searchText, preSelectedIds]);

  const handleAddResource = () => {
    const resourceRequest = gantt.getTask(resourceRequestId);
    gantt.batchUpdate(() => {
      Object.entries(selectedResources).forEach(([id, name]) => {
        gantt.addTask({
          id: `${resourceRequest.id}_${gantt.uid()}`,
          resource_id: Number(id),
          text: name,
          start_date: resourceRequest.start_date,
          end_date: resourceRequest.end_date,
          parent: resourceRequest.id,
          project_id: resourceRequest.project_id,
          type: 'task',
          resource_request_id: resourceRequest.id,
        });
      });
    });
    handleClose();
  };

  return (
    <StyledDialog
      handleClose={handleClose}
      maxWidth={'sm'}
      open={open}
      title={'Available Resources'}
      actions={
        <>
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="small" variant="contained" onClick={handleAddResource}>
            Add
          </Button>
        </>
      }
    >
      <Box>
        <Box mb={1}>
          <SearchBox
            fullWidth
            bgcolor="grey.200"
            placeholder="Search By Name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Box display={'flex'} justifyContent={'space-between'} mt={0.5}>
            <Typography color={'textSecondary'} sx={{ fontWeight: 'bold' }}>
              Selected: {Object.keys(selectedResources)?.length || 0}
            </Typography>
            <Typography color={'textSecondary'} sx={{ fontWeight: 'bold' }}>
              Total Available: {filteredResources?.length || 0}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            minHeight: 200,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            px: 1,
          }}
        >
          {!isLoading && isFetching && <LinearProgress />}
          {isLoading ? (
            [...Array(5)].map((_, index) => (
              <Box component={Card} key={index} sx={{ p: 1 }}>
                <Skeleton height={30} variant="text" width="100%" />
              </Box>
            ))
          ) : filteredResources?.length ? (
            filteredResources.map((resource) => (
              <Box key={resource.id}>
                <ResourceCard
                  resource={resource}
                  selected={resource.id in selectedResources}
                  onSelect={handleToggleSelection}
                />
              </Box>
            ))
          ) : (
            <Box alignItems={'center'} display={'flex'} justifyContent={'center'} m={'auto'}>
              <Typography color={'textSecondary'} variant="h6">
                No Resource Available.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </StyledDialog>
  );
};

export interface ResourceCardProps {
  resource: Resource;
  selected?: boolean;
  onSelect?: (resource: Resource) => void;
  sx?: Record<string, any>;
}

const ResourceCard: React.FC<ResourceCardProps> = ({
  resource,
  selected = false,
  onSelect,
  sx = {},
}) => {
  const handleSelect = useCallback(() => {
    onSelect?.(resource);
  }, [resource, onSelect]);

  const cardStyles: SxProps<Theme> = {
    position: 'relative',
    transition: 'all 0.2s',
    '&:hover': { boxShadow: 5 },
    border: 1,
    borderColor: selected ? 'primary.main' : 'lightGray',
    ...sx,
  };

  return (
    <Card sx={cardStyles}>
      <CardActionArea onClick={handleSelect}>
        <CardContent sx={{ p: 1 }}>
          <Stack alignItems={'center'} direction={'row'}>
            <Checkbox disableRipple checked={selected} />
            <Typography>{resource.name}</Typography>
          </Stack>
          <Stack alignItems={'center'} direction={'row'} gap={1}>
            {resource.trades?.map((tradeRole: TradeRole) => {
              const name = `${tradeRole.trade_name} ${tradeRole.role_name ? `- ${tradeRole.role_name}` : ''}`;
              return <Chip key={tradeRole.id} label={name} size="small"></Chip>;
            })}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
