import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';
import { ReactNode } from 'react';

interface ContentLayoutProps extends BoxProps {
  header?: ReactNode;
  headerProps?: BoxProps;
  containerProps?: BoxProps;
}

/**
 * A layout component that displays content with a header.
 * @param header - The content to be displayed as the header.
 * @param headerProps - Additional props to be applied to the header Box component.
 * @param containerProps - Additional props to be applied to the container Box component.
 * @param props - Additional props to be applied to the content Box component.
 * @returns The rendered ContentLayout component.
 */
export const ContentLayout = ({
  header,
  headerProps,
  containerProps = {},
  ...props
}: ContentLayoutProps): JSX.Element => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      width={'100%'}
      {...containerProps}
    >
      <Box {...headerProps}>{header}</Box>
      <Box
        flexGrow={1}
        overflow={'auto'}
        sx={{
          '@media print': {
            overflow: 'unset',
          },
        }}
        {...props}
        position={'relative'}
      />
    </Box>
  );
};
