import { Box, Button, Card, Collapse, IconButton, styled } from '@mui/material';
import { TaskDetails } from 'features/tasks/components/TaskDetails/TaskDetails';
import { useOnTaskClick } from 'hooks/useOnTaskClick';
import { useCallback, useState } from 'react';
import { formatTask, undoFormatTask } from '../utils/formatter';
import { useSyncTagsOnTaskMutation } from 'features/tasks/store/task.api';
import { ArrowDropUp, Close } from '@mui/icons-material';

const Slide = styled(Box)({
  transition: 'height .3s ease',
});

export const LookaheadTaskDetailsDrawer = ({ gantt }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [taskId, setTaskId] = useState();

  const [syncTagsOnTask] = useSyncTagsOnTaskMutation();

  const handleTaskClick = useCallback(
    (task) => {
      setTaskId(task.id);
      setIsDrawerOpen(true);

      gantt.attachEvent(
        'onEmptyClick',
        (id, e) => {
          setIsDrawerOpen(false);
        },
        { id: 'onEmptyClick', once: true }
      );
    },
    [gantt]
  );

  useOnTaskClick({ gantt, callback: handleTaskClick, className: 'gantt_task_content' });

  const formattedTask = taskId && gantt.isTaskExists(taskId) && gantt.getTask(taskId);
  // Need to get the tag objects back
  const unformattedTask = formattedTask && {
    ...undoFormatTask(formattedTask),
    tags: formattedTask.tags,
    workday_calendar: formattedTask.workday_calendar
  };

  const handleTaskUpdate = (newTaskValues) => {
    if (newTaskValues.tags) {
      const promise = syncTagsOnTask({
        taskId,
        tags: newTaskValues.tags?.map((tag) => tag.id) || [],
      })
        .unwrap()
        .then((data) => {
          gantt.batchUpdate(() => {
            data?.forEach(({ id, tags }) => {
              if (gantt.isTaskExists(id)) {
                const task = gantt.getTask(id);
                task.tags = tags;
                gantt.refreshTask(id);
              }
            });
          });
        });
      return promise;
    } else {
      gantt.updateTask(taskId, formatTask({ ...unformattedTask, ...newTaskValues }));
    }
  };

  const handleTaskDelete = (taskId) => {
    gantt.deleteTask(taskId);
  };

  return (
    <Slide
      bottom={0}
      height={isDrawerOpen ? '50%' : 0}
      left={0}
      overflow={'auto'}
      position={'relative'}
      right={0}
      sx={{
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.28)",
        pt: 1
      }}
    >
      <Box>
        <Button
          sx={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 0%)' }}
          onClick={() => setIsDrawerOpen(false)}
        >
          <Close /> Close
        </Button>
        {unformattedTask && (
          <TaskDetails
            handleTaskDelete={handleTaskDelete}
            handleTaskUpdate={handleTaskUpdate}
            task={unformattedTask}
          />
        )}
      </Box>
    </Slide>
  );
};
