export function useAreaTasks(allTasks, selectedAreaTags) {
  if (!selectedAreaTags.length) return [];
  return Object.keys(allTasks.entities)
    .map((k) => allTasks.entities[k])
    .filter((t) => {
      return selectedAreaTags.every((tag) => {
        return t.tags.filter((t) => t.id === tag.id).length > 0;
      });
    });
}
