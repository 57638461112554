import { parseDate } from '@blackhyve/utilities/dates';
import { Business, HomeRepairService, LocationCity, MonitorHeart } from '@mui/icons-material';
import { Box, Chip, Grid, Paper, TableContainer, Typography } from '@mui/material';
import { Table } from 'components/table/Table';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import { Label } from 'features/labels/components/Label';
import MoreOptions from 'features/projects/components/MoreOptions';
import { Pace } from 'features/tasks/components/Pace';
import { ProductionRating } from 'features/tasks/components/ProductionRating';
import { groupBy as lodashGroupBy } from 'lodash';
import { useDeferredValue, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetProjectsQuery } from '../store/project.api';
import { ProjectTableFilterMenu } from './ProjectTableFilterMenu';
import { PROJECT_STATUS } from '../api/constants';

const columns = [
  // {
  //   field: 'icon',
  //   render: () => (
  //     <Icon fontSize={'small'} sx={{ color: 'grey.600' }}>
  //       <SvgBuilding height={'1em'} width={'1em'} />
  //     </Icon>
  //   ),
  // },
  {
    field: 'project_number',
    label: 'No',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => project.project_number ?? '-',
  },
  { field: 'name', label: 'Name', sort: true },
  {
    field: 'status',
    label: 'Status',
    sort: true,
    cellProps: { sx: { textTransform: 'capitalize' } },
  },
  { field: 'project_dimension_1', label: <Label labelKey="project_dimension_1" />, sort: true },
  { field: 'project_dimension_2', label: <Label labelKey="project_dimension_2" />, sort: true },
  { field: 'project_dimension_3', label: <Label labelKey="project_dimension_3" />, sort: true },
  {
    field: 'start_date',
    label: 'Start Date',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => parseDate(project.start_date).toLocaleDateString(),
  },
  {
    field: 'est_completion_date',
    label: 'Forecasted End',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => parseDate(project.est_completion_date).toLocaleDateString(),
  },
  {
    field: 'sub_completion_date',
    label: 'Scheduled End',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => parseDate(project.sub_completion_date).toLocaleDateString(),
  },
  {
    field: 'project_manager',
    label: 'Project Manager',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => project.project_manager,
  },
  {
    field: 'client',
    label: 'Client',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => project.client,
  },
  {
    field: 'pace',
    label: 'Pace',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => <Pace value={project.pace} />,
  },
  {
    field: 'pr',
    label: 'PR',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => <ProductionRating value={project.pr} />,
  },
  {
    field: 'moreOptions',
    cellProps: { align: 'right' },
    render: (project) => (
      <div onClick={(event) => event.stopPropagation()}>
        <MoreOptions projectId={project.id} />
      </div>
    ),
  },
];
export const ProjectsTable = (props) => {
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    project_dimension_1: [],
    project_dimension_2: [],
    project_dimension_3: [],
    status: [PROJECT_STATUS.BID, PROJECT_STATUS.ACTIVE],
  });
  const deferredSearch = useDeferredValue(search);
  const [groupBy, setGroupBy] = useState(undefined);
  const deferredGroupBy = useDeferredValue(groupBy);
  const {
    data: projects = [],
    isLoading,
    isFetching,
  } = useGetProjectsQuery({ status: ['bid', 'active', 'complete'] });

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSetGroupBy = (value) => {
    if (value === groupBy) {
      setGroupBy(undefined);
    } else {
      setGroupBy(value);
    }
  };

  const filteredList = useMemo(() => {
    return projects?.filter((project) => {
      // Filter based on search text
      const matchesSearch = project.name.toLowerCase().includes(deferredSearch.toLowerCase());

      // Filter based on filter keys
      const matchesFilters = Object.entries(filters).every(([key, filterValues]) => {
        if (filterValues.length === 0) {
          return true;
        }
        return filterValues.includes(project[key]);
      });

      return matchesSearch && matchesFilters;
    });
  }, [projects, deferredSearch, filters]);

  const groupedList = useMemo(
    () => (deferredGroupBy ? lodashGroupBy(filteredList, deferredGroupBy) : { null: filteredList }),
    [filteredList, deferredGroupBy]
  );

  return (
    <div>
      <Grid container item justifyContent={'center'} m={'auto'} md={6} p={3} spacing={1} xs={12}>
        <Grid item>
          <Chip
            icon={<HomeRepairService />}
            variant={groupBy === 'project_dimension_1' ? 'filled' : 'outlined'}
            label={
              <>
                Group By <Label labelKey="project_dimension_1" />
              </>
            }
            onClick={() => handleSetGroupBy('project_dimension_1')}
          />
        </Grid>
        <Grid item>
          <Chip
            icon={<LocationCity />}
            variant={groupBy === 'project_dimension_2' ? 'filled' : 'outlined'}
            label={
              <>
                Group By <Label labelKey="project_dimension_2" />
              </>
            }
            onClick={() => handleSetGroupBy('project_dimension_2')}
          />
        </Grid>
        <Grid item>
          <Chip
            icon={<Business />}
            variant={groupBy === 'project_dimension_3' ? 'filled' : 'outlined'}
            label={
              <>
                Group By <Label labelKey="project_dimension_3" />
              </>
            }
            onClick={() => handleSetGroupBy('project_dimension_3')}
          />
        </Grid>
        <Grid item>
          <Chip
            icon={<MonitorHeart />}
            label={<>Group By Status</>}
            variant={groupBy === 'status' ? 'filled' : 'outlined'}
            onClick={() => handleSetGroupBy('status')}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <ProjectTableFilterMenu
          filters={filters}
          handleSearch={handleSearch}
          setFilters={setFilters}
        />
        {Object.entries(groupedList)?.map(([field, value]) => (
          <Box key={field} p={3}>
            {deferredGroupBy && (
              <Typography sx={{ textTransform: 'capitalize' }} variant="h4">
                {field === 'null' ? 'None' : field}
              </Typography>
            )}
            <Table
              columns={columns}
              defaultOrderBy={false}
              isFetching={isFetching}
              isLoading={isLoading}
              rows={value}
              {...props}
            />
          </Box>
        ))}
      </TableContainer>
    </div>
  );
};
