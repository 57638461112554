import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  DraggableAttributes,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useMemo, useState, useEffect } from 'react';
import { getCurve } from 'features/products/constants/curves';
import { ProductLineFormDialog } from './ProductLineFormDialog';
import { ProductLineActionMenu } from './ProductLineActionMenu';
import { formatCurrency } from 'helpers/numberFormatter';
import { ProductLineItem } from 'features/estimates/types/productLine.models';
import { ProductLineTableRow } from './ProductLineTableRow';
import { useReorderProductLineItemsMutation } from 'features/estimates/api/productLine.api';
import AddIcon from '@mui/icons-material/Add';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DragIndicator } from '@mui/icons-material';

interface ColumnConfig {
  label: string;
  field: string;
  hide?: boolean;
  hidePrint?: boolean;
  align?: 'left' | 'center' | 'right';
  sx?: SxProps;
  render: (
    row: any,
    props: {
      dragProps: {
        attributes: DraggableAttributes;
        listeners: SyntheticListenerMap;
        isDragging: boolean;
      };
    }
  ) => React.ReactNode;
}

interface ColumnGroup {
  label: string;
  columns: string[];
  // bgcolor: string;
  props: TableCellProps;
  // sx?: Record<string, any>;
}

const columnGroups: ColumnGroup[] = [
  {
    label: '',
    columns: ['actions'],
    props: { sx: { backgroundColor: '#fff' } },
  },
  {
    label: 'Base Information',
    columns: ['product', 'estimateBase', 'romOfUnit', 'revenueTotal'],
    props: { sx: { backgroundColor: '#f5f5f5' } },
  },
  {
    label: 'Material Revenue',
    columns: ['materialRevenueSplit', 'materialRevenueCogs'],
    props: { sx: { backgroundColor: '#e3f2fd' } },
  },
  {
    label: 'Labor Revenue',
    columns: ['laborRevenueSplit', 'laborRevenueCogs'],
    props: { sx: { backgroundColor: '#e8f5e9' } },
  },
  {
    label: 'Labor Details',
    columns: ['curveType', 'laborHours', 'trade', 'rate'],
    props: { sx: { backgroundColor: '#f3e5f5' } },
  },
];

const ProductLineTableColumns: Record<string, ColumnConfig> = {
  actions: {
    label: '',
    field: 'actions',
    align: 'center',
    hidePrint: true,
    render: (productLineItem, { dragProps }) => (
      <Stack alignItems={'center'} direction={'row'}>
        <IconButton
          sx={{ cursor: dragProps.isDragging ? 'grabbing' : 'grab' }}
          {...dragProps.attributes}
          {...dragProps.listeners}
          size={'small'}
        >
          <DragIndicator />
        </IconButton>
        <ProductLineActionMenu productLineItem={productLineItem} />
      </Stack>
    ),
  },
  product: {
    label: 'Product',
    field: 'name',
    render: ({ name }) => name,
  },
  estimateBase: {
    label: 'Estimate Based',
    field: 'estimate_base',
    render: ({ estimate_base }) => `${estimate_base} Sqft`,
  },
  romOfUnit: {
    label: 'Estimate of Unit',
    field: 'rom_of_unit',
    render: ({ rom_of_unit }) => `${rom_of_unit} Sqft`,
  },
  revenueTotal: {
    label: 'Estimate Total',
    field: 'revenue_total',
    render: ({ revenue_total }) => formatCurrency(revenue_total),
  },
  materialRevenueSplit: {
    label: 'Estimate Split',
    field: 'material_percent',
    render: ({ material_percent, revenue_material }) =>
      `(${material_percent}%)\n${formatCurrency(revenue_material)}`,
  },
  materialRevenueCogs: {
    label: 'Cost %',
    field: 'cogs_material_percent',
    hide: true,
    render: ({ cogs_material_percent, cogs_material }) =>
      `(${cogs_material_percent}%)\n${formatCurrency(cogs_material)}`,
    sx: { color: 'error.main' },
  },
  laborRevenueSplit: {
    label: 'Estimate Split',
    field: 'labor_percent',
    render: ({ labor_percent, revenue_labor }) =>
      `(${labor_percent}%)\n${formatCurrency(revenue_labor)}`,
  },
  laborRevenueCogs: {
    label: 'Cost %',
    field: 'cogs_labor_percent',
    hide: true,
    render: ({ cogs_labor_percent, cogs_labor }) =>
      `(${cogs_labor_percent}%)\n${formatCurrency(cogs_labor)}`,
    sx: { color: 'error.main' },
  },
  curveType: {
    label: 'Curve Type',
    field: 'labor_curve',
    render: ({ labor_curve }) => getCurve(labor_curve)?.icon,
  },
  laborHours: {
    label: 'Hours',
    field: 'labor_hours',
    render: ({ labor_hours }) => labor_hours,
  },
  trade: {
    label: 'Trade',
    field: 'trade_id',
    render: ({ trade_id }) => trade_id,
  },
  rate: {
    label: 'Rate',
    hide: true,
    field: 'cogs_per_hour',
    render: ({ cogs_per_hour }) => `${formatCurrency(cogs_per_hour)}`,
  },
};

interface ProductLineTablePropTypes {
  estimateId: number;
  sectionId: number;
  hideSensitive?: boolean;
  productLineItems?: ProductLineItem[];
}

export const ProductLineTable: React.FC<ProductLineTablePropTypes> = ({
  productLineItems,
  estimateId,
  sectionId,
  hideSensitive = false,
}) => {
  const [updateProductLineItemOrder] = useReorderProductLineItemsMutation();

  const [items, setItems] = useState<ProductLineItem[]>(
    productLineItems?.length ? productLineItems : []
  );
  const isPrint = useMediaQuery('print');

  const { columns: visibleColumns, groups: visibleColumnGroups } = useMemo(() => {
    // Filter columns once
    const columns = Object.entries(ProductLineTableColumns).filter(([_, column]) => {
      if (isPrint && column.hidePrint) return false;
      if (hideSensitive && column.hide) return false;
      return true;
    });

    // Create a Set for O(1) lookup when filtering groups
    const visibleColumnKeys = new Set(columns.map(([key]) => key));

    // Filter groups using the Set
    const groups = columnGroups
      .map((group) => ({
        ...group,
        columns: group.columns.filter((key) => visibleColumnKeys.has(key)),
      }))
      .filter((group) => group.columns.length > 0);

    return {
      columns,
      groups,
    };
  }, [isPrint, hideSensitive]);

  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      keyboardCodes: {
        start: ['Space'],
        cancel: ['Escape'],
        end: ['Space'],
      },
    })
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      const newItems = arrayMove(items, oldIndex, newIndex);
      const lineItemOrder = newItems.map((item: ProductLineItem) => Number(item?.id));
      setItems(newItems);
      updateProductLineItemOrder({
        line_ids: lineItemOrder,
        estimateId,
        sectionId,
      }).then(() => {});
    }
  };

  useEffect(() => {
    productLineItems?.length ? setItems(productLineItems) : setItems([]);
  }, [productLineItems]);

  return (
    <Paper elevation={3} sx={{ width: '100%', mt: 2 }}>
      <ProductLineTableHeader
        estimateId={estimateId}
        noOfProducts={items?.length || 0}
        sectionId={sectionId}
      />
      <Divider />
      {items?.length ? (
        <TableContainer>
          <DndContext
            collisionDetection={closestCenter}
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          >
            <Table
              stickyHeader
              size={'small'}
              sx={{
                'td:not(:last-child), th:not(:last-child) ': {
                  borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  {visibleColumnGroups?.map((group, index) => (
                    <TableCell colSpan={group.columns.length} key={index} {...group.props}>
                      {group.label}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {visibleColumns.map(([key, column]) => (
                    <TableCell align={column.align || 'left'} key={key} sx={column.sx}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <SortableContext
                  items={((items as any[]) || []).map((item) => item.id)}
                  strategy={verticalListSortingStrategy}
                >
                  {items?.map((product) => (
                    <ProductLineTableRow
                      columns={visibleColumns}
                      id={product.id}
                      key={product.id}
                      product={product}
                    />
                  ))}
                </SortableContext>
              </TableBody>
            </Table>

            <DragOverlay>
              {activeId && (
                <ProductLineTableRow
                  columns={visibleColumns}
                  id={activeId}
                  isOverlay={true}
                  product={items.find((item) => item.id === activeId)}
                />
              )}
            </DragOverlay>
          </DndContext>
        </TableContainer>
      ) : (
        <Alert severity="warning">No product line items have been added.</Alert>
      )}
    </Paper>
  );
};

const ProductLineTableHeader = ({
  noOfProducts,
  estimateId,
  sectionId,
}: {
  noOfProducts: number;
  estimateId: number;
  sectionId: number;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'} p={2}>
      <Box>
        <Stack alignItems="center" direction="row" spacing={1.5}>
          <Typography component="h2" variant="h6">
            Product Line Items
          </Typography>
          <Chip
            label={`${noOfProducts} items`}
            size="small"
            sx={{
              backgroundColor: (theme) => theme.palette.grey[200],
              borderRadius: '12px',
            }}
          />
        </Stack>
      </Box>

      <Button
        size={'small'}
        startIcon={<AddIcon />}
        sx={{ displayPrint: 'none' }}
        variant="contained"
        onClick={() => setIsDialogOpen(true)}
      >
        Add Product Line
      </Button>
      {isDialogOpen && (
        <ProductLineFormDialog
          estimateId={estimateId}
          handleClose={() => setIsDialogOpen(false)}
          open={isDialogOpen}
          sectionId={sectionId}
        />
      )}
    </Stack>
  );
};
