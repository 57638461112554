import { useEffect } from 'react';
import { configExportPlugin } from 'features/gantt/components/ExportModule';

export const useGanttCustomPrint = (gantt) => {
  useEffect(() => {
    if (!gantt) return;

    gantt.attachEvent("onGanttReady", () => {
      configExportPlugin(gantt, () => { });
    });

    return () => gantt.detachEvent("onGanttReady");
  }, [gantt]);
};