import { api, providesList } from 'api';
import { getSocket } from 'helpers/websockets';

export const PROJECT_TAG = 'Project';

const projectAPI = api
  .enhanceEndpoints({ addTagTypes: [PROJECT_TAG, 'ProjectHolidays', 'Task'] })
  .injectEndpoints({
    endpoints: (build) => ({
      createProject: build.mutation({
        query: (data) => {
          return {
            url: `/projects`,
            method: 'POST',
            body: data,
          };
        },
        invalidatesTags: (result, error, params) =>
          result ? [{ type: PROJECT_TAG, id: 'LIST' }] : [],
      }),

      getProjects: build.query({
        query: (params) => ({
          url: `/projects?include=jobWalkPerformance,holidays`,
          params: { status: params?.status },
        }),
        providesTags: (data) => providesList(data, PROJECT_TAG),
        transformResponse: (response) => response.data,
        // **  Commented out as there is a bug with RTK query and upsertQueryEntries that puts the upserted query into a continuous 'pending' state it has been fixed but the fix has not been released yet **
        //   async onQueryStarted(_, { dispatch, queryFulfilled }) {
        //     const res = await queryFulfilled;
        //     const projects = res.data;
        //     // Pre-fill individual project caches
        //     dispatch(
        //       api.util.upsertQueryEntries(
        //         projects.map((project) => ({
        //           endpointName: 'getProject',
        //           arg: project.id,
        //           value: project,
        //         }))
        //       )
        //     );
        //   },
      }),

      getProject: build.query({
        query: (id) => `/projects/${id}`,
        providesTags: (result, error, id) => {
          return result ? [{ type: PROJECT_TAG, id: result.id }] : [];
        },
        transformResponse: (response) => response.data,
        async onCacheEntryAdded(
          id,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState, dispatch }
        ) {
          await cacheDataLoaded;
          const socket = getSocket();
          const { workspaceId } = getState().auth;
          const channelName = `workspaces.${workspaceId}.projects.${id}`;

          try {
            await cacheDataLoaded;
            const handleUpdateProject = (data) => {
              updateCachedData((draft) => {
                Object.assign(draft, data);
                dispatch(
                  api.util.updateQueryData('getProjects', { workspaceId: '35' }, (draft) => {
                    const index = draft.findIndex((project) => project.id === data.id);
                    if (index !== -1) {
                      draft[index] = { ...draft[index], ...data };
                    }
                  })
                );
              });
            };

            const eventListeners = {
              ProjectUpdated: handleUpdateProject,
            };

            const connectedChannel = socket.join(channelName);
            for (const [event, listener] of Object.entries(eventListeners)) {
              const eventListener = (data) => {
                listener(data);
              };
              connectedChannel.listen(event, eventListener);
            }
          } catch (error) {
            console.log('Error while connecting to sockets while subscribing project =>', error);
          }
          await cacheEntryRemoved;
          socket?.leave(channelName);
        },
      }),

      updateProject: build.mutation({
        query: ({ id, ...data }) => ({
          url: `/projects/${id}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: (result, error, args) =>
          result
            ? [
                { type: PROJECT_TAG, id: args?.id },
                { type: PROJECT_TAG, id: 'LIST' },
              ]
            : [],
      }),

      deleteProject: build.mutation({
        query: (id) => ({
          url: `/projects/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, id) =>
          result
            ? [
                { type: PROJECT_TAG, id },
                { type: PROJECT_TAG, id: 'LIST' },
              ]
            : [],
      }),

      duplicateProject: build.mutation({
        query: (id) => ({
          url: `projects/${id}/duplicate`,
          method: 'POST',
        }),
        invalidatesTags: (result, error, id) =>
          result
            ? [
                { type: PROJECT_TAG, id },
                { type: PROJECT_TAG, id: 'LIST' },
              ]
            : [],
      }),

      updateProjectKpi: build.mutation({
        query: (id) => ({
          url: `projects/${id}/kpi`,
          method: 'POST',
        }),
      }),

      getProjectHolidays: build.query({
        query: (id) => `projects/${id}/holidays`,
        providesTags: (data) => {
          return providesList(data?.listOfHolidays, 'ProjectHolidays');
        },
        transformResponse: (response) => ({
          listOfHolidays: response.data,
          holidays: response.data.map((holiday) => holiday.date),
        }),
      }),

      createProjectHoliday: build.mutation({
        query: (data) => {
          return {
            url: `/projects/${data.projectId}/holidays`,
            method: 'POST',
            body: data,
          };
        },
        invalidatesTags: (result, error, params) => (result ? [{ type: 'ProjectHolidays' }] : []),
      }),

      updateProjectHoliday: build.mutation({
        query: ({ id, ...data }) => ({
          url: `/holidays/${id}`,
          method: 'PATCH',
          body: data,
        }),
        invalidatesTags: (result, error, args) =>
          result ? [{ type: 'ProjectHolidays', id: args?.id }] : [],
      }),

      deleteProjectHoliday: build.mutation({
        query: (id) => ({
          url: `/holidays/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, id) => (result ? [{ type: 'ProjectHolidays' }] : []),
      }),

      fillProjectHolidays: build.mutation({
        query: (id) => ({
          url: `projects/${id}/holidays/fill`,
          method: 'POST',
        }),
        invalidatesTags: (result, error, id) => (result ? [{ type: 'ProjectHolidays' }] : []),
      }),

      runAutoSchedulerJob: build.mutation({
        query: (id) => ({
          url: `projects/${id}/autoschedule`,
          method: 'POST',
        }),
        invalidatesTags: () => [{ type: 'Task' }],
      }),
    }),
  });

export const {
  useGetProjectQuery,
  useGetProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useDuplicateProjectMutation,
  useUpdateProjectKpiMutation,
  useGetProjectHolidaysQuery,
  useCreateProjectHolidayMutation,
  useUpdateProjectHolidayMutation,
  useDeleteProjectHolidayMutation,
  useFillProjectHolidaysMutation,
  useRunAutoSchedulerJobMutation,
} = projectAPI;
