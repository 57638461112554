export enum GanttColumnLabels {
  ACTION = 'Action',
  WBS = 'WBS',
  NAME = 'Name',
  UUID = 'Identifier',
  CALENDAR_DAYS = 'Calendar Days',
  WORKING_DAYS = 'Working Days',
  DURATION = 'Duration',
  STATUS = 'Status',
  START_DATE = 'Scheduled Start',
  END_DATE = 'Scheduled End',
  FORECASTED_START_DATE = 'Forecasted Start',
  FORECASTED_END_DATE = 'Forecasted End',
  ACTUAL_START_DATE = 'Actual Start',
  ACTUAL_END_DATE = 'Actual End',
  EFFORT_TAG = 'Effort',
  EFFORT_HOURS = 'Hours',
  PREDECESSOR = 'Predecessors',
  CREW_SIZE = 'Crew',
  LOCATION = 'Location',
  ZONE = 'Zone',
  AREA = 'Area',
  COMPANIES = 'Companies',
  CONTACTS = 'Contacts',
  RESPONSIBLE = 'Responsible',
  CONSTRAINT_TYPE = 'Constraint Type',
  CONSTRAINT_DATE = 'Constraint Date',
  TOTAL_FLOAT = 'Total Float',
  PROGRESS = '% Complete',
  FREE_FLOAT = 'Free Float',
  PROJECT = 'Project',
  SUCCESSOR_COLUMN = 'Successors',
  PACE = 'Pace',
  EARNED_DAYS = 'Earned Days',
  EARNED_PLANNED_DAYS = 'Earned Planned Days',
  PR = 'PR',
  TRADES = 'Trades',
  TASK_TYPE = 'Task Type',
  RESOURCE_DEMAND = 'Resource Demand',
  // RESOURCE_ALLOCATION = 'Resource Allocation',
  DEMANDER_PER_DAY = 'Demand per Day',
  MATERIAL_DEMAND = 'Demand Tons',
  MATERIAL_DEMAND_PER_DAY = 'Tons Per Day',
  RESOURCES = 'Resources',
  GENERATED_TAGS = 'LBS',
  TAGS = 'Tags',
  PROCUREMENTS = 'Procurements',
}
