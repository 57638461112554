import { useState } from 'react';
import {
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Grid,
  InputAdornment,
} from '@mui/material';
import { Delete, Add } from '@mui/icons-material';

export function MappingEditor({ mapping, items, onAdd, onRemove, onChange }: any) {
  const [selectedItem, setSelectedItem] = useState(mapping?.labor_estimate_item_id || '');
  const [percentage, setPercentage] = useState(mapping?.percentage || 100);

  const handleAdd = () => {
    onAdd?.({
      id: Date.now(),
      labor_estimate_item_id: selectedItem,
      percentage,
    });
    setSelectedItem('');
    setPercentage(100);
  };

  return (
    <Box>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <Select
            displayEmpty
            fullWidth
            size="small"
            value={selectedItem}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedItem(value);
              if (onChange && mapping) {
                onChange({
                  ...mapping,
                  labor_estimate_item_id: value,
                });
              }
            }}
          >
            {items.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item['task:name']} ({item['task:demand']})
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            type="number"
            value={percentage}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              setPercentage(value);
              if (onChange && mapping) {
                onChange({
                  ...mapping,
                  percentage: value,
                });
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          {mapping ? (
            <IconButton color="error" size="small" onClick={() => onRemove?.(mapping)}>
              <Delete />
            </IconButton>
          ) : (
            <Button color="primary" disabled={!selectedItem} onClick={handleAdd}>
              <Add /> Add
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
