import { Box, Button, Container, Typography, alpha } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import { grey } from '@mui/material/colors';
import { useGetImportQuery, useUpdateImportMutation } from '../../api/imports.api';
import { BoundingBox } from '../../api/imports.models';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentLayout } from 'components/layouts';

export function ImportBoundingBox(): JSX.Element {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const navigate = useNavigate();
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(null);
  const [currentBox, setCurrentBox] = useState<BoundingBox | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [updateImport] = useUpdateImportMutation();
  const importId = Number(useParams().importId) || undefined;

  const { data: backendImport = null } = useGetImportQuery(
    { backendImportId: importId },
    { skip: !importId }
  );

  // Load and draw the image
  useEffect(() => {
    const newImage = new Image();
    newImage.src = backendImport?.previewUrl ?? '';

    newImage.onload = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      canvas.width = newImage.width;
      canvas.height = newImage.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      ctx.drawImage(newImage, 0, 0);
      setImage(newImage);
      setImageLoaded(true);
    };
  }, [backendImport?.previewUrl]);

  const getCanvasCoordinates = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return { x: 0, y: 0 };

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    return {
      x: (e.clientX - rect.left) * scaleX,
      y: (e.clientY - rect.top) * scaleY,
    };
  };

  const normalizeCoordinates = (pixelBox: {
    x: number;
    y: number;
    width: number;
    height: number;
  }) => {
    const canvas = canvasRef.current;
    if (!canvas)
      return {
        bounding_box_left: 0,
        bounding_box_top: 0,
        bounding_box_width: 0,
        bounding_box_height: 0,
      };

    return {
      bounding_box_left: pixelBox.x / canvas.width,
      bounding_box_top: pixelBox.y / canvas.height,
      bounding_box_width: pixelBox.width / canvas.width,
      bounding_box_height: pixelBox.height / canvas.height,
    };
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const coords = getCanvasCoordinates(e);
    setIsDrawing(true);
    setStartPoint(coords);
    setCurrentBox(null);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawing || !startPoint || !image) return;

    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) return;

    // Redraw the image
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0);

    // Get current mouse position
    const currentPoint = getCanvasCoordinates(e);

    // Calculate box dimensions in pixel space
    const width = Math.abs(currentPoint.x - startPoint.x);
    const height = Math.abs(currentPoint.y - startPoint.y);
    const x = Math.min(startPoint.x, currentPoint.x);
    const y = Math.min(startPoint.y, currentPoint.y);

    // Draw the rectangle in pixel space
    ctx.strokeStyle = '#2196f3';
    ctx.lineWidth = 2;
    ctx.strokeRect(x, y, width, height);

    // Normalize coordinates for the bounding box
    const normalizedCoords = normalizeCoordinates({ x, y, width, height });

    // Create bounding box data with normalized coordinates
    const box: BoundingBox = {
      ...normalizedCoords,
    };
    setCurrentBox(box);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const handleConfirm = () => {
    if (currentBox) {
      handleBoundingBoxComplete(currentBox);
    }
  };

  const handleBoundingBoxComplete = async (boundingBox: BoundingBox) => {
    try {
      if (!importId) {
        throw new Error('No import selected');
      }

      await updateImport({
        backendImportId: importId,
        updateData: boundingBox,
      });

      navigate(-1);
    } catch (e) {
      console.error('Failed to update import:', e);
    }
  };
  // Format coordinate display with more precision for normalized values
  const formatCoordinate = (value: number) => value.toFixed(3);

  return (
    <ContentLayout>
      <Container maxWidth="xl">
        <Box sx={{ height: '85vh', display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              flex: 1,
              backgroundColor: grey[400],
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <canvas
                ref={canvasRef}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  cursor: isDrawing ? 'crosshair' : 'default',
                }}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseUp}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
              />
              {!imageLoaded && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: alpha(grey[400], 0.8),
                  }}
                >
                  <Typography>Loading image...</Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              p: 2,
              borderTop: 1,
              borderColor: 'divider',
              bgcolor: 'background.paper',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                maxWidth: 'lg',
                mx: 'auto',
                width: '100%',
              }}
            >
              {currentBox ? (
                <Typography color="text.secondary" variant="body2">
                  Normalized coordinates: ({formatCoordinate(currentBox.bounding_box_left)},{' '}
                  {formatCoordinate(currentBox.bounding_box_top)}) -{' '}
                  {formatCoordinate(currentBox.bounding_box_width)} ×{' '}
                  {formatCoordinate(currentBox.bounding_box_height)}
                </Typography>
              ) : (
                <Typography color="text.secondary" variant="body2">
                  No box selected.
                </Typography>
              )}
              <Button
                color="primary"
                disabled={!currentBox}
                variant="contained"
                onClick={handleConfirm}
              >
                Confirm Selection
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </ContentLayout>
  );
}
