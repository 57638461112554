import {
  Button,
  Box,
  Grid,
  CircularProgress,
  FormLabel,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { breakdownChanges } from '../models/breakdown';
import { useCallback, useEffect } from 'react';
import { StyledDialog } from '@blackhyve/common';
import { Controller, useForm } from 'react-hook-form';
import {
  useCreateBudgetLineMutation,
  useUpdateBudgetLineMutation,
} from '../store/budget-lines.api';
import {
  formatDecimals,
  formatMoney,
  formatToCents,
  MoneyInput,
  PercentInput,
  unformatMoney,
} from './NumericControls';
import {
  BELL_CURVE,
  NORMAL_DISTRIBUTION,
  CurveOptions,
  DistributionOptions,
} from '../models/curves';
import { useGetProductsQuery } from 'features/products/api/products.api';
import { format, parseISO } from 'date-fns';
import { TradesAutocomplete } from 'features/trades/components/TradesAutocomplete';
import { BudgetMonthPicker, isAfterStart } from './BudgetMonthPicker';

const initialState = {
  division: null,
  cost_code: '',
  description: '',
  trade_id: null,
  start_date: null,
  end_date: null,

  amount: '',
  percent: '10',
  labor_amount: '',
  labor_percent: '50',
  labor_curve: BELL_CURVE,
  labor_curve_distribution: NORMAL_DISTRIBUTION,
  material_amount: '',
  material_percent: '50',
  material_curve: BELL_CURVE,
  material_curve_distribution: NORMAL_DISTRIBUTION,
};

const TOP_DOWN = 'top_down';

function computeBudgetInitialState(budget) {
  const rawAmount = budget.amount * (initialState.percent / 100);
  const amount = formatMoney(rawAmount);
  const material_amount = formatMoney(rawAmount * (initialState.material_percent / 100));
  const labor_amount = formatMoney(rawAmount * (initialState.labor_percent / 100));
  return {
    amount,
    labor_amount,
    material_amount,
  };
}

function computeBudgetLineInitialState(line) {
  return {
    ...line,
    start_date: line?.start_date ? parseISO(line.start_date) : null,
    end_date: line?.end_date ? parseISO(line.end_date) : null,
  };
}

export const BudgetLineFormDialog = ({ creating = false, open, handleClose, budget, line }) => {
  const { data: products = [], isLoading: isLoadingProducts } = useGetProductsQuery();
  const { id: budgetId, strategy } = budget;
  const { watch, control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      ...initialState,
      ...computeBudgetInitialState(budget),
      ...computeBudgetLineInitialState(line ?? {}),
    },
  });

  useEffect(() => {
    reset({
      ...initialState,
      ...computeBudgetInitialState(budget),
      ...computeBudgetLineInitialState(line ?? {}),
    });
  }, [line, budget, reset]);

  const [createBudgetLine, { isLoading: isLoadingCreatingBudgetLine }] =
    useCreateBudgetLineMutation();

  const [updateBudgetLine, { isLoading: isLoadingUpdatingBudgetLine }] =
    useUpdateBudgetLineMutation();

  const isLoading = isLoadingCreatingBudgetLine || isLoadingUpdatingBudgetLine;

  async function handleStore(data) {
    if (line?.id) {
      await updateBudgetLine({
        budgetLineId: line?.id,
        ...data,
        start_date: data.start_date ? format(data.start_date, 'yyyy-MM-dd') : null,
        end_date: data.end_date ? format(data.end_date, 'yyyy-MM-dd') : null,
        amount: unformatMoney(data.amount, true),
        labor_amount: unformatMoney(data.labor_amount, true),
        material_amount: unformatMoney(data.material_amount, true),
      })
        .unwrap()
        .then(() => {
          reset();
          handleClose();
        });
    } else {
      await createBudgetLine({
        budgetId,
        ...data,
        start_date: data.start_date ? format(data.start_date, 'yyyy-MM-dd') : null,
        end_date: data.end_date ? format(data.end_date, 'yyyy-MM-dd') : null,
        amount: unformatMoney(data.amount, true),
        labor_amount: unformatMoney(data.labor_amount, true),
        material_amount: unformatMoney(data.material_amount, true),
      })
        .unwrap()
        .then(() => {
          reset();
          handleClose();
        });
    }
  }

  const handlePercentChanged = useCallback(
    (state) => {
      const rawAmount = budget.amount * (state.percent / 100);
      setValue('amount', formatMoney(rawAmount));

      setValue('material_amount', formatMoney(rawAmount * (state.material_percent / 100)));
      setValue('labor_amount', formatMoney(rawAmount * (state.labor_percent / 100)));
    },
    [budget.amount, setValue]
  );

  useEffect(() => {
    const laborWatcher = watch((state, { name, type }) => {
      if (!type) return;

      if (name === 'percent') {
        handlePercentChanged(state);
      }

      if (name === 'amount') {
        const newAmount = unformatMoney(state.amount, true);
        setValue('percent', formatDecimals((newAmount / Math.max(budget.amount, 1)) * 100));
      }

      breakdownChanges(state, name).forEach(({ field, value }) => {
        setValue(field, value);
      });
    });

    return () => {
      laborWatcher.unsubscribe();
    };
  }, [watch, setValue, strategy, budget, handlePercentChanged]);

  const state = watch();

  const prefillFromProduct = (product) => {
    const newState = { ...state };

    [
      'division',
      'cost_code',
      'description',
      'trade_id',
      'percent',
      'labor_percent',
      'labor_curve',
      'labor_curve_distribution',
      'material_percent',
      'material_curve',
      'material_curve_distribution',
    ].forEach((field) => {
      setValue(field, (newState[field] = product[field]));
    });

    handlePercentChanged(newState);
  };

  return (
    <StyledDialog
      DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={() => handleClose()}
      open={open}
      title={creating ? 'Create Budget Line' : 'Edit Budget Line'}
      actions={
        <>
          <Button disabled={isLoading} onClick={() => handleClose()}>
            Close
          </Button>
          <Button
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={'1rem'} />}
            variant={'contained'}
            onClick={handleSubmit(handleStore)}
          >
            {creating ? 'Add' : 'Save'}
          </Button>
        </>
      }
    >
      <Grid container item spacing={2} xs={12}>
        <Grid container item xs={6}>
          <FormLabel>Division</FormLabel>
          <Box width={'100%'}>
            <Controller
              control={control}
              name="division"
              rules={{ required: 'Division is required.' }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  autoHighlight
                  disableClearable
                  freeSolo
                  groupBy={() => 'Products'}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  loading={isLoadingProducts}
                  options={products}
                  size="small"
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') return option;

                    return (
                      products.find((product) => product?.division === option?.division)
                        ?.division || 'Unknown'
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(error)}
                      helperText={error?.message}
                      id="division-input"
                      placeholder="Division"
                      variant="outlined"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      {option?.division} ({option?.cost_code}) - {option?.description}
                    </li>
                  )}
                  onChange={(_event, newValue) => {
                    if (newValue) {
                      prefillFromProduct(newValue);
                    }
                  }}
                  onInputChange={(event, data) => {
                    field.onChange(data);
                  }}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid container item xs={6}>
          <FormLabel>Cost Code</FormLabel>
          <Controller
            control={control}
            name="cost_code"
            rules={{ required: 'Cost Code field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid container item xs={12}>
          <FormLabel>Description</FormLabel>
          <Controller
            control={control}
            name="description"
            rules={{ required: 'Description field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={error}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>

        <Grid container item xs={12}>
          <FormLabel>Trade</FormLabel>
          <Box width={'100%'}>
            <Controller
              control={control}
              name="trade_id"
              render={({ field }) => (
                <TradesAutocomplete
                  {...field}
                  onChange={(_event, newValue) => {
                    field.onChange(newValue ?? null);
                  }}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid item xs={6}>
            <FormLabel>Start Date (optional)</FormLabel>
            <Controller
              control={control}
              name="start_date"
              render={({ field: { ref, ...field }, fieldState }) => (
                <BudgetMonthPicker field={field} fieldState={fieldState} ref={ref} />
              )}
            />
            <FormHelperText>Leave blank to use the project start date.</FormHelperText>
          </Grid>

          <Grid item xs={6}>
            <FormLabel>End Date (optional)</FormLabel>
            <Controller
              control={control}
              name="end_date"
              render={({ field: { ref, ...field }, fieldState }) => (
                <BudgetMonthPicker
                  field={field}
                  fieldState={fieldState}
                  minDate={state.start_date}
                  ref={ref}
                />
              )}
              rules={{
                validate: {
                  isAfterStart: (value) => isAfterStart(value, state.start_date),
                },
              }}
            />
            <FormHelperText>Leave blank to use the project estimated end date.</FormHelperText>
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Percentage of estimated budget</FormLabel>
            <Controller
              control={control}
              name="percent"
              rules={{ required: 'Estimated Percent of budget field is required' }}
              render={({ field, fieldState: { error } }) => (
                <PercentInput {...field} fullWidth disabled={strategy !== TOP_DOWN} error={error} />
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Estimated Value ($)</FormLabel>
            <Controller
              control={control}
              name="amount"
              rules={{ required: 'Estimated Value field is required' }}
              render={({ field, fieldState: { error } }) => (
                <MoneyInput
                  {...field}
                  defaultMax={formatToCents(budget.amount)}
                  disabled={strategy == TOP_DOWN}
                  error={error}
                  setValue={setValue}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Estimated Labor (%)</FormLabel>
            <Controller
              control={control}
              name="labor_percent"
              rules={{ required: 'Estimated Labor Percent field is required' }}
              render={({ field, fieldState: { error } }) => (
                <PercentInput {...field} fullWidth disabled={strategy !== TOP_DOWN} error={error} />
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Estimated Material (%)</FormLabel>
            <Controller
              control={control}
              name="material_percent"
              rules={{ required: 'Estimated Material Percent field is required' }}
              render={({ field, fieldState: { error } }) => (
                <PercentInput {...field} fullWidth disabled={strategy !== TOP_DOWN} error={error} />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Estimated Labor ($)</FormLabel>
            <Controller
              control={control}
              name="labor_amount"
              rules={{ required: 'Estimated Labor Amount field is required' }}
              render={({ field, fieldState: { error } }) => (
                <MoneyInput
                  {...field}
                  defaultMax={unformatMoney(state.amount)}
                  disabled={strategy == TOP_DOWN}
                  error={error}
                  setValue={setValue}
                />
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Estimated Material ($)</FormLabel>
            <Controller
              control={control}
              name="material_amount"
              rules={{ required: 'Estimated Material Amount field is required' }}
              render={({ field, fieldState: { error } }) => (
                <MoneyInput
                  {...field}
                  defaultMax={unformatMoney(state.amount)}
                  disabled={strategy == TOP_DOWN}
                  error={error}
                  setValue={setValue}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Labor Curve</FormLabel>
            <Controller
              control={control}
              name="labor_curve"
              rules={{ required: 'Labor Curve field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth name={'name'} size={'small'}>
                  {CurveOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Material Curve</FormLabel>
            <Controller
              control={control}
              name="material_curve"
              rules={{ required: 'Material Curve field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth name={'name'} size={'small'}>
                  {CurveOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Labor Curve Distribution</FormLabel>
            <Controller
              control={control}
              name="labor_curve_distribution"
              rules={{ required: 'Labor Curve Distribution field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth name={'name'} size={'small'}>
                  {DistributionOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Material Curve Distribution</FormLabel>
            <Controller
              control={control}
              name="material_curve_distribution"
              rules={{ required: 'Material Curve Distribution field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth name={'name'} size={'small'}>
                  {DistributionOptions.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};
