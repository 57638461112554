import { GanttConfigOptions, GanttStatic, NewResourceItem } from '@blackhyve/dhtmlx-gantt';
import {
  ResourceRequestAssignmentTask,
  ResourceTask,
} from 'features/resources/api/resources.models';
import { useCallback } from 'react';
import { getLayout } from '../config/layout';
import { scales as defaultScales } from '../config/scales';
import { formatWorkdayCalendar } from '../utils/formatter';

const typeRenderers: GanttConfigOptions['type_renderers'] = {
  request_status: () => {
    return false;
  },
  resource_request: () => {
    return false;
  },
};

const ICONS = {
  DELETE:
    '<svg viewBox="0 0 24 24" width="22" height="22" fill="red"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>',
  ADD: '<svg viewBox="0 0 24 24" width="22" height="22" fill="#448aff"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>',
  EDIT: '<svg viewBox="0 0 24 24" width="18" height="18"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>',
  TICK: '<svg  viewBox="0 0 24 24" width="22" height="22"><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="#1eb97f"/></svg>',
};

export const useOperationsBoardConfig = ({
  startDate,
  endDate,
  layout = getLayout,
  scales = defaultScales,
}) => {
  const getConfig = useCallback(
    (gantt: GanttStatic): Partial<GanttConfigOptions> => ({
      columns: [
        {
          name: 'uuid',
          label: 'UUID',
          align: 'left',
          max_width: 110,
          width: '*',
          template: (task) => task.uuid,
        },
        {
          name: 'text',
          label: 'Name',
          align: 'left',
          tree: true,
          resize: true,
          width: '*',
          template: (task) => {
            if (task.type === 'resource_request') {
              return `<span class="flex">${gantt.getCollectionEntity('trade', task.trade_id)?.name || ''} ${task.role_id ? `-${gantt.getCollectionEntity('role', task.role_id)?.name}` : ''}</span > `;
            } else if (task.type === 'task') {
              if (task.resource_id) {
                const resource = gantt
                  .getDatastore(gantt.config.resource_store)
                  .getItem(task.resource_id) as NewResourceItem;
                return resource?.text || '';
              } else {
                return 'Unassigned';
              }
            } else {
              return task.text;
            }
          },
        },
        {
          name: 'project',
          label: 'Project',
          width: 150,
          resize: true,
          template: (task) => {
            return gantt.getCollectionEntity('project', task.project_id)?.name || '';
          },
        },
        {
          name: '',
          label: '',
          width: 105,
          max_width: 105,
          min_width: 105,
          template: function (task) {
            return `<div class="row action-icons" style="display: flex; align-items: center; gap: 5px;">
              ${
                task.type === gantt.config.types.resource_request
                  ? `<span class="flex icon save-icon ${task.type === 'task' ? 'disabled' : ''}" data-action="save">${ICONS.TICK}</span>
                      <span class="flex icon add-icon ${task.type === 'task' ? 'disabled' : ''}" data-action="add">${ICONS.ADD}</span>`
                  : task.type === gantt.config.types.task
                    ? `<span class="flex icon delete-icon" data-action="delete">${ICONS.DELETE}</span>`
                    : ''
              }
                    </div>`;
          },
        },
      ],
      types: {
        ...gantt.config.types,
        request_status: 'request_status',
        resource_request: 'resource_request',
      },
      row_height: 40,
      bar_height: 25,
      parent_row_height: 35,
      parent_bar_height: 20,
      drag_links: false,
      show_links: false,
      show_tasks_outside_timescale: true,
      auto_types: false,
      smart_rendering: true,
      static_background: true,
      start_date: startDate,
      end_date: endDate,
      grid_elastic_columns: true,
      layout: typeof layout === 'function' ? layout(gantt) : layout,
      resource_property: 'resource_id',
      process_resource_assignments: true,
      resources: {
        resource_store: {
          fetchTasks: true,
          type: 'treeDataStore',
          initItem: function (item: ResourceTask | ResourceRequestAssignmentTask) {
            // if (!gantt.config.resource_calendars) gantt.config.resource_calendars = {};
            if (item.workday_calendar) {
              gantt.config.resource_calendars[item.id] = gantt.addCalendar(
                formatWorkdayCalendar(item.workday_calendar)
              );
              delete item.workday_calendar;
            }
            return item;
          },
        },
      },
      // resource_calendars: getResourceCalendars(resources, gantt),
      scales: scales,
      min_column_width: 50,
      scale_height: 50,
      order_branch: false,
      order_branch_free: false,
      type_renderers: typeRenderers,
      deepcopy_on_parse: false, // This needs to be off otherwise the tasks names in the resource grid don't render
    }),
    [scales, startDate, endDate, layout]
  );
  return getConfig;
};
