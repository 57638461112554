import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TableCell, TableRow } from '@mui/material';
import { CSSProperties } from 'styled-components';

const overlayStyles = {
  boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
  display: 'flex',
  width: '100%',
  backgroundColor: 'white',
  cursor: 'grabbing',
  scale: 1,
};

export const ProductLineTableRow = ({ product, columns, id, isOverlay = false }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  let rowStyles: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,

    position: 'relative',
    touchAction: 'none',
  };

  if (isOverlay) {
    rowStyles = { ...rowStyles, ...overlayStyles };
  }

  return (
    <TableRow hover component={isOverlay ? 'div' : 'tr'} ref={setNodeRef} style={{ ...rowStyles }}>
      {columns.map(([key, column], index) => (
        <TableCell
          align={column.align || 'left'}
          component={isOverlay ? 'div' : 'td'}
          key={key}
          sx={{
            ...column.sx,
          }}
        >
          {column.render(product, {
            dragProps: { attributes, listeners, isDragging: isOverlay },
          })}
        </TableCell>
      ))}
    </TableRow>
  );
};
