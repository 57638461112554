import { createEntityAdapter } from '@reduxjs/toolkit';
import { api, providesList } from 'api';

const roleAdapter = createEntityAdapter();
const initialState = roleAdapter.getInitialState();

export const ROLE_TAG = 'Role';

export const roleAPI = api.enhanceEndpoints({ addTagTypes: [ROLE_TAG] }).injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.query({
      query: () => `/roles`,
      providesTags: (data) => (data ? providesList(data.ids, ROLE_TAG) : []),
      transformResponse: (response) => roleAdapter.setAll(initialState, response.data),
    }),
    createRole: build.mutation({
      query: ({ role }) => ({
        url: `/role`,
        method: 'POST',
        body: role,
      }),
      invalidatesTags: (result, error, args) => (!error ? [{ type: ROLE_TAG, id: 'LIST' }] : []),
    }),
    updateRole: build.mutation({
      query: (role) => ({
        url: `/role/${role.id}`,
        method: 'PATCH',
        body: role,
      }),
      invalidatesTags: (result, error, args) => (!error ? [{ type: ROLE_TAG, id: args.id }] : []),
    }),

    deleteRole: build.mutation({
      query: (id) => ({
        url: `/role/id`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, args) => (!error ? [{ type: ROLE_TAG, id: 'LIST' }] : []),
    }),
  }),
});
export const {
  useGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = roleAPI;
