import { BellCurve } from '@blackhyve/common/src/icons/BellCurve';
import { FrontHeavyCurve } from '@blackhyve/common/src/icons/FrontHeavyCurve';
import { BackHeavyCurve } from '@blackhyve/common/src/icons/BackHeavyCurve';
import { EvenCurve } from '@blackhyve/common/src/icons/EvenCurve';
import { FlatDistribution } from '@blackhyve/common/src/icons/FlatDistribution';
import { NormalDistribution } from '@blackhyve/common/src/icons/NormalDistribution';
import { SteepDistribution } from '@blackhyve/common/src/icons/SteepDistribution';
import { ReactElement } from 'react';
type curve = {
  id: string;
  name: string;
  icon?: ReactElement;
};
export enum CURVE_TYPE {
  BELL = 'bell',
  FRONT_HEAVY = 'front_heavy',
  BACK_HEAVY = 'back_heavy',
  EVEN = 'even',
}

export enum CURVE_DISTRIBUTION {
  FLAT = 'flat',
  NORMAL = 'normal',
  STEEP = 'steep',
}

export const CURVE_OPTIONS: curve[] = [
  { id: CURVE_TYPE.BELL, name: 'Bell', icon: <BellCurve /> },
  { id: CURVE_TYPE.FRONT_HEAVY, name: 'Front Heavy', icon: <FrontHeavyCurve /> },
  { id: CURVE_TYPE.BACK_HEAVY, name: 'Back Heavy', icon: <BackHeavyCurve /> },
  { id: CURVE_TYPE.EVEN, name: 'Even', icon: <EvenCurve /> },
];

export const DISTRIBUTION_OPTIONS: curve[] = [
  { id: CURVE_DISTRIBUTION.FLAT, name: 'Flat', icon: <FlatDistribution /> },
  { id: CURVE_DISTRIBUTION.NORMAL, name: 'Normal', icon: <NormalDistribution /> },
  { id: CURVE_DISTRIBUTION.STEEP, name: 'Steep', icon: <SteepDistribution /> },
] as const;

// Helper functions for reverse formatting
export const getCurve = (type: CURVE_TYPE): curve | undefined => {
  return CURVE_OPTIONS.find((option) => option.id === type);
};

export const getDistribution = (type: CURVE_DISTRIBUTION): curve | undefined => {
  return DISTRIBUTION_OPTIONS.find((option) => option.id === type);
};
