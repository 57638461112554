import { TAGS_CACHE_KEY } from 'features/tags/store/tag.api';

const { api, providesList } = require('api');

const recipeTaskAPI = api.enhanceEndpoints({ addTagTypes: ['RecipeTask'] }).injectEndpoints({
  endpoints: (build) => ({
    getRecipeTasks: build.query({
      query: ({ recipeId }) => `/recipes/${recipeId}/tasks`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, args) => (result ? providesList(result, 'RecipeTask') : []),
    }),

    syncTagsOnRecipeTask: build.mutation({
      query: ({ taskId, tags }) => ({
        url: `/recipe_tasks/${taskId}/tags`,
        method: 'POST',
        body: { tags },
      }),
      invalidatesTags: (result, error, args) => {
        return [{ type: TAGS_CACHE_KEY, id: 'LIST' }];
      },
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useGetRecipeTasksQuery, useSyncTagsOnRecipeTaskMutation } = recipeTaskAPI;
