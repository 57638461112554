import { theme } from '@blackhyve/common/src/AppTheme';
import SvgBlackhyveIcon from '@blackhyve/common/src/icons/BlackhyveIcon';
import ProductIcon from '@blackhyve/common/src/icons/Product';
import { CompanyIcon } from '@blackhyve/common/src/icons/CompanyIcon';
import RecipeIconComponent from '@blackhyve/common/src/icons/Recipe';
import SvgTaskIcon from '@blackhyve/common/src/icons/Task';
import WorkspaceIconComponent from '@blackhyve/common/src/icons/Workspace';
import { Assignment, Engineering, MenuOpen, Notifications } from '@mui/icons-material';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TimelineIcon from '@mui/icons-material/Timeline';
import Analytics from '@mui/icons-material/Analytics';
import {
  Box,
  Fab,
  List,
  Paper,
  SvgIcon,
  ThemeProvider,
  Tooltip,
  createTheme,
  lighten,
  styled,
} from '@mui/material';
import { RequireRole } from 'features/auth/components/RequireRole';
import { selectCurrentUserWorkspaceId } from 'features/auth/store/authSlice';
import { NotificationDrawer } from 'features/notifications/components/NotificationDrawer';
import { useGetProjectsQuery } from 'features/projects/store/project.api';
import { UserMenu } from 'features/users/components/UserMenu';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CompanyDrawer } from './drawers/CompanyDrawer';
import { ProjectDrawer } from './drawers/ProjectDrawer';
import { RecipeDrawer } from './drawers/RecipeDrawer';
import { NavListItem } from './NavListItem';
import LabelIcon from '@mui/icons-material/Label';
import { Label as LabelComponent } from 'features/labels/components/Label';
import FeatureFlag, { features } from '@blackhyve/common/src/components/FeatureFlag';

export const navTheme = createTheme({
  ...theme,
  palette: {
    mode: 'dark',
    primary: {
      main: '#D1A8FF',
    },
    secondary: {
      main: theme.palette.secondary.main,
    },
    background: {
      paper: '#4c4c4c',
    },
  },
});

const StyledSlide = styled(Box)({ transition: 'width .3s ease' });

const drawerWith = '400px';

export const Nav = () => {
  const [drawerComponent, setDrawerComponent] = useState(undefined);
  const [drawerName, setDrawerName] = useState(undefined);
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { data: projects = [] } = useGetProjectsQuery({ workspaceId });

  const hasBudgets = projects.some(({ budgets_feature }) => budgets_feature);

  const handleToggleDrawer = (newDrawerName, newDrawerComponent) => {
    if (drawerName !== newDrawerName) {
      setDrawerComponent(newDrawerComponent);
      setDrawerName(newDrawerName);
    } else {
      setDrawerName(undefined);
    }
  };

  const handleOpenHelp = () => {
    window.Beacon('config', {
      display: {
        position: 'left',
        horizontalOffset: 60,
      },
      enableFabAnimation: false,
    });
    window.Beacon('toggle');
    window.Beacon('navigate', '/answers/');
  };

  return (
    <ThemeProvider theme={navTheme}>
      <Box
        alignItems={'center'}
        bgcolor={'#2C2C2C'}
        display={'flex'}
        displayPrint={'none'}
        flexDirection={'column'}
        flexShrink={0}
        overflow={'auto'}
      >
        <SvgIcon fontSize={'large'} sx={{ py: 2 }}>
          <SvgBlackhyveIcon />
        </SvgIcon>
        <Box disablePadding component={List} width={1}>
          <NavListItem
            icon={<WorkspaceIconComponent color={'currentColor'} />}
            selected={drawerName === 'project'}
            title={'Projects'}
            onClick={() => handleToggleDrawer('project', <ProjectDrawer />)}
          />
          <NavListItem
            icon={<SvgTaskIcon color={'currentColor'} />}
            title={'Task Portfolio'}
            to={{ pathname: `/workspace/${workspaceId}/tasks/gantt` }}
          />
          <NavListItem
            icon={<Notifications />}
            selected={drawerName === 'notifications'}
            title={'Notifications'}
            onClick={() => handleToggleDrawer('notifications', <NotificationDrawer />)}
          />
          <NavListItem
            icon={<PendingActionsOutlinedIcon color={'currentColor'} />}
            title={<LabelComponent labelKey="stuck_point" plural={true} />}
            to={{ pathname: `/workspace/${workspaceId}/stuck-points` }}
          />
          <NavListItem
            icon={<LabelIcon color={'currentColor'} />}
            title={'Tags'}
            to={{ pathname: `/workspace/${workspaceId}/tags` }}
          />
          <RequireRole exclude roles={['contractor_contact']}>
            <NavListItem
              icon={<TimelineIcon />}
              title={'Activity Feed'}
              to={{ pathname: `/workspace/${workspaceId}/activity-feeds` }}
            />
          </RequireRole>
          <FeatureFlag name={features.ANALYTICS}>
            <RequireRole exclude roles={['contractor_contact']}>
              <NavListItem
                icon={<Analytics />}
                title={'Analytics'}
                to={{ pathname: `/workspace/${workspaceId}/analytics` }}
              />
            </RequireRole>
          </FeatureFlag>
          <RequireRole exclude roles={['contractor_contact']}>
            <NavListItem
              icon={<Assignment color={'currentColor'} />}
              title={'Operations'}
              to={{ pathname: `/workspace/${workspaceId}/operations` }}
            />
          </RequireRole>
        </Box>
        <Box disablePadding component={List} mt={'auto'} width={1}>
          <RequireRole exclude roles={['contractor_contact']}>
            <NavListItem
              icon={<RecipeIconComponent color="currentColor" />}
              selected={drawerName === 'recipe'}
              title={'Recipes'}
              onClick={() => handleToggleDrawer('recipe', <RecipeDrawer />)}
            />
          </RequireRole>
          <RequireRole exclude roles={['contractor_contact']}>
            <NavListItem
              icon={<CompanyIcon />}
              selected={drawerName === 'company'}
              title={'Companies'}
              onClick={() => handleToggleDrawer('company', <CompanyDrawer />)}
            />
          </RequireRole>

          <NavListItem
            icon={<Engineering color={'currentColor'} />}
            title={'Resources'}
            to={{ pathname: `/workspace/${workspaceId}/resources` }}
          />

          {hasBudgets && (
            <RequireRole exclude roles={['contractor_contact']}>
              <NavListItem
                icon={<ProductIcon color="currentColor" />}
                title={'Products'}
                to={{ pathname: `/workspace/${workspaceId}/products` }}
              />
            </RequireRole>
          )}
          {/* <RequireRole roles={['admin', 'super_admin', 'member']}>
            <NavListItem
              icon={<AddMemberIconComponent color="currentColor" />}
              to={{ pathname: `/workspace/${workspaceId}/users` }}
            />
          </RequireRole> */}
          <NavListItem icon={<QuestionMarkIcon />} title={'Help'} onClick={handleOpenHelp} />
          <UserMenu />
        </Box>
      </Box>
      <Box display={'flex'} position={'relative'}>
        {drawerName && (
          <Tooltip arrow title={'Collapse'}>
            <Fab
              color={'primary'}
              size="small"
              sx={{
                position: 'absolute',
                right: '0',
                top: '14px',
                translate: '50% 0%',
                width: '36px',
                height: '36px',
                fontSize: '18px',
                bgcolor: (theme) => lighten(theme.palette.background.paper, 0.4),
                color: (theme) => theme.palette.text.primary,
              }}
              onClick={() => setDrawerName(undefined)}
            >
              <MenuOpen />
            </Fab>
          </Tooltip>
        )}
        <StyledSlide
          flexShrink={0}
          overflow={'hidden'}
          style={{
            width: drawerComponent !== undefined && drawerName !== undefined ? drawerWith : '0px',
          }}
        >
          <Box
            square
            component={Paper}
            display={'flex'}
            flexDirection={'column'}
            height={'100%'}
            maxWidth={'80vw'}
            position={'relative'}
            width={drawerWith}
            zIndex={1}
          >
            {drawerComponent}
          </Box>
        </StyledSlide>
      </Box>
    </ThemeProvider>
  );
};
