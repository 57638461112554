import { parseDate } from '@blackhyve/utilities/dates';
import { useMemo } from 'react';

const emptyObject = {};

export function useSanitizedObject(inputObject = emptyObject) {
  const sanitizedObject = useMemo(() => {
    return Object.keys(inputObject).reduce((sanitized, key) => {
      const value = inputObject[key];

      if (value === undefined || value === null) {
        sanitized[key] = '';
        return sanitized;
      }

      if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
        const date = parseDate(value);
        sanitized[key] = isNaN(date?.getTime()) ? value : date;
      } else {
        sanitized[key] = value;
      }

      return sanitized;
    }, {});
  }, [inputObject]);

  return sanitizedObject;
}

export default useSanitizedObject;
