import { StyledDialog } from '@blackhyve/common';
import { Alert, Button } from '@mui/material';
import {
  useCreateEstimateMutation,
  useUpdateEstimateMutation,
} from 'features/estimates/api/estimate.api';
import { Estimate, NewEstimate } from 'features/estimates/types/estimate.models';
import { useForm } from 'react-hook-form';
import { EstimateForm } from './EstimateForm';

type EstimateFormDialogProps = {
  projectId: number;
  open: boolean;
  handleClose;
  estimate?: Estimate;
};

const defaultValues = {
  name: '',
  is_primary: false,
};

export function EstimateFormDialog({
  open,
  handleClose,
  projectId,
  estimate,
}: EstimateFormDialogProps) {
  const [createEstimate, { isLoading: isCreatingEstimate, isError: isErrorCreatingEstimate }] =
    useCreateEstimateMutation();
  const [updateEstimate, { isLoading: isUpdatingEstimate, isError: isErrorUpdatingEstimate }] =
    useUpdateEstimateMutation();

  const isUpdate = estimate !== undefined;
  const isUpdating = isCreatingEstimate || isUpdatingEstimate;
  const isError = isErrorCreatingEstimate || isErrorUpdatingEstimate;

  const form = useForm<NewEstimate | Estimate>({
    values: estimate,
    defaultValues: { ...defaultValues },
    disabled: isUpdating,
  });

  const close = () => {
    form.reset({ ...defaultValues });
    handleClose();
  };

  const handleSubmit = async (data) => {
    const payload = { project_id: projectId, ...data };
    try {
      if (isUpdate) {
        await updateEstimate(payload).unwrap();
      } else {
        await createEstimate(payload).unwrap();
      }
      close();
    } catch (error) {
      console.log(error);
    }
  };

  const submit = form.handleSubmit(handleSubmit);

  return (
    <StyledDialog
      handleClose={close}
      open={open}
      title={estimate ? 'Update Estimate' : 'Create Estimate'}
      actions={
        <>
          <Button color={'primary'} onClick={close}>
            Close
          </Button>
          <Button color={'primary'} variant={'contained'} onClick={submit}>
            Save
          </Button>
        </>
      }
    >
      {isError && (
        <Alert severity={'error'}>
          An error occurred while {isUpdate ? 'updating' : 'creating'}. Please try again
        </Alert>
      )}
      <EstimateForm form={form} handleSubmit={submit} />
    </StyledDialog>
  );
}
