import React from 'react';
import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Popover,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { CalendarToday as CalendarIcon, Clear as ClearIcon } from '@mui/icons-material';

const DateRangeSelector = ({ startDate, endDate, onDateRangeChange, minDate, maxDate }) => {
  const [startAnchorEl, setStartAnchorEl] = React.useState(null);
  const [endAnchorEl, setEndAnchorEl] = React.useState(null);

  const formatDate = (date) => {
    if (!date) return 'Select date';
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const handleDateChange = (newDate, isStart) => {
    const date = new Date(newDate);

    if (isStart) {
      date.setHours(0, 0, 0, 0);
      onDateRangeChange(date, endDate);
      setStartAnchorEl(null);
    } else {
      date.setHours(23, 59, 59, 999);
      onDateRangeChange(startDate, date);
      setEndAnchorEl(null);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          size="small"
          sx={{ width: 140 }}
          value={formatDate(startDate)}
          InputProps={{
            startAdornment: (
              <CalendarIcon sx={{ mr: 1, fontSize: '1.25rem', color: 'action.active' }} />
            ),
            readOnly: true,
            sx: { cursor: 'pointer', fontSize: '0.875rem' },
          }}
          onClick={(e) => setStartAnchorEl(e.currentTarget)}
        />

        <Typography color="text.secondary" variant="body2">
          to
        </Typography>

        <TextField
          size="small"
          sx={{ width: 140 }}
          value={formatDate(endDate)}
          InputProps={{
            startAdornment: (
              <CalendarIcon sx={{ mr: 1, fontSize: '1.25rem', color: 'action.active' }} />
            ),
            readOnly: true,
            sx: { cursor: 'pointer', fontSize: '0.875rem' },
          }}
          onClick={(e) => setEndAnchorEl(e.currentTarget)}
        />
      </Box>

      {(startDate || endDate) && (
        <Tooltip title="Clear dates">
          <Button size='small' variant='outlined' onClick={() => onDateRangeChange(null, null)}>Clear Date</Button>
          {/* <IconButton
            size="small"
            sx={{
              ml: 0.5,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
            onClick={() => onDateRangeChange(null, null)}
          >
            <ClearIcon sx={{ fontSize: '1.25rem', color: 'action.active' }} />
          </IconButton> */}
        </Tooltip>
      )}

      <Popover
        anchorEl={startAnchorEl}
        open={Boolean(startAnchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setStartAnchorEl(null)}
      >
        <DateCalendar
          maxDate={endDate || maxDate}
          minDate={minDate}
          value={startDate}
          onChange={(date) => handleDateChange(date, true)}
        />
      </Popover>

      <Popover
        anchorEl={endAnchorEl}
        open={Boolean(endAnchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setEndAnchorEl(null)}
      >
        <DateCalendar
          maxDate={maxDate}
          minDate={startDate || minDate}
          value={endDate}
          onChange={(date) => handleDateChange(date, false)}
        />
      </Popover>
    </Box>
  );
};

export default DateRangeSelector;
