import { isValid } from 'date-fns';
import { Controller, useForm, UseFormProps } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { StyledDialog } from '@blackhyve/common';
import useSanitizedObject from 'hooks/useSantizedObject';
import { ResourceRequest, ResourceRequestStatus } from '../api/resources.models';
import { TradesAutocomplete } from 'features/trades/components/TradesAutocomplete';
import { RolesAutocomplete } from 'features/roles/components/RolesAutocomplete';

const defaultValues: Omit<ResourceRequest, 'id'> = {
  role_id: undefined,
  trade_id: undefined,
  resource_count: 0,
  status: ResourceRequestStatus.Open,
  notes: '',
};

export function ResourceRequestDialog({
  open,
  handleClose,
  onSubmit,
  isLoading,
  resourceRequest = undefined,
}: {
  open: boolean;
  isLoading: boolean;
  handleClose: any;
  onSubmit: any;
  resourceRequest?: ResourceRequest;
}) {
  const isUpdate = Boolean(resourceRequest);
  const resourceRequestFormObject = useSanitizedObject(resourceRequest);
  const formConfig: UseFormProps = {
    defaultValues,
    ...(resourceRequest && typeof resourceRequest === 'object'
      ? { values: resourceRequestFormObject }
      : {}),
  };

  const form = useForm(formConfig);
  const { handleSubmit, control, watch } = form;
  const watchStartDate = watch('start_date');

  return (
    <StyledDialog
      handleClose={handleClose}
      open={open}
      title={isUpdate ? 'Update Resource Request' : 'Create Resource Request'}
      actions={
        <>
          <Button onClick={handleClose}>Close</Button>
          <Button disabled={isLoading} variant="contained" onClick={form.handleSubmit(onSubmit)}>
            {isUpdate ? 'Update' : 'Create'}
            {isLoading && <CircularProgress color="inherit" size={'1.5em'} sx={{ ml: 1 }} />}
          </Button>
        </>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="trade_id">Trade*</InputLabel>
            <Controller
              control={control}
              name="trade_id"
              render={({ field, fieldState: { error } }) => (
                <TradesAutocomplete
                  {...field}
                  multiple={false}
                  TextFieldProps={{
                    error: !!error,
                    helperText: error?.message,
                  }}
                  onChange={(_event, newValue) => {
                    field.onChange(newValue ?? null);
                  }}
                />
              )}
              rules={{
                required: 'Trade is required',
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="role_id">Role</InputLabel>
            <Controller
              control={control}
              name="role_id"
              render={({ field, fieldState: { error } }) => (
                <RolesAutocomplete
                  {...field}
                  multiple={false}
                  onChange={(_event, newValue) => {
                    field.onChange(newValue ?? null);
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="start_date">Start Date*</InputLabel>
            <Controller
              control={control}
              name="start_date"
              render={({ field: { ref, ...field }, fieldState }) => (
                <DatePicker
                  {...field}
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                      error: fieldState?.error ? true : false,
                      helperText: fieldState?.error?.message,
                    },
                  }}
                />
              )}
              rules={{
                required: 'Start date is required',
                validate: (value) => {
                  return isValid(value) || 'Invalid Date';
                },
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <InputLabel htmlFor="end_date">End Date*</InputLabel>
            <Controller
              control={control}
              name="end_date"
              render={({ field: { ref, ...field }, fieldState }) => (
                <DatePicker
                  {...field}
                  minDate={watchStartDate}
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                      error: fieldState?.error ? true : false,
                      helperText: fieldState?.error?.message,
                    },
                  }}
                />
              )}
              rules={{
                required: 'End date is required',
                validate: (value) => {
                  return isValid(value) || 'Invalid Date';
                },
              }}
            />
          </Grid>

          <Grid item sm={8} xs={12}>
            <InputLabel htmlFor="resource_count">Resource Count*</InputLabel>
            <Controller
              control={control}
              name="resource_count"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{ min: 1 }}
                  placeholder="Resource Count"
                  size="small"
                  type="number"
                  variant="outlined"
                />
              )}
              rules={{
                required: 'Resource count is required',
                min: {
                  value: 1,
                  message: 'Resource count must be greater than 0',
                },
                validate: (value) => {
                  return Number(value) > 0 || 'Resource count must be greater than 0';
                },
              }}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <InputLabel htmlFor="status">Status</InputLabel>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <Select {...field} fullWidth size="small" variant="outlined">
                  <MenuItem value={ResourceRequestStatus.Open}>Open</MenuItem>
                  <MenuItem value={ResourceRequestStatus.Closed}>Closed</MenuItem>
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel htmlFor="notes">Notes</InputLabel>
            <Controller
              control={control}
              name={'notes'}
              render={({ field, fieldState: { error, invalid } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  error={invalid}
                  helperText={error?.message}
                  maxRows={7}
                  minRows={3}
                  placeholder={'Enter notes'}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </StyledDialog>
  );
}
