import { TextField } from '@mui/material';
import { forwardRef } from 'react';

export const formatDecimals = (value) => {
  return parseFloat(value)
    .toFixed(2)
    .replace(/\.00$/, '')
    .replace(/\.0$/, '')
    .replace(/(\.\d)0$/, '$1');
};

export const formatMoney = (value, asCents = true) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(value / (asCents ? 100 : 1));
};

export const formatToCents = (value) => {
  return value / 100;
};

export const unformatMoney = (value, asCents = false) => {
  return parseFloat(`${value}`.replaceAll(',', '') || 0) * (asCents ? 100 : 1);
};

export const formatPercent = (value, options = {}) => {
  options = { min: 0, max: Infinity, ...options };

  const min = parseInt(options.min);
  const max = parseInt(options.max);
  value = parseFloat(`${value}`.replace(/[^-\d.]/, '') || 0);

  if (isNaN(value) || value < min) {
    return min.toFixed(0);
  }

  if (value > max) {
    return max.toFixed(0);
  }

  return value;
};

export const PercentInput = forwardRef(({ error, disabled = false, ...field }, ref) => {
  return (
    <TextField
      {...field}
      fullWidth
      disabled={disabled}
      error={!!error}
      helperText={error?.message}
      inputProps={{ min: 0, max: 100, step: 1, precision: 2 }}
      ref={ref}
      size="small"
      type="number"
      onChange={(event) => {
        event.target.value = formatPercent(event.target.value, {
          min: event.target.getAttribute('min'),
          max: event.target.getAttribute('max'),
        });

        field.onChange(event);
      }}
    />
  );
});

export const MoneyInput = forwardRef(({ error, disabled, setValue, defaultMax, ...field }, ref) => {
  const max = defaultMax || 9_000_000_000;
  return (
    <TextField
      {...field}
      fullWidth
      disabled={disabled}
      error={error}
      helperText={error?.message}
      inputProps={{ min: 0, max, step: 1, precision: 2 }}
      ref={ref}
      size="small"
      onBlur={(e) => {
        e.target.type = 'text';
        setValue(e.target.name, formatMoney(unformatMoney(e.target.value), false));
      }}
      onChange={(e) => {
        e.target.value = formatPercent(e.target.value, { max });
        field.onChange(e);
      }}
      onFocus={(e) => {
        let unformatted = unformatMoney(e.target.value);
        setValue(e.target.name, unformatted > 0 ? unformatted : '');
        e.target.type = 'number';
      }}
    />
  );
});
