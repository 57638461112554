import { Autocomplete, Checkbox, TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { AutocompleteProps } from '@mui/material/Autocomplete';
import { useGetRolesQuery } from '../api/role.api';

interface RolesAutocompleteProps
  extends Omit<
    AutocompleteProps<string, boolean, boolean, boolean>,
    'renderInput' | 'options' | 'getOptionLabel'
  > {
  TextFieldProps?: Partial<TextFieldProps>;
  multiple?: boolean;
}

export const RolesAutocomplete = forwardRef<HTMLDivElement, RolesAutocompleteProps>(
  ({ TextFieldProps = undefined, multiple = false, ...props }, ref) => {
    const { data: { entities: roleEntities = {}, ids: roleIds = [] } = {}, isLoading } =
      useGetRolesQuery({});

    return (
      <Autocomplete
        autoHighlight
        disableCloseOnSelect
        getOptionLabel={(option: string) => roleEntities?.[option]?.name || 'Unknown'}
        limitTags={multiple ? 3 : -1}
        loading={isLoading}
        multiple={multiple}
        options={roleIds}
        ref={ref}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            id="role-input"
            placeholder="Role"
            variant="outlined"
            {...TextFieldProps}
          />
        )}
        renderOption={(props, option: string, { selected }) => (
          <li {...props}>
            {multiple && <Checkbox checked={selected} edge="start" />}
            {roleEntities[option]?.name}
          </li>
        )}
        {...props}
      />
    );
  }
);
