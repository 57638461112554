import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Paper, Stack } from '@mui/material';
import { FormatIndentDecrease, FormatIndentIncrease, Cancel } from '@mui/icons-material';
import { useGetImportQuery } from '../../api/imports.api';
import { useImportTasksMutation } from 'features/tasks/store/task.api';
import { PropertyMappedTask, useScheduleEntries } from '../../hooks/useScheduleEntries';
import { WBSAnomalies } from './WBSAnomalies';
import { WBSTaskIndentor } from './WBSTaskIndentor';
import { detectNestingAnomalies } from 'features/imports/utils/inferWbsFromBoundingBoxes';
import _ from 'lodash';
import { format } from 'date-fns';
import { useRunAutoSchedulerJobMutation } from 'features/projects/store/project.api';
import { formatGanttTaskToTask, formatTaskToGanttTask } from '@blackhyve/utilities/gantt';
interface ImportableTask {
  actual_end: null;
  actual_start: null;
  autoschedule_date: 'schedule' | 'forecasted';
  constraint_date: null;
  constraint_type: 'asap';
  dates_locked_by: null;
  demand: null;
  demand_calculation: null;
  duration_days: null;
  duration_string: null;
  forecasted_end: string | null;
  forecasted_start: string | null;
  id: string;
  imported_parent_id: string;
  is_lookahead: false;
  is_parent: boolean;
  name: string;
  parent_id: string;
  percent_complete: 0;
  scheduled_end: string | null;
  scheduled_start: string | null;
  status: 'todo';
  trades: [];
}

function mapToImportableTask(
  task: PropertyMappedTask & { parent_id: number; is_parent: boolean }
): ImportableTask {
  const name = task.name;
  const id = task.id.toString();
  const is_parent = task.is_parent;
  const imported_parent_id = task.parent_id?.toString();
  const parent_id = task.parent_id?.toString();
  const scheduled_start = task.scheduled_start ? format(task.scheduled_start, 'yyyy-MM-dd') : null;
  const scheduled_end = task.scheduled_end ? format(task.scheduled_end, 'yyyy-MM-dd') : null;
  const forecasted_start = scheduled_start;
  const forecasted_end = scheduled_end;

  return {
    actual_end: null,
    actual_start: null,
    autoschedule_date: 'schedule',
    constraint_date: null,
    constraint_type: 'asap',
    dates_locked_by: null,
    demand: null,
    demand_calculation: null,
    duration_days: null,
    duration_string: null,
    forecasted_end,
    forecasted_start,
    id,
    imported_parent_id,
    is_lookahead: false,
    is_parent,
    name,
    parent_id,
    percent_complete: 0,
    scheduled_end,
    scheduled_start,
    status: 'todo',
    trades: [],
  };
}

export function ConfirmScheduleWBS() {
  const { projectId, importId } = useParams();
  const { data: importData, isLoading } = useGetImportQuery(
    { backendImportId: importId },
    {
      skip: !importId,
      refetchOnMountOrArgChange: true
    }
  );
  
  const [scheduleTable] = useScheduleEntries(importData);
  const [tasks, setTasks] = useState<PropertyMappedTask[]>([]);
  const [selection, setSelection] = useState<number[]>([]);
  const [importing, setImporting] = useState(false);
  const [importTasks] = useImportTasksMutation();
  const [runAutoSchedulerJob] = useRunAutoSchedulerJobMutation();
  const [selectedAnomalyTaskId, setSelectedAnomalyTaskId] = useState<number | undefined>();

  const handleAnomalyClick = (taskId: number) => {
    setSelectedAnomalyTaskId(taskId);
    setSelection([]);
  };

  useMemo(() => {
    if (importData?.schedule_entries) {
      setTasks(scheduleTable as any);
    }
  }, [importData]);

  const handleChangeIndentLevel = (delta: number) => {
    const newIndentTasks = tasks.map((t) => {
      if (selection.includes(t.id)) {
        return {
          ...t,
          indent_level: Math.max(t.indent_level + delta, 0),
        };
      }
      return t;
    });
    setTasks(newIndentTasks);
  };

  const handleToggleSelection = (taskId: number, event: React.MouseEvent) => {
    setSelection((selection) => {
      if (event.nativeEvent.shiftKey && selection.length > 0) {
        const lastSelectedIndex = tasks.findIndex((t) => t.id === selection[selection.length - 1]);
        const currentIndex = tasks.findIndex((t) => t.id === taskId);

        const start = Math.min(lastSelectedIndex, currentIndex);
        const end = Math.max(lastSelectedIndex, currentIndex);
        const rangeIds = tasks.slice(start, end + 1).map((t) => t.id);

        const newSelection = new Set([...selection, ...rangeIds]);
        return Array.from(newSelection);
      } else {
        if (selection.includes(taskId)) {
          return selection.filter((s) => s !== taskId);
        } else {
          return selection.concat(taskId);
        }
      }
    });
  };

  const handleClearSelection = () => {
    setSelection([]);
  };

  const anomalies = useMemo(
    () => detectNestingAnomalies(tasks).filter((t) => t.anomaly_type),
    [tasks]
  );

  const handleImportTasks = async () => {
    setImporting(true);
    let parentStack: number[] = [];
    for (let i = 0; i < tasks.length; i++) {
      while (
        parentStack.length > 0 &&
        tasks[parentStack[parentStack.length - 1]].indent_level >= tasks[i].indent_level
      ) {
        parentStack.pop();
      }

      if (parentStack.length > 0) {
        const parentIndex = parentStack[parentStack.length - 1];
        (tasks[parentIndex] as any).is_parent = true;
        (tasks[i] as any).parent_id = tasks[parentIndex].id;
      }

      parentStack.push(i);
    }
    const importableTasks = (tasks as any[]).map((t) => mapToImportableTask(t));

    try {
      await importTasks({ projectId, tasks: importableTasks, dependencies: [] });
      // await runAutoSchedulerJob(projectId);
    } catch (err) {
      console.error(err);
    } finally {
      setImporting(false);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', gap: 2, p: 2 }}>
      <WBSAnomalies anomalies={anomalies} onAnomalyClick={handleAnomalyClick} />
      <Paper sx={{ flex: 2, p: 2 }}>
        <Stack spacing={2}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              startIcon={<FormatIndentDecrease />}
              onClick={() => handleChangeIndentLevel(-1)}
            >
              Outdent
            </Button>
            <Button startIcon={<FormatIndentIncrease />} onClick={() => handleChangeIndentLevel(1)}>
              Indent
            </Button>
            <Button startIcon={<Cancel />} onClick={() => handleClearSelection()}>
              Clear Selection
            </Button>
            <Box sx={{ flex: 1 }}></Box>
            <Button
              disabled={anomalies.length > 0 || importing}
              variant="contained"
              onClick={handleImportTasks}
            >
              Import
            </Button>
          </Box>
          <WBSTaskIndentor
            scrollToTaskId={selectedAnomalyTaskId}
            selection={selection}
            tasks={tasks}
            onToggleSelection={handleToggleSelection}
          />
        </Stack>
      </Paper>
    </Box>
  );
}
