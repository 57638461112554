import { transformToTable } from '../utils/transformToTable';
import _ from 'lodash';
export function useLaborEstimateItems<T>(
  importData,
  selectedLaborEstimateArea
): { mapped: T[]; filtered: T[] } {
  if (_.isNil(selectedLaborEstimateArea)) return { mapped: [], filtered: [] };

  const laborEstimateColumns = importData?.labor_estimate_columns || [];
  const laborEstimateEntries = transformToTable<{
    value: string;
    row_index: number;
    column_index: number;
  }>(importData?.labor_estimate_entries || []);
  const mapToByIndex = laborEstimateColumns.reduce((acc, col) => {
    if (col.map_to) {
      acc[col.column_index] = col.map_to;
    }
    return acc;
  }, {});

  const mapped = laborEstimateEntries.map((row, index) => {
    return row.reduce((obj, entry) => {
      const mapTo = mapToByIndex[entry.column_index];
      if (mapTo) {
        if (mapTo === 'task:demand') {
          obj[mapTo] = parseFloat(entry.value);
        } else {
          obj[mapTo] = entry.value;
        }
      }
      return {
        id: 1 + index,
        ...obj,
      };
    }, {} as T);
  });

  const filtered = mapped.filter((e) => e['tag:area'] === selectedLaborEstimateArea) as T[]; // TODO: fix this typing

  return { mapped, filtered };
}
