import { Gantt, GanttStatic } from '@blackhyve/dhtmlx-gantt';
import '@blackhyve/dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import {
  isValidElement,
  ReactElement,
  ReactPortal,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { defaultConfig } from '../config/defaultConfig';
import { defaultPlugins } from '../config/defaultPlugins';
import { defaultTemplates } from '../config/defaultTemplates';
import { GanttHookOptions, GanttHookReturn } from '../types/useGantt.types';
import { getDefaultZoomConfig } from '../utils/getDefaultZoomConfig';
import { GanttCollections, useGanttCollections } from './useGanttCollections';
import { useGanttColorBy } from './useGanttColorBy';
import { useGanttDisplayOption } from './useGanttDisplayOption';
import { useGanttGroupBy } from './useGanttGroupBy';
import { useGanttLayers } from './useGanttLayers';
import { useGanttLoadTaskAndLinks } from './useGanttLoadTasksAndLinks';
import { useGanttMakers } from './useGanttMakers';
import { useGanttWorkdays } from './useGanttWorkdays';

const defaultWorkdays = [0, 1, 1, 1, 1, 1, 0];
const emptyArray: never[] = [];
const emptyObject = {};

export function useGantt({
  tasks = emptyArray,
  links = emptyArray,
  resources = emptyArray,
  assignments = undefined,
  collections = emptyObject as GanttCollections,
  config = emptyObject,
  plugins = emptyObject,
  events = emptyObject,
  templates = emptyObject,
  inlineEditorConfig,
  zoomConfig = getDefaultZoomConfig,
  workdays = defaultWorkdays,
  holidays = emptyArray,
  markers = emptyArray,
  layers,
  groupBy,
  colorBy,
  onBeforeInit,
  onAfterInit,
  isLoading = false,
  isFetching = false,
  displayOptions = emptyArray,
}: GanttHookOptions): GanttHookReturn {
  const [portals, setPortals] = useState<{ [key: string]: ReactPortal }>({});
  const [gantt, setGantt] = useState<GanttStatic | undefined>(Gantt.getGanttInstance());

  const ref = useRef<HTMLDivElement>(null);

  const portalsList = useMemo(() => Object.values(portals), [portals]);

  useGanttLayers(gantt, layers);
  useGanttMakers(gantt, markers);
  useGanttGroupBy(gantt, groupBy);
  useGanttCollections(gantt, collections);
  useGanttWorkdays(gantt, workdays, holidays, { skip: isLoading });
  useGanttColorBy(gantt, colorBy);
  useGanttDisplayOption(gantt, displayOptions);

  useEffect(() => {
    if (gantt && !gantt.$destroyed && ref.current && !isLoading) {
      // Initialize Gantt
      ref.current.id = 'gantt';

      //Initialize plugins
      gantt.plugins({
        ...defaultPlugins,
        ...plugins,
      });

      // Initialize inline editor config
      //@ts-ignore
      inlineEditorConfig && gantt.ext.inlineEditors.setMapping(inlineEditorConfig(gantt));

      //Initialize external render
      gantt.config.external_render = {
        isElement: (element: any): boolean => {
          return isValidElement(element);
        },
        renderElement: (element: ReactElement, container: HTMLElement): void => {
          if (element.key) {
            container.innerHTML = '';
            setPortals((prevState) => ({
              ...prevState,
              //@ts-ignore
              [element.key]: createPortal(element, container, element.key),
            }));
          } else {
            console.warn('Element needs a key: ', element);
          }
        },
      };

      // Initialize config
      Object.assign(gantt.config, {
        ...defaultConfig,
        ...(typeof config === 'function' ? config(gantt) : config),
      });

      Object.assign(gantt.templates, {
        ...defaultTemplates,
        ...(typeof templates === 'function' ? templates(gantt) : templates),
      });

      // Initialize zoom
      //@ts-ignore
      zoomConfig &&
        //@ts-ignore
        gantt.ext.zoom.init(typeof zoomConfig === 'function' ? zoomConfig(gantt) : zoomConfig);

      // Initialize events
      if (Array.isArray(events)) {
        events.forEach(({ event, callback }, index) =>
          //@ts-ignore
          gantt.attachEvent(event, callback, { id: `${event}${index}` })
        );
      } else {
        Object.entries(events).forEach(([event, callback], index) =>
          //@ts-ignore
          gantt.attachEvent(event, callback, { id: `${event}${index}` })
        );
      }

      if (!gantt.$root) {
        if (onBeforeInit) onBeforeInit(gantt);
        gantt.init(ref.current);
        if (onAfterInit) onAfterInit(gantt);
      } else {
        gantt.render();
      }
    }
  }, [
    config,
    events,
    gantt,
    isLoading,
    onAfterInit,
    onBeforeInit,
    plugins,
    templates,
    zoomConfig,
    inlineEditorConfig,
  ]);

  useGanttLoadTaskAndLinks({
    gantt,
    tasks,
    links,
    resources,
    assignments,
    options: { skip: isLoading },
  });

  useEffect(() => {
    if (!gantt || gantt.$destroyed) {
      setGantt(Gantt.getGanttInstance());
    }
    // Clean up: Destroy the Gantt instance when unmounting
    return () => {
      if (!gantt?.$destroyed) {
        gantt?.destructor();
        setGantt(undefined);
      }
    };
  }, [gantt]);

  return { ref, gantt, portals: portalsList };
}
