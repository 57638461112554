import {
  AdditionalTaskLayer,
  GanttConfigOptions,
  GanttStatic,
  NewTask,
} from '@blackhyve/dhtmlx-gantt';
import { parseDate } from '@blackhyve/utilities/dates';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { ContentLayout } from 'components/layouts';
import { addMonths } from 'date-fns';
import { GanttPortal } from 'features/gantt/components/GanttPortal';
import { useGantt } from 'features/gantt/hooks/useGantt';
import { useGetProjectsQuery } from 'features/projects/store/project.api';
import { useGetResourceRequestQuery } from 'features/resources/api/resourceRequest.api';
import { useGetAllResourcesQuery } from 'features/resources/api/resources.api';
import { useGetRolesQuery } from 'features/roles/api/role.api';
import { useGetTradesQuery } from 'features/trades/api/trades.api';
import { useMemo } from 'react';
import { OperationModals } from '../components/OperationModals';
import { ResourceAssignmentHeader } from '../components/ResourceAssignmentHeader';
import { plugins } from '../config/plugins';
import { templates } from '../config/templates';
import { useOperationsBoardConfig } from '../hooks/useOperationBoardConfig';
import { useOperationBoardEvents, useAttachResourceEvents } from '../hooks/useOperationBoardEvents';
import { useOperationBoardGanttDataProcessor } from '../hooks/useOperationBoardGanttDataProcessor';
import { useResourceFilters } from '../hooks/useResourceFilters';
import {
  formatResource,
  formatResourceRequest,
  formatResourceRequestAssignment,
  undoFormatResourceRequestAssignment,
} from '../utils/formatter';
import { layers } from '../config/layers';

const openRequest: NewTask = {
  id: 'open-requests',
  text: 'Open Requests',
  type: 'request_status',
};
const closedRequest: NewTask = {
  id: 'closed-requests',
  text: 'Closed Requests',
  type: 'request_status',
};

const defaultDates = {
  startDate: addMonths(new Date(), -1),
  endDate: addMonths(new Date(), 1),
};

const resourceAssignmentFormatters = {
  format: formatResourceRequestAssignment,
  undoFormat: undoFormatResourceRequestAssignment,
};

const emptyArray = [];
const emptyObject = {};

export const OperationsBoard: React.FC = () => {
  const {
    filters: resourceFilters,
    handleNameChange: handleResourceNameFilterChange,
    handleTradeChange: handleResourceTradeFilterChange,
    shouldShow: shouldShowResource,
  } = useResourceFilters();

  const {
    data: { data: resourceRequestLists = emptyArray } = {},
    isLoading: isLoadingResourceRequest,
  } = useGetResourceRequestQuery({});

  const { data: resources = emptyArray, isLoading: isLoadingResources } =
    useGetAllResourcesQuery(undefined);

  const formattedResources = useMemo(
    () =>
      resources
        .filter((resource) => resource.type !== 'group')
        .map((resource) => formatResource(resource)),
    [resources]
  );

  const { data: tradeCollection = emptyObject, isLoading: isLoadingTrades } =
    useGetTradesQuery(undefined);
  const { data: roleCollection = emptyObject, isLoading: isLoadingRoles } =
    useGetRolesQuery(undefined);
  const { data: projects = emptyArray, isLoading: isLoadingProjects } =
    useGetProjectsQuery(undefined);

  const projectCollection = useMemo(() => {
    const ids: number[] = [];
    const entities: { [key: string]: any } = {};

    projects.forEach((project) => {
      ids.push(project.id);
      entities[project.id] = project;
    });

    return {
      ids,
      entities,
    };
  }, [projects]);

  const isLoading =
    isLoadingResourceRequest || isLoadingTrades || isLoadingRoles || isLoadingResources;

  const { initDataProcessor } = useOperationBoardGanttDataProcessor({
    formatters: resourceAssignmentFormatters,
  });

  const { startDate, endDate } = useMemo(() => {
    if (isLoading || !resourceRequestLists?.length) {
      return defaultDates;
    }

    return {
      startDate: addMonths(
        new Date(
          Math.min(...resourceRequestLists.map((req) => parseDate(req.start_date).getTime()))
        ),
        -1
      ),
      endDate: addMonths(
        new Date(Math.max(...resourceRequestLists.map((req) => parseDate(req.end_date).getTime()))),
        1
      ),
    };
  }, [resourceRequestLists, isLoading]);

  const formattedResourceRequest = useMemo(() => {
    if (!resourceRequestLists?.length || isLoading) {
      return [openRequest];
    }
    const formattedRequests = resourceRequestLists.flatMap(formatResourceRequest);

    return [openRequest, closedRequest, ...formattedRequests];
  }, [resourceRequestLists, isLoading]);

  const getConfig = useOperationsBoardConfig({ startDate, endDate });

  const events = useOperationBoardEvents();

  const collections = useMemo(
    () => ({ trade: tradeCollection, role: roleCollection, project: projectCollection }),
    [projectCollection, roleCollection, tradeCollection]
  );

  const {
    ref: ganttRef,
    portals,
    gantt,
  } = useGantt({
    layers: layers,
    collections: collections,
    tasks: formattedResourceRequest,
    events: events,
    onBeforeInit: initDataProcessor,
    resources: formattedResources,
    // assignments: assignments,
    zoomConfig: null,
    isLoading: isLoading,
    plugins: plugins,
    config: getConfig,
    templates: templates,
  });

  const filters = useMemo(() => [shouldShowResource], [shouldShowResource]);
  useAttachResourceEvents({ filters, gantt });

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <CircularProgress />
          <Typography>Please Wait...</Typography>
        </Box>
      ) : (
        <ContentLayout>
          <Stack
            direction={'row'}
            height={'100%'}
            overflow={'hidden'}
            position={'relative'}
            width={'100%'}
          >
            <Stack flexGrow={1} minWidth={0}>
              <Box
                flex={1}
                overflow={'hidden'}
                ref={ganttRef}
                sx={{
                  '.resource-request-cell-value': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',
                    overflow: 'hidden',
                  },

                  '.row .icon.disabled': {
                    opacity: 0,
                  },
                  '.row .icon:not(.disabled)': {
                    opacity: 0.7,
                  },
                  '.row .icon:not(.disabled):hover': {
                    opacity: 1,
                  },
                  '.gantt_row.gantt_row_request_status .gantt_cell_tree .gantt_tree_content': {
                    fontWeight: 'bold !important',
                  },
                  '.resource_request_cell': {
                    fontSize: '14px',
                    minWidth: '90%',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '80%',
                    borderRadius: '4px',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              >
                {portals}
                <GanttPortal containerId={'resource-panel-header'} gantt={gantt}>
                  <ResourceAssignmentHeader
                    filters={resourceFilters}
                    handleNameFilterChange={handleResourceNameFilterChange}
                    handleTradeFilterChange={handleResourceTradeFilterChange}
                    trades={tradeCollection.ids.map((tradeId) => tradeCollection.entities[tradeId])}
                  />
                </GanttPortal>
              </Box>
              <OperationModals />
            </Stack>
          </Stack>
        </ContentLayout>
      )}
    </>
  );
};
