import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { StyledDialog } from '@blackhyve/common';
import { Section, SectionFormInterface } from '../../types/section.models';
import { SectionFrom } from './SectionForm';
import {
  useCreateSectionMutation,
  useUpdateSectionMutation,
} from 'features/estimates/api/section.api';
import { format } from 'date-fns/format';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { TASK_COLORS } from 'helpers/constants';
import { random } from 'lodash';
import { parseDate } from '@blackhyve/utilities/dates';

const defaultValues: SectionFormInterface = {
  name: '',
  notes: '',
  color: TASK_COLORS[random(0, 44)].value,
  start_date: null,
  end_date: null,
};

export const SectionFormDialog = ({
  open,
  handleClose,
  section,
}: {
  open: boolean;
  handleClose: any;
  section?: Section;
}) => {
  const estimateId = Number(useParams().estimateId) || undefined;

  const [createSection, { isLoading: isCreatingSection }] = useCreateSectionMutation();
  const [updateSection, { isLoading: isUpdatingSection }] = useUpdateSectionMutation();

  const isUpdate = section?.id;
  const form = useForm({
    defaultValues,
    values: section
      ? {
          ...section,
          name: section.name,
          notes: section.notes,
          color: section.color,
          start_date: section.start_date ? parseDate(section.start_date) : null,
          end_date: section.end_date ? parseDate(section.end_date) : null,
        }
      : defaultValues,
  });

  const handleSave = (data) => {
    const payload = {
      ...data,
      estimate_id: estimateId,
      start_date: data.start_date ? format(data.start_date, 'yyyy-MM-dd') : null,
      end_date: data.end_date ? format(data.end_date, 'yyyy-MM-dd') : null,
    };

    const action = payload?.id ? updateSection : createSection;

    action(payload).then(() => {
      form.reset(defaultValues);
      handleClose();
    });
  };

  return (
    <StyledDialog
      handleClose={handleClose}
      open={open}
      title={isUpdate ? 'Update Section' : 'Add Section'}
      actions={
        <>
          <Button disabled={isCreatingSection || isUpdatingSection} onClick={handleClose}>
            Close
          </Button>
          <LoadingButton
            loading={isCreatingSection || isUpdatingSection}
            variant="contained"
            onClick={form.handleSubmit(handleSave)}
          >
            {isUpdate ? 'Update' : 'Create'}
          </LoadingButton>
        </>
      }
    >
      <SectionFrom form={form} onSubmit={handleSave} />
    </StyledDialog>
  );
};
