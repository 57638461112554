import { StyledDialog } from '@blackhyve/common';
import { Alert, Backdrop, Button, CircularProgress } from '@mui/material';
import useSanitizedObject from 'hooks/useSantizedObject';
import { isArray } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useCreateResourceMutation, useUpdateResourceMutation } from '../api/resources.api';
import { Resource, ResourceType, ResourceUnit } from '../api/resources.models';
import { ResourceForm } from './ResourceForm';

const defaultValues: Omit<Resource, 'id'> = {
  name: '',
  type: ResourceType.Labor,
  unit: ResourceUnit.Hours,
  max_capacity: 8,
  workday_calendar: {
    sunday: '0',
    monday: '1',
    tuesday: '1',
    wednesday: '1',
    thursday: '1',
    friday: '1',
    saturday: '0',
  },
  trade_id: null,
  role_id: null,
  parents: [],
  trades: [],
};

export function ResourceDialog({
  isGroup,
  open,
  handleClose,
  resource,
}: {
  isGroup: boolean;
  open: boolean;
  handleClose: any;
  resource?: Resource;
}) {
  const dispatch = useDispatch();
  const form = useForm({
    defaultValues,
    //@ts-ignore
    values: {
      ...useSanitizedObject(resource),
      trades: resource?.trades?.length
        ? resource?.trades?.map((trade) => ({
            trade_id: trade.trade_id,
            role_id: trade.role_id ? trade.role_id : undefined,
            trade_name: trade.trade_name,
            role_name: trade.role_name ? trade.role_name : undefined,
          }))
        : [],
    },
  });

  const isUpdate = Boolean(resource);
  const [createResource, { isLoading: isCreateLoading, isError: isCreateError }] =
    useCreateResourceMutation();
  const [updateResource, { isLoading: isUpdateLoading, isError: isUpdateError }] =
    useUpdateResourceMutation();

  const onSubmit = async (data) => {
    if (data.trades.length === 0) {
      form.setError('root.trades', {
        type: 'manual',
        message: 'Please add at least one trade or trade and role combination.',
      });
      return;
    }

    form.clearErrors('root.trades');

    data.parents = isArray(data.parents) ? data.parents : [data.parents];
    if (data.parents.includes('')) {
      data.parents = [];
    }

    if (isUpdate && resource?.id) {
      await updateResource({
        resourceId: resource.id,
        data,
      });
    } else {
      await createResource({ data }).unwrap();
      form.reset({ ...defaultValues });
    }

    handleClose();
  };

  useEffect(() => {
    if (!isUpdate && open) {
      form.reset(defaultValues);
    }
  }, [isUpdate, open, form]);

  return (
    <StyledDialog
      handleClose={handleClose}
      open={open}
      title={resource ? 'Update Resource' : 'Create Resource'}
      actions={
        <>
          <Button onClick={handleClose}>Close</Button>
          <Button variant="contained" onClick={form.handleSubmit(onSubmit)}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </>
      }
    >
      {(isCreateLoading || isUpdateLoading) && (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress />
        </Backdrop>
      )}
      {(isCreateError || isUpdateError) && (
        <Alert severity="error">An error occurred. Please try again.</Alert>
      )}
      <ResourceForm form={form} isGroup={isGroup} onSubmit={onSubmit} />
    </StyledDialog>
  );
}
