import { parseDate } from '@blackhyve/utilities/dates';
import { Star, StarOutline } from '@mui/icons-material';
import { CircularProgress, Fab, IconButton, Stack, TableContainer } from '@mui/material';
import SearchBox from 'components/search/SearchBox';
import { Column, Table, TableProps } from 'components/table/Table';
import {
  useGetEstimatesQuery,
  useUpdateEstimateMutation,
} from 'features/estimates/api/estimate.api';
import { Estimate } from 'features/estimates/types/estimate.models';
import { useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { EstimateActionMenu } from './EstimateActionMenu';
import { EstimateFormDialog } from './EstimateFormDialog';
import { formatCurrency } from 'helpers/numberFormatter';

export type EstimateTableProps = {
  projectId: number;
  TableProps: Partial<TableProps<Estimate>>;
};

const columns: Column<Estimate>[] = [
  {
    field: 'is_primary',
    label: '',
    render: ({ is_primary, id }) => <PrimaryColumnCell estimateId={id} isPrimary={is_primary} />,
    cellProps: { padding: 'checkbox' },
  },
  {
    field: 'name',
    label: 'Name',
  },
  {
    field: 'rollup.rom_total',
    label: 'Estimate Total',
    cellProps: { sx: { display: { xs: 'none', sm: 'table-cell' } } },
    render: ({ rollup }) => (rollup?.rom_total ? formatCurrency(rollup.rom_total) : '-'),
  },
  {
    field: 'rollup.rom_labor',
    label: 'Labor Estimate',
    cellProps: { sx: { display: { xs: 'none', sm: 'table-cell' } } },
    render: ({ rollup }) => (rollup?.rom_labor ? formatCurrency(rollup.rom_labor) : '-'),
  },
  {
    field: 'rollup.rom_material',
    label: 'Material Estimate',
    cellProps: { sx: { display: { xs: 'none', sm: 'table-cell' } } },
    render: ({ rollup }) => (rollup?.rom_material ? formatCurrency(rollup.rom_material) : '-'),
  },
  {
    field: 'updated_at',
    label: 'Updated At',
    cellProps: { sx: { display: { xs: 'none', md: 'table-cell' } } },
    render: (estimate) => parseDate(estimate.updated_at).toLocaleDateString(),
  },
  {
    field: 'created_at',
    label: 'Created At',
    cellProps: { align: 'right', sx: { display: { xs: 'none', md: 'table-cell' } } },
    render: (estimate) => parseDate(estimate.created_at).toLocaleDateString(),
  },
  {
    field: 'action',
    label: '',
    cellProps: { align: 'right' },
    render: (estimate) => (
      <div onClick={(event) => event.stopPropagation()}>
        <EstimateActionMenu estimate={estimate} />
      </div>
    ),
  },
];

function PrimaryColumnCell({ estimateId, isPrimary }) {
  const [updateEstimate, { isLoading: isUpdatingEstimate }] = useUpdateEstimateMutation();
  return (
    <IconButton
      onClick={(event) => {
        event.stopPropagation();
        updateEstimate({ id: estimateId, is_primary: !isPrimary });
      }}
    >
      {isUpdatingEstimate ? (
        <CircularProgress size={'1em'} />
      ) : isPrimary ? (
        <Star htmlColor={'#e3b341'} />
      ) : (
        <StarOutline />
      )}
    </IconButton>
  );
}

export function EstimateTable({ projectId, TableProps }: EstimateTableProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounceValue(searchQuery, 500);

  const [isEstimateDialogOpen, setIsEstimateDialogOpen] = useState(false);

  const {
    data: estimates = [],
    isLoading: isLoadingEstimates,
    isFetching: isFetchingEstimates,
  } = useGetEstimatesQuery({
    params: { q: debouncedSearchQuery, project: projectId },
  });

  return (
    <>
      <Stack
        alignItems={'center'}
        direction={'row'}
        flexWrap={'wrap-reverse'}
        justifyContent={'space-between'}
        rowGap={1}
      >
        <SearchBox
          backgroundColor={'grey.200'}
          borderRadius={1}
          fullWidth={false}
          placeholder={'Search By Name'}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        <Fab
          color={'secondary'}
          size={'small'}
          sx={{ textWrap: 'nowrap' }}
          variant={'extended'}
          onClick={() => setIsEstimateDialogOpen(true)}
        >
          Add Estimate
        </Fab>
        <EstimateFormDialog
          handleClose={() => setIsEstimateDialogOpen(false)}
          open={isEstimateDialogOpen}
          projectId={projectId}
        />
      </Stack>
      <TableContainer>
        <Table
          columns={columns}
          defaultOrder={'desc'}
          defaultOrderBy={'created_at'}
          isFetching={isFetchingEstimates}
          isLoading={isLoadingEstimates}
          rows={estimates}
          handleSort={function (property: string, order: 'asc' | 'desc'): void {
            throw new Error('Function not implemented.');
          }}
          {...TableProps}
        />
      </TableContainer>
    </>
  );
}
