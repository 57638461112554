import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DirectionsWalk from '@mui/icons-material/DirectionsWalk';
import { Button, CircularProgress, Paper } from '@mui/material';
import { JobwalkHistory } from 'features/jobwalk/components/JobwalkHistory';
import { useState } from 'react';
import { JobwalkInput } from 'features/jobwalkInput/routes/JobwalkInput';
import { useGetProjectHolidaysQuery } from 'features/projects/store/project.api';
import { StyledDialog } from '@blackhyve/common';

export const JobwalkTab = ({ task }) => {
  const [displayAddJobwalkDialog, setDisplayAddJobwalkDialog] = useState(false);

  return (
    <Grid container gap={1}>
      <Grid container item alignItems={'center'} xs={12}>
        <IconButton disableRipple size="large">
          <DirectionsWalk fontSize="inherit" />
        </IconButton>
        <Typography sx={{ fontWeight: '600' }} variant="h6">
          Jobwalk
        </Typography>
        <Button variant="text" onClick={() => setDisplayAddJobwalkDialog((prev) => !prev)}>
          + ADD Jobwalk
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid item width={'100%'}>
          <Paper>
            <JobwalkHistory
              TableContainerProps={{ sx: { maxHeight: '400px', overflow: 'auto' } }}
              TableProps={{ stickyHeader: true }}
              taskId={task.id}
            />
          </Paper>
        </Grid>
      </Grid>
      {displayAddJobwalkDialog && (
        <CreateJobwalkDialog
          handleClose={() => setDisplayAddJobwalkDialog(false)}
          open={displayAddJobwalkDialog}
          task={task}
        />
      )}
    </Grid>
  );
};

const CreateJobwalkDialog = ({ task, handleClose, open }) => {
  const { data: { holidays = [] } = {}, isLoading: isLoadingProjectHolidays } =
    useGetProjectHolidaysQuery(task.project_id);

  return (
    <StyledDialog
      DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={() => handleClose()}
      maxWidth="sm"
      open={open}
      title="Create Jobwalk"
    >
      {isLoadingProjectHolidays ? (
        <CircularProgress className="loading" />
      ) : (
        <Paper>
          <JobwalkInput
            holidays={holidays}
            shouldAutoschedule={true}
            task={task}
            onSuccess={handleClose}
          />
        </Paper>
      )}
    </StyledDialog>
  );
};
