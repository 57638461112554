import { transformScheduleToTable } from '../utils/transformToTable';
import _ from 'lodash';
import { parse } from 'date-fns';
import parser from 'any-date-parser';
import { detectIndentLevels } from '../utils/inferWbsFromBoundingBoxes';

const taskPropertyMapping = {
  Identifier: 'identifier',
  Name: 'name',
  'Scheduled Start': 'scheduled_start',
  'Scheduled End': 'scheduled_end',
  Duration: 'duration',
};

export interface PropertyMappedTask {
  id: number;
  identifier?: string;
  name: string;
  scheduled_start: Date;
  scheduled_end: Date;
  duration?: string;
  indent_level: number;
  anomaly_type?: string;
  confidence: number;
  mapped_identifier_to_name?: boolean;
  bounding_box_left: number;
}

export function useScheduleEntries(importData): [PropertyMappedTask[], any] | [] {
  if (_.isNil(importData)) return [];

  const scheduleColumns = importData?.schedule_columns || [];
  const scheduleEntries = transformScheduleToTable<{
    text: string;
    row: number;
    column: number;
    page: number;
    bounding_box_left: string;
  }>(importData?.schedule_entries || []);
  const mapToByIndex = scheduleColumns.reduce((acc, col) => {
    if (col.map_to) {
      acc[col.index] = col.map_to;
    }
    return acc;
  }, {});

  const { processedBlocks: indentLevels, clusters } = detectIndentLevels(
    importData.schedule_entries.filter((e) => e.column === 0)
  );

  const mapped = scheduleEntries
    .filter((row) => row[0]) // TODO: FIX POTENTIAL DATA LOSS HERE, not all rows will have detected column 0
    .map((row, index) => {
      const { indent_level, anomaly_type, confidence } = indentLevels[index];
      const mappedRow = row.reduce(
        (obj, entry) => {
          if (!entry) {
            return obj;
          }

          if (entry.column === 0) {
            obj.bounding_box_left = parseFloat(entry.bounding_box_left);
          }

          const mapTo = mapToByIndex[entry.column];
          if (mapTo) {
            const taskProperty = taskPropertyMapping[mapTo];
            switch (taskProperty) {
              // TODO: drop duration on the ground for now
              // case 'duration':
              //   obj[taskProperty] = parseFloat(entry.text);
              //   break;
              case 'scheduled_start':
                obj[taskProperty] = parser.fromString(entry.text);
                obj['$raw_scheduled_start'] = entry.text;
                break;
              case 'scheduled_end': {
                obj[taskProperty] = parser.fromString(entry.text);
                obj['$raw_scheduled_end'] = entry.text;
                break;
              }
              case 'name':
              case 'identifier': {
                obj[taskProperty] = entry.text;
                break;
              }
            }
          }

          return {
            ...obj,
            id: 1 + index,
          };
        },
        {
          indent_level,
          anomaly_type,
          confidence: (confidence * 100).toFixed(2),
        } as unknown as PropertyMappedTask
      );

      if (!mappedRow.name && mappedRow.identifier) {
        mappedRow.name = mappedRow.identifier;
        mappedRow.mapped_identifier_to_name = true;

        delete mappedRow.identifier;
      }

      if (!mappedRow.scheduled_start && mappedRow.scheduled_end) {
        mappedRow.scheduled_start = mappedRow.scheduled_end;
      }

      if (!mappedRow.scheduled_end && mappedRow.scheduled_start) {
        mappedRow.scheduled_end = mappedRow.scheduled_start;
      }

      return mappedRow;
    });

  const headings = mapped.filter((m) => m.mapped_identifier_to_name);
  const { processedBlocks: headingIndentLevels, clusters: headingIndentClusters } =
    detectIndentLevels(headings, { bandwidthMultiplier: 0.05, minClusterSize: 1 });

  let lastHeadingLevel = 0;
  const result = mapped.map((m, index) => {
    let indent_level, confidence;

    if (m.mapped_identifier_to_name) {
      // For headings, use the detected indent levels
      const level = headingIndentLevels.find((h) => h.id === m.id);
      indent_level = level?.indent_level ?? 0;
      lastHeadingLevel = indent_level;
    } else {
      // For tasks, indent one level deeper than the last heading
      indent_level = lastHeadingLevel + 1;
    }

    return {
      ...m,
      indent_level,
      confidence,
    };
  });

  return [result, clusters];
}
