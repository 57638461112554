import useGanttDataProcessor from 'features/gantt/hooks/useGanttDataProcessor';
import {
  useBulkCreateResourceRequestAssignmentMutation,
  useBulkDeleteResourceRequestAssignmentMutation,
  useBulkUpdateResourceRequestAssignmentMutation,
} from 'features/resources/api/resourceRequest.api';
import { useCallback } from 'react';

interface GanttDataProcessorProps {
  formatters: { format: Function; undoFormat: Function };
}

type ApiFunction = (payload: any) => Promise<any>;

const useAssignmentPayloadWrapper = (fn: ApiFunction) => {
  return useCallback(({ tasks }) => fn({ assignments: [...tasks] }), [fn]);
};

export const useOperationBoardGanttDataProcessor = ({ formatters }: GanttDataProcessorProps) => {
  const [
    createAssignments,
    {
      isLoading: isCreatingAssignment,
      isError: isCreatingAssignmentError,
      isSuccess: isCreatingAssignmentSuccess,
    },
  ] = useBulkCreateResourceRequestAssignmentMutation();

  const [
    updateAssignments,
    {
      isLoading: isUpdatingAssignment,
      isError: isUpdatingAssignmentError,
      isSuccess: isUpdatingAssignmentSuccess,
    },
  ] = useBulkUpdateResourceRequestAssignmentMutation();

  const [
    deleteAssignments,
    {
      isLoading: isDeletingAssignment,
      isError: isDeletingAssignmentError,
      isSuccess: isDeletingAssignmentSuccess,
    },
  ] = useBulkDeleteResourceRequestAssignmentMutation();

  const isSaving = isCreatingAssignment || isUpdatingAssignment || isDeletingAssignment;
  const isError =
    isCreatingAssignmentError || isUpdatingAssignmentError || isDeletingAssignmentError;
  const isSuccess =
    isCreatingAssignmentSuccess || isUpdatingAssignmentSuccess || isDeletingAssignmentSuccess;

  //@ts-ignore
  const { initDataProcessor, processChanges, hasPendingUpdates } = useGanttDataProcessor({
    createTasks: useAssignmentPayloadWrapper(createAssignments),
    updateTasks: useAssignmentPayloadWrapper(updateAssignments),
    deleteTasks: useAssignmentPayloadWrapper(deleteAssignments),
    formatters,
  });

  return {
    initDataProcessor,
    isSaving,
    isError,
    isSuccess,
    processChanges,
    hasPendingUpdates,
  };
};
