import { ConfirmScheduleWBS } from '../components/schedules/ConfirmScheduleWBS';
import { ImportBoundingBox } from '../components/schedules/ImportBoundingBox';
import { ImportColumnMapper } from '../components/schedules/ImportColumnMapper';
import { MatchLaborEstimateAreas } from '../components/labor-estimates/MatchLaborEstimateAreas';
import { MatchLaborEstimateColumns } from '../components/labor-estimates/MatchLaborEstimateColumns';
import { MatchLaborEstimateToSchedule } from '../components/labor-estimates/MatchLaborEstimateToSchedule';
import { Imports } from './Imports';

export const importsRoutes = [
  { path: '', element: <Imports /> },
  { path: 'schedule/:importId/bounding-box', element: <ImportBoundingBox /> },
  { path: 'schedule/:importId/column-mapper', element: <ImportColumnMapper /> },
  { path: 'schedule/:importId/confirm-wbs', element: <ConfirmScheduleWBS /> },
  { path: 'labor/:importId', element: <MatchLaborEstimateColumns /> },
  { path: 'labor/:importId/areas', element: <MatchLaborEstimateAreas /> },
  { path: 'labor/:importId/schedule', element: <MatchLaborEstimateToSchedule /> },
];
