import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Alert,
  Paper,
  CircularProgress,
  Skeleton,
  IconButton,
  Tooltip,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ContentLayout } from 'components/layouts';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import { useGetTagsQuery } from 'features/tags/store/tag.api';
import { useGetImportQuery, useUpdateImportMutation } from '../../api/imports.api';
import { transformToTable } from '../../utils/transformToTable';
import { Dropdown } from '../Dropdown';
import { LaborEstimateColumn } from '../../api/imports.models';

interface Tag {
  id: string;
  name: string;
  type: string;
}

interface DropdownOption {
  id: string;
  label: string;
  children?: Array<{ id: string; label: string }>;
}

export function MatchLaborEstimateColumns() {
  const navigate = useNavigate();
  const { importId } = useParams();
  const [columnMappings, setColumnMappings] = useState<Record<number, string>>({});
  const [error, setError] = useState<string | null>(null);

  const parsedImportId = parseInt(importId ?? '', 10);

  const {
    data: importData,
    error: importError,
    isLoading: isLoadingImport,
  } = useGetImportQuery({ backendImportId: parsedImportId }, { skip: isNaN(parsedImportId) });

  const [updateImport, { isLoading: isUpdating }] = useUpdateImportMutation();

  const {
    data: tags = [],
    isLoading: isLoadingTags,
    error: tagsError,
  } = (useGetTagsQuery as any)();

  const dropdownOptions: DropdownOption[] = [
    {
      id: 'task-properties',
      label: 'Task Properties',
      children: [
        { id: 'task:demand', label: 'Demand' },
        { id: 'task:name', label: 'Name' },
      ],
    },
    {
      id: 'smart-tags',
      label: 'Smart Tags',
      children: (tags as Tag[])
        .filter((t) => t.type === 'smart')
        .map((tag) => ({
          id: 'tag:' + tag.name,
          label: tag.name,
        })),
    },
  ];

  useEffect(() => {
    if (importData?.labor_estimate_columns) {
      const initialMappings = importData.labor_estimate_columns.reduce(
        (acc: Record<number, string>, col: LaborEstimateColumn) => {
          if (col.map_to !== '') {
            acc[col.id] = col.map_to;
          }
          return acc;
        },
        {}
      );
      setColumnMappings(initialMappings);
    }
  }, [importData]);

  const handleMappingChange = useCallback(
    (columnId: number) => (event: React.ChangeEvent<{ value: unknown }>) => {
      setColumnMappings((prev) => ({
        ...prev,
        [columnId]: event.target.value as string,
      }));
      setError(null);
    },
    []
  );

  const handleNextPage = useCallback(async () => {
    try {
      const laborEstimateColumns = Object.entries(columnMappings).map(([columnId, mapTo]) => ({
        id: parseInt(columnId),
        map_to: mapTo || null,
      }));

      await updateImport({
        backendImportId: parsedImportId,
        updateData: {
          labor_estimate_columns: laborEstimateColumns,
        },
      }).unwrap();

      navigate('areas');
    } catch (error) {
      console.error('Failed to update mappings:', error);
      setError('Failed to update column mappings. Please try again.');
    }
  }, [parsedImportId, columnMappings, updateImport, navigate]);

  if (isLoadingTags || isLoadingImport) {
    return (
      <Container maxWidth="xl">
        <Box sx={{ py: 4 }}>
          <Skeleton height={60} variant="text" width="40%" />
          <Box sx={{ mt: 4 }}>
            <Skeleton height={400} variant="rectangular" />
          </Box>
        </Box>
      </Container>
    );
  }

  if (isNaN(parsedImportId)) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        Invalid Import ID
      </Alert>
    );
  }

  if (importError || tagsError) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        Error loading data. Please refresh the page.
      </Alert>
    );
  }

  const laborEstimateColumns = importData?.labor_estimate_columns || [];
  const laborEstimateEntries = transformToTable(importData?.labor_estimate_entries || []).slice(
    0,
    10
  );
  const unmappedColumns = laborEstimateColumns.filter((col) => !columnMappings[col.id]).length;

  return (
    <ContentLayout>
      <Container maxWidth="xl">
        <Box sx={{ py: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
            <Typography sx={{ fontWeight: 500 }} variant="h4">
              Match Labor Estimate Columns
            </Typography>
            <Tooltip title="Map columns to their corresponding properties">
              <IconButton size="small">
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          {unmappedColumns > 0 && (
            <Alert severity="info" sx={{ mb: 3 }}>
              {unmappedColumns} column{unmappedColumns !== 1 ? 's' : ''} still need
              {unmappedColumns === 1 ? 's' : ''} mapping
            </Alert>
          )}

          <Paper sx={{ mb: 4, overflow: 'hidden' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {laborEstimateColumns.map((column) => (
                    <TableCell key={column.id} sx={{ fontWeight: 600 }}>
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {laborEstimateColumns.map((column) => (
                    <TableCell key={'col:' + column.id}>
                      <Dropdown
                        list={dropdownOptions}
                        name={`column-${column.id}`}
                        value={columnMappings[column.id]}
                        onChange={handleMappingChange(column.id)}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {laborEstimateEntries.map((row, rowIndex) => (
                  <TableRow hover key={'row:' + rowIndex}>
                    {row.map((column, columnIndex) => (
                      <TableCell key={'row:' + rowIndex + '-col:' + columnIndex}>
                        {column.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <Button
              color="inherit"
              startIcon={<ArrowBackIcon />}
              variant="outlined"
              onClick={() => navigate('../')}
            >
              Back
            </Button>
            <Button
              color="primary"
              disabled={Object.keys(columnMappings).length === 0 || isUpdating}
              endIcon={!isUpdating && <NavigateNextIcon />}
              variant="contained"
              onClick={handleNextPage}
            >
              {isUpdating ? (
                <>
                  <CircularProgress size={20} sx={{ mr: 1 }} />
                  Saving...
                </>
              ) : (
                'Next'
              )}
            </Button>
          </Box>
        </Box>
      </Container>
    </ContentLayout>
  );
}
