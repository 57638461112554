import { OptionsMenu } from '@blackhyve/common';
import ConfirmDeleteDialog from 'components/common/popovers/ConfirmDeleteDialog';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useDeleteProductLineItemMutation } from 'features/estimates/api/productLine.api';
import { ProductLineItem } from 'features/estimates/types/productLine.models';
import { useState } from 'react';
import { ProductLineFormDialog } from './ProductLineFormDialog';

type EditDialogType = 'edit' | 'delete' | false;

export const ProductLineActionMenu = ({
  productLineItem,
}: {
  productLineItem: ProductLineItem;
}) => {
  const [openDialog, setOpenDialog] = useState<EditDialogType>(false);
  const [deleteProductLineItem, { isLoading: isDeleting }] = useDeleteProductLineItemMutation();

  const handleClose = () => setOpenDialog(false);

  const handleDelete = () => {
    if (!productLineItem.id || !productLineItem.section_id) {
      return;
    }
    deleteProductLineItem({
      id: productLineItem.id,
      estimateId: productLineItem.estimate_id,
      sectionId: productLineItem.section_id,
    }).then(() => {
      setOpenDialog(false);
    });
  };

  return (
    <>
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        triggerButton={
          <IconButton size="small">
            <MoreVert />
          </IconButton>
        }
      >
        {(handleClose) => {
          return (
            <>
              <ListItemButton
                dense
                onClick={() => {
                  handleClose();
                  setOpenDialog('edit');
                }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </ListItemButton>
              <ListItemButton
                dense
                onClick={() => {
                  handleClose();
                  setOpenDialog('delete');
                }}
              >
                <ListItemIcon style={{ color: 'red' }}>
                  <Delete />
                </ListItemIcon>
                <ListItemText style={{ color: 'red' }}>Delete</ListItemText>
              </ListItemButton>
            </>
          );
        }}
      </OptionsMenu>
      {openDialog === 'edit' && (
        <ProductLineFormDialog
          handleClose={handleClose}
          open={true}
          productLine={productLineItem}
        />
      )}

      {openDialog === 'delete' && (
        <ConfirmDeleteDialog
          handleClose={handleClose}
          handleDelete={handleDelete}
          id={productLineItem.id}
          isLoading={isDeleting}
          item={'Product Line Item'}
          itemNames={undefined}
          open={true}
          plural={false}
        />
      )}
    </>
  );
};
