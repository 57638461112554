import { Card, CardHeader, CardContent, Divider, Stack, Box } from '@mui/material';
import { Skeleton } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import { MetricsSkeleton } from '../metrics/MetricsSkeleton';
import { ProductLineTableSkeleton } from '../productLines/ProductLinteSkeleton';

export const SectionSkeleton = () => {
  return (
    <Card elevation={5}>
      <CardHeader
        avatar={<DragIndicator sx={{ opacity: 0.2 }} />}
        title={<Skeleton variant="text" width={200} />}
        titleTypographyProps={{ variant: 'h5' }}
      />
      <CardContent>
        <MetricsSkeleton />
        <Divider sx={{ my: 2 }} />
        <ProductLineTableSkeleton />
      </CardContent>
    </Card>
  );
};
