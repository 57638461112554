import { StyledDialog } from '@blackhyve/common';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import {
  useCreateProductLineItemMutation,
  useUpdateProductLineItemMutation,
} from 'features/estimates/api/productLine.api';
import { CURVE_TYPE, CURVE_DISTRIBUTION } from 'features/products/constants/curves';
import { useForm } from 'react-hook-form';
import { ProductLineForm } from './ProductLineForm';
import { ProductLineItem } from 'features/estimates/types/productLine.models';

const defaultValues = {
  name: '',
  estimate_base: undefined,
  rom_of_unit: undefined,
  revenue_total: undefined,
  revenue_labor: undefined,
  labor_percent: undefined,
  revenue_material: undefined,
  material_percent: undefined,
  cogs_labor: undefined,
  cogs_labor_percent: undefined,
  cogs_material: undefined,
  cogs_material_percent: undefined,
  material_curve: CURVE_TYPE.BELL,
  material_curve_distribution: CURVE_DISTRIBUTION.NORMAL,
  cogs_per_hour: undefined,
  labor_curve: CURVE_TYPE.BELL,
  labor_curve_distribution: CURVE_DISTRIBUTION.NORMAL,
  trade_id: undefined,
  labor_hours: undefined,
};

export const ProductLineFormDialog = ({
  open,
  handleClose,
  productLine,
  estimateId,
  sectionId,
}: {
  open: boolean;
  estimateId?: number;
  sectionId?: number;
  handleClose: any;
  productLine?: any;
}) => {
  const form = useForm<ProductLineItem>({
    defaultValues,
    values: productLine,
  });

  const [createProductLineItem, { isLoading: isCreating }] = useCreateProductLineItemMutation();
  const [updateProductLineItem, { isLoading: isUpdating }] = useUpdateProductLineItemMutation();

  const onSubmit = (data) => {
    let payload = data;
    let action;

    if (data.id === undefined) {
      payload = { ...data, estimate_id: estimateId, section_id: sectionId };
      action = createProductLineItem(payload);
    } else {
      action = updateProductLineItem(payload);
    }

    action
      .then(() => {
        form.reset({ ...defaultValues });
        handleClose();
      })
      .catch((error) => {
        console.log('error =>', error);
      });
  };

  return (
    <StyledDialog
      handleClose={handleClose}
      maxWidth="sm"
      open={open}
      title={'Create Product Line Item'}
      actions={
        <>
          <Button size="small" onClick={handleClose}>
            Close
          </Button>
          <LoadingButton
            loading={isCreating || isUpdating}
            size="small"
            variant="contained"
            onClick={form.handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </>
      }
    >
      <ProductLineForm form={form} onSubmit={onSubmit} />
    </StyledDialog>
  );
};
