import { Paper, Typography, Stack, Box } from '@mui/material';
import { AnomalyType } from 'features/imports/api/imports.models';

const getAnomalyType = (anomalyType: string) => {
  if (anomalyType === AnomalyType.InvalidIndentation) {
    return 'Check Indentation';
  }
  if (anomalyType === AnomalyType.Orphan) {
    return 'Check Parent';
  }
  return '';
};

export function WBSAnomalies({ anomalies, onAnomalyClick }) {
  return (
    <Paper sx={{ flex: 1, p: 2, borderRadius: 2, boxShadow: 2 }}>
      <Typography gutterBottom variant="h5">
        Tasks with Anomalies
      </Typography>
      <Stack spacing={2}>
        {anomalies.map((anomaly, index) => (
          <Paper
            elevation={1}
            key={index}
            sx={{
              p: 2,
              bgcolor: 'grey.50',
              borderRadius: 1,
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'grey.100',
              },
            }}
            onClick={() => onAnomalyClick(anomaly.id)}
          >
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <Typography color="text.primary" fontWeight="bold" variant="body1">
                Task: {anomaly.name}
              </Typography>
              <Box
                sx={{
                  px: 2,
                  py: 0.5,
                  bgcolor: anomaly.type === 'Critical' ? 'error.main' : 'warning.main',
                  color: 'white',
                  borderRadius: 1,
                }}
              >
                <Typography fontWeight="bold" variant="body2">
                  {getAnomalyType(anomaly.anomaly_type)}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Paper>
  );
}
