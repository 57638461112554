import { GanttStatic, Task } from '@blackhyve/dhtmlx-gantt';
import { alpha } from '@mui/material';

const parentBoundsLayer = {
  id: 'parent_bounds_layer',
  renderer: {
    render: function (
      task: Task,
      timeline: { $gantt: GanttStatic },
      config: any,
      pos: any
    ): HTMLElement | void {
      const gantt = timeline.$gantt;
      if (!task.parent) return;

      const boundingTask =
        task.type === gantt.config.types.resource_request ? task : gantt.getTask(task.parent);

      const { start_date: startDate, end_date: endDate } = boundingTask;

      if (!startDate || !endDate) return;

      const el = document.createElement('div');
      const color = gantt.getCollectionEntity('trade', boundingTask.trade_id)?.color || '#a0a0a0';
      el.className = 'parent-bounds-highlight';
      el.style.backgroundColor = alpha(color, 0.1);
      el.style.borderLeft = `2px solid ${color}`;
      el.style.borderRight = `2px solid ${color}`;
      el.style.boxSizing = 'border-box';

      const left = gantt.posFromDate(startDate);
      const right = gantt.posFromDate(endDate);
      const top = gantt.getTaskTop(task.id);
      const height = gantt.getTaskHeight();

      el.style.position = 'absolute';
      el.style.left = `${left}px`;
      el.style.top = `${top}px`;
      el.style.width = `${right - left}px`;
      el.style.height = `${height}px`;

      el.title = `Parent Task: ${boundingTask.text}\nSpan: ${startDate} - ${endDate}`;

      return el;
    },
  },
  filter: function (task: Task): boolean {
    return Boolean(task.parent && !task.$virtual);
  },
  topmost: false,
};

export const layers = [parentBoundsLayer];
