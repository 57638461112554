import { api, providesList } from 'api';

export const BUDGET_TAG = 'Budget';

const budgetAPI = api.enhanceEndpoints({ addTagTypes: [BUDGET_TAG] }).injectEndpoints({
  endpoints: (build) => ({
    getBudgets: build.query({
      query: ({ projectId }) => `/projects/${projectId}/budgets`,
      providesTags: (result, _error, _args) => (result ? providesList(result, BUDGET_TAG) : []),
      transformResponse: (response) =>
        response.data.map((budget) => ({
          ...budget,
          summaries: response.summaries[budget.id],
        })),
    }),
    createBudgetsByProject: build.mutation({
      query: ({ projectId, ...body }) => ({
        url: `/projects/${projectId}/budgets`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, params) =>
        result ? [{ type: BUDGET_TAG, id: 'LIST' }] : [],
    }),
    getBudgetById: build.query({
      query: ({ budgetId }) => `/budgets/${budgetId}`,
      providesTags: (result, error, { budgetId }) => [{ type: BUDGET_TAG, id: budgetId }],
      transformResponse: (response) => {
        return {
          ...response.data,
          summaries: response.summaries,
        };
      },
    }),
    getUsersWithAccess: build.query({
      query: ({ budgetId }) => `/budgets/${budgetId}/users`,
      providesTags: (result, error, { budgetId }) => [{ type: 'BudgetUsers', id: budgetId }],
      transformResponse: (response) => {
        return response.data;
      },
    }),
    giveUserAccess: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/budgets/${id}/users`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { id }) => {
        return result ? [{ type: 'BudgetUsers', id }] : [];
      },
    }),
    removeUserAccess: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/budgets/${id}/users`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (result, error, { id }) => {
        return result ? [{ type: 'BudgetUsers', id }] : [];
      },
    }),
    updateBudgetById: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/budgets/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: BUDGET_TAG, id: id },
              { type: 'BudgetLine', id: 'LIST' },
              { type: 'BudgetForecast', id: id },
              { type: 'ProjectForecast' },
            ]
          : [],
    }),
    deleteBudgetById: build.mutation({
      query: ({ id }) => ({
        url: `/budgets/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: BUDGET_TAG, id: id },
              { type: 'BudgetLine', id: 'LIST' },
              { type: 'BudgetForecast', id: id },
              { type: 'ProjectForecast' },
            ]
          : [],
    }),
  }),
});

export const {
  useGetBudgetsQuery,
  useGetBudgetByIdQuery,
  useGiveUserAccessMutation,
  useGetUsersWithAccessQuery,
  useRemoveUserAccessMutation,
  useCreateBudgetsByProjectMutation,
  useUpdateBudgetByIdMutation,
  useDeleteBudgetByIdMutation,
} = budgetAPI;
