const { unionBy } = require('lodash');

/**
 * Get task tags
 * @param {Number} taskId
 * @param {Gantt} gantt
 * @returns {[Tags]}
 */
function getTaskTags(taskId, gantt) {
  const task = gantt.getTask(taskId);
  const parentTags = getParentTags(taskId, gantt);
  const existingTags = task?.tags?.length
    ? task?.tags?.filter((tag) => tag.tagged_by !== 0 && tag.type !== 'generated')
    : [];
  return unionBy(parentTags, existingTags, 'id');
}

const getParentTags = (id, gantt) => {
  const smartTags = [];
  const cascadingTags = {};

  gantt.eachParent((parent) => {
    parent?.tags?.forEach(({ type, id, ...rest }) => {
      if (type === 'smart') {
        smartTags.push({ id, type, ...rest, parentId: parent.id });
      } else if (type === 'cascading') {
        cascadingTags[id] = { id, type, ...rest, tagged_by: 0 };
      }
    });
  }, id);

  const typePriority = ['location', 'phase', 'zone', 'area'];
  const customSort = (a, b) => {
    const priorityA = typePriority.indexOf(a.smart_key);
    const priorityB = typePriority.indexOf(b.smart_key);
    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    return 0;
  };

  // Finding generated tags of those smart tags
  const newGeneratedTags = smartTags
    ?.reverse()
    ?.map((smartTag) => {
      // Try to find existing generated tag
      const existingTag = gantt?.constants?.tags?.find(
        (tag) =>
          tag.type === 'generated' &&
          tag.smart_tag_id === smartTag.id &&
          tag.smart_task_id === smartTag.parentId
      );

      // If found, return it
      if (existingTag) return existingTag;

      // If not found, create temporary one
      const parent = gantt.getTask(smartTag.parentId);
      return {
        object_type: 'tag',
        color: null,
        id: gantt.uid(),
        name: parent.text,
        type: 'generated',
        smart_task_id: smartTag.parentId,
        smart_tag_id: smartTag.id,
        smart_key: smartTag.name,
      };
    })
    .filter(Boolean);

  newGeneratedTags?.sort(customSort);
  return [...Object.values(cascadingTags), ...newGeneratedTags];
};

module.exports = { getTaskTags };
