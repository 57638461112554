import { Scales } from '@blackhyve/dhtmlx-gantt';
import { endOfWeek, format } from 'date-fns';

export const scales: Scales = [
  {
    unit: 'week',
    step: 1,
    format: function (date) {
      const endDate = endOfWeek(date, { weekStartsOn: 1 });
      return `
        ${format(date, 'MMM')}-${format(date, 'd')} - ${format(endDate, 'MMM')}-${format(endDate, 'd')}
      `;
    },
  },
  {
    unit: 'day',
    step: 1,
    format: function (date) {
      return format(date, 'EEEEE') + ' ' + format(date, 'dd');
    },
  },
];
