import { Select, MenuItem, IconButton, Box, FormControl, InputLabel } from '@mui/material';

export const AreaSelector = ({
  areas = [],
  selectedAreaId = null,
  onAreaChange = (a: any) => {},
}: {
  areas: any;
  selectedAreaId: any;
  onAreaChange: any;
}) => {
  const currentIndex = areas.findIndex((area) => area.id === selectedAreaId);

  const handlePrevious = () => {
    if (currentIndex > 0) {
      onAreaChange(areas[currentIndex - 1].id);
    }
  };

  const handleNext = () => {
    if (currentIndex < areas.length - 1) {
      onAreaChange(areas[currentIndex + 1].id);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <IconButton
        disabled={currentIndex <= 0}
        size="small"
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 1,
          '&:hover': { bgcolor: 'action.hover' },
        }}
        onClick={handlePrevious}
      >
        ←
      </IconButton>

      <FormControl>
        <InputLabel id="area-selector-label">Area</InputLabel>
        <Select
          label="Area"
          labelId="area-selector-label"
          value={selectedAreaId || ''}
          sx={{
            minWidth: 200,
            bgcolor: 'background.paper',
            boxShadow: 1,
            '& .MuiSelect-select': { py: 1 },
          }}
          onChange={(e) => onAreaChange(e.target.value)}
        >
          {areas.map((area) => (
            <MenuItem key={area.id} value={area.id}>
              {area.area_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <IconButton
        disabled={currentIndex >= areas.length - 1}
        size="small"
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 1,
          '&:hover': { bgcolor: 'action.hover' },
        }}
        onClick={handleNext}
      >
        →
      </IconButton>
    </Box>
  );
};
