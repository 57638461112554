import { FormControl, Select, MenuItem, ListSubheader } from '@mui/material';

export function Dropdown({ name, value, list, onChange }) {
  return (
    <FormControl size="small" sx={{ minWidth: 120 }}>
      <Select displayEmpty fullWidth name={name} value={value || ''} onChange={onChange}>
        {list && list.length ? (
          list.map((obj) =>
            obj?.children ? (
              renderGroup(obj)
            ) : (
              <MenuItem key={obj.id} value={obj.id}>
                {obj.label}
              </MenuItem>
            )
          )
        ) : (
          <MenuItem value="">No Options</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

const renderGroup = (obj) => {
  const items = obj?.children.map(({ id, label }) => (
    <MenuItem key={id} value={id}>
      {label}
    </MenuItem>
  ));
  return [
    <ListSubheader
      key={obj.id}
      style={{
        lineHeight: 2,
        fontWeight: 'bold',
        fontSize: '14px',
        color: 'black',
      }}
    >
      {obj.label}
    </ListSubheader>,
    ...items,
  ];
};
