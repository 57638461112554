import { GanttStatic } from '@blackhyve/dhtmlx-gantt';
import { useEffect } from 'react';

// Define the structure of a single collection
export interface GanttCollection {
  ids: number[];
  entities: {
    [key: string]: {
      [key: string | number]: any;
    };
  };
}

// Define the collections object structure
export interface GanttCollections {
  [key: string]: GanttCollection;
}

// Extend the GanttStatic type to include collections and getCollectionEntity
declare module '@blackhyve/dhtmlx-gantt' {
  interface GanttStatic {
    collections: GanttCollections;
    getCollectionEntity(
      collectionName: string,
      entityId: string | number
    ): { [key: string | number]: any } | undefined;
  }
}

export const useGanttCollections = (
  gantt: GanttStatic | undefined,
  collections: GanttCollections,
  options = { skip: false }
) => {
  const { skip } = options;

  useEffect(() => {
    if (gantt) {
      gantt.collections = {};
      gantt.getCollectionEntity = function (
        collectionName: string,
        entityId: string | number
      ): { [key: string | number]: any } | undefined {
        if (Object.hasOwn(gantt.collections, collectionName)) {
          return gantt.collections[collectionName]?.entities?.[entityId];
        } else {
          return undefined;
        }
      };
    }
  }, [gantt]);

  useEffect(() => {
    if (gantt && !gantt.$destroyed && collections && !skip && true) {
      Object.entries(collections).forEach(([key, entries]) => {
        gantt.collections[key] = entries;
      });
    }
  }, [gantt, collections, skip]);
};
