import { GanttPlugins } from '@blackhyve/dhtmlx-gantt';

export const plugins: GanttPlugins = {
  auto_scheduling: false,
  multiselect: false,
  marker: true,
  tooltip: false,
  keyboard_navigation: false,
  grouping: true,
};
