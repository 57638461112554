import { DragIndicator } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Divider, LinearProgress } from '@mui/material';
import { useGetSectionQuery } from 'features/estimates/api/section.api';
import { Section as SectionModel } from 'features/estimates/types/section.models';
import { useMediaQuery } from 'usehooks-ts';
import { Metrics } from '../metrics/Metrics';
import { ProductLineTable } from '../productLines/ProductLineTable';
import { SectionActionMenu } from './SectionActionMenu';
import { SectionSkeleton } from './SectionSkeleton';

type SectionProps = {
  id: number;
  estimateId: number;
  hideSensitive?: boolean;
};

export const Section: React.FC<SectionProps> = ({ id, estimateId, hideSensitive = false }) => {
  const {
    data: section = {} as SectionModel,
    isLoading: isLoadingSection,
    isFetching: isFetchingSection,
  } = useGetSectionQuery({ id: Number(id), estimateId: estimateId! }, { skip: !id || !estimateId });

  const isPrint = useMediaQuery('print');

  return isLoadingSection && !isFetchingSection ? (
    <SectionSkeleton />
  ) : (
    <Card elevation={5}>
      {isFetchingSection && <LinearProgress />}
      <CardHeader
        action={!isPrint && <SectionActionMenu section={section} />}
        avatar={!isPrint && <DragIndicator />}
        title={section?.name}
        titleTypographyProps={{ variant: 'h5' }}
      />
      <CardContent>
        {section?.rollup && (
          <Metrics
            endDate={section?.end_date}
            hideSensitive={hideSensitive}
            notes={section.notes}
            rollup={section?.rollup}
            startDate={section?.start_date}
          />
        )}
        <Divider sx={{ marginY: 2 }} />
        {section.estimate_id && section.id && (
          <ProductLineTable
            estimateId={section.estimate_id}
            hideSensitive={hideSensitive}
            productLineItems={section.lines}
            sectionId={section.id}
          />
        )}
      </CardContent>
    </Card>
  );
};
