import { useState } from 'react';
import { ConfirmDeleteDialog, OptionsMenu } from '@blackhyve/common';
import { ProductFormDialog } from './ProductFormDialog';
import { Delete, Edit, MoreHoriz } from '@mui/icons-material';
import { useDeleteProductMutation } from '../api/products.api';
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

export const ProductActionMenu = ({ product }) => {
  const [deleteProduct] = useDeleteProductMutation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editingDialog, setEditingDialog] = useState(false);

  const handleDelete = async (id) => {
    deleteProduct({ id })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Product Deleted successfully', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Product can not be deleted', { variant: 'warning' });
      });
  };

  return (
    <>
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        triggerButton={
          <IconButton size="small">
            <MoreHoriz />
          </IconButton>
        }
      >
        {(handleClose) => {
          return (
            <>
              <ListItemButton
                dense
                onClick={() => {
                  handleClose();
                  setEditingDialog(true);
                }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </ListItemButton>
              <ListItemButton
                dense
                onClick={() => {
                  handleClose();
                  setOpenDeleteDialog(true);
                }}
              >
                <ListItemIcon style={{ color: 'red' }}>
                  <Delete color="error" />
                </ListItemIcon>
                <ListItemText style={{ color: 'red' }}>Delete</ListItemText>
              </ListItemButton>
            </>
          );
        }}
      </OptionsMenu>
      {editingDialog && (
        <ProductFormDialog
          handleClose={() => setEditingDialog(false)}
          open={editingDialog}
          product={product}
        />
      )}
      {openDeleteDialog && (
        <ConfirmDeleteDialog
          handleClose={() => setOpenDeleteDialog(false)}
          handleDelete={handleDelete}
          id={product.id}
          item={'Product'}
          open={openDeleteDialog}
          plural={false}
        />
      )}
    </>
  );
};
