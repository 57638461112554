import { Grid, MenuItem, Select } from '@mui/material';
import { FormLabel } from 'assets/style-components/typography';
import { WorkDaysComponent } from 'components/common/v3/WorkDaysComponent';
import { Controller, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';

const defaultValues = {
  workday_calendar: {
    friday: '1',
    monday: '1',
    sunday: '0',
    tuesday: '1',
    saturday: '0',
    thursday: '1',
    wednesday: '1',
  },
  lookahead_weeks: 6
};

export const ScheduleForm = ({ onSubmit, initialData, canEdit }) => {
  const { control, handleSubmit } = useForm({
    defaultValues,
    values: initialData,
  });

  const debouncedSaveFormState = useDebounceCallback(() => {
    handleSubmit(onSubmit)();
  });

  return (
    <form>
      <Grid container alignItems={'center'} spacing={2}>
        <Grid item md={3} xs={12}>
          <FormLabel>Lookahead Weeks</FormLabel>
          <Controller
            control={control}
            name="lookahead_weeks"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field: { value, onChange } }) => (
              <Select
                fullWidth
                size={'small'}
                value={value}
                variant={'outlined'}
                onChange={onChange}
              >
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={8}>8</MenuItem>
              </Select>
            )}
          />
        </Grid>
        <Grid item md={9} xs={12}>
          <FormLabel>WORK DAYS</FormLabel>
          <Controller
            control={control}
            name="workday_calendar"
            rules={{ onChange: debouncedSaveFormState }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <WorkDaysComponent disabled={!canEdit} workdayCalendar={value} onChange={onChange} />
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};
