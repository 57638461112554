import { OptionsMenu } from '@blackhyve/common';
import ConfirmDeleteDialog from 'components/common/popovers/ConfirmDeleteDialog';
import { Delete as DeleteIcon, Edit as EditIcon, MoreVert } from '@mui/icons-material';
import { IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Section } from 'features/estimates/types/section.models';
import { useState } from 'react';
import { SectionFormDialog } from './SectionFormDialog';
import { useDeleteSectionMutation } from 'features/estimates/api/section.api';

type EditDialogType = 'edit' | 'delete' | false;

export const SectionActionMenu = ({ section }: { section: Section }) => {
  const [openDialog, setOpenDialog] = useState<EditDialogType>(false);
  const [deleteSection, { isLoading }] = useDeleteSectionMutation();

  const handleDelete = () => {
    deleteSection({ id: section.id, estimateId: section.estimate_id }).then(() => {
      setOpenDialog(false);
    });
  };

  return (
    <>
      <OptionsMenu
        anchorOrigin={{
          vertical: 'middle',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'middle',
          horizontal: 'right',
        }}
        triggerButton={
          <IconButton size={'small'}>
            <MoreVert />
          </IconButton>
        }
      >
        {(handleClose) => (
          <List dense>
            <ListItemButton
              onClick={() => {
                setOpenDialog('edit');
                handleClose();
              }}
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                setOpenDialog('delete');
                handleClose();
              }}
            >
              <ListItemIcon>
                <DeleteIcon color={'error'} />
              </ListItemIcon>
              <ListItemText sx={{ color: 'error' }}>Delete</ListItemText>
            </ListItemButton>
          </List>
        )}
      </OptionsMenu>

      {openDialog === 'edit' && (
        <SectionFormDialog handleClose={() => setOpenDialog(false)} open={true} section={section} />
      )}
      {openDialog === 'delete' && (
        <ConfirmDeleteDialog
          handleClose={() => setOpenDialog(false)}
          handleDelete={handleDelete}
          id={section.id}
          isLoading={isLoading}
          item="Section"
          itemNames={undefined}
          open={true}
          plural={false}
        />
      )}
    </>
  );
};
