import { useEffect } from 'react';
import { UseFormWatch, UseFormSetValue } from 'react-hook-form';
import { ProductLineItem } from 'features/estimates/types/productLine.models';

export const useFormCalculations = (
  watch: UseFormWatch<ProductLineItem>,
  setValue: UseFormSetValue<ProductLineItem>
) => {
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      // Total Revenue calculation
      if (name === 'estimate_base' || name === 'rom_of_unit') {
        const total = (values.estimate_base || 0) * (values?.rom_of_unit || 0);
        setValue('revenue_total', total);
      }

      // Material calculations
      if (name === 'material_percent' || name === 'revenue_total') {
        const revenueMaterial =
          (values.revenue_total || 0) *
          (parseFloat(values.material_percent?.toString() || '0') / 100);
        setValue('revenue_material', Number(revenueMaterial.toFixed(2)));
      }

      if (name === 'revenue_material' || name === 'cogs_material_percent') {
        const cogsMaterial =
          (parseFloat(values.cogs_material_percent?.toString() || '0') / 100) *
          (values.revenue_material || 0);
        setValue('cogs_material', Number(cogsMaterial.toFixed(2)));
      }

      // Labor calculations
      if (name === 'labor_percent' || name === 'revenue_total') {
        const revenueLabor =
          (values.revenue_total || 0) * (parseFloat(values.labor_percent?.toString() || '0') / 100);
        setValue('revenue_labor', Number(revenueLabor.toFixed(2)));
      }

      if (name === 'revenue_labor' || name === 'cogs_labor_percent') {
        const cogsLabor =
          (parseFloat(values.cogs_labor_percent?.toString() || '0') / 100) *
          (values.revenue_labor || 0);
        setValue('cogs_labor', Number(cogsLabor.toFixed(2)));
      }

      if (name === 'cogs_labor' || name === 'cogs_per_hour') {
        const totalHours = (values?.cogs_labor || 0) / (values.cogs_per_hour || 0);
        setValue('labor_hours', Number(totalHours.toFixed(2)));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);
};
