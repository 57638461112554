import {
  Autocomplete,
  Box,
  Divider,
  FormLabel,
  Grid,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useGetProductsQuery } from 'features/products/api/products.api';
import { CURVE_OPTIONS, DISTRIBUTION_OPTIONS } from 'features/products/constants/curves';
import { TradesAutocomplete } from 'features/trades/components/TradesAutocomplete';
import { formatCurrency } from 'helpers/numberFormatter';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useFormCalculations } from './hooks/useFormCalculations';
import { ProductLineItem } from 'features/estimates/types/productLine.models';
import { Product } from 'features/products/types/product.models';

const formatInputPercent = (inputValue: string, options: any) => {
  options = { min: 0, max: Infinity, ...options };

  const min = parseInt(options.min);
  const max = parseInt(options.max);
  const value = parseFloat(inputValue);

  if (isNaN(value) || 2 < min) {
    return min.toFixed(0);
  }

  if (value > max) {
    return max.toFixed(0);
  }

  return `${value}`;
};

const FormSection = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <Box>
    <Typography gutterBottom variant="h6">
      {title}
    </Typography>
    <Grid container spacing={1}>
      {children}
    </Grid>
  </Box>
);

interface NonEditableValueProps {
  label: string;
  value: number | string;
  sm?: number;
  xs?: number;
}

const NonEditableValue: React.FC<NonEditableValueProps> = ({
  label,
  value,
  sm = true,
  xs = 12,
}) => {
  return (
    <Grid item sm={sm} xs={xs}>
      <Box bgcolor={'grey.200'} borderRadius={1} paddingX={2} paddingY={1}>
        <FormLabel sx={{ fontWeight: 'bold' }}>{label}: </FormLabel>
        <Typography color={'primary'} sx={{ fontWeight: 600 }}>
          {value !== undefined ? value : '-'}
        </Typography>
      </Box>
    </Grid>
  );
};

interface SectionProps {
  form: UseFormReturn<ProductLineItem>;
}

// Define the fields that can be prefilled as a const array
const PREFILL_FIELDS = [
  // 'division',
  // 'cost_code',
  // 'description',
  'trade_id',
  // 'percent',
  'labor_percent',
  'cogs_labor',
  'cogs_labor_percent',
  'labor_curve',
  'labor_curve_distribution',
  'material_percent',
  'cogs_material',
  'cogs_material_percent',
  'cogs_per_hour',
  'labor_hours',
  'material_curve',
  'material_curve_distribution',
] as const;

// Create a type from the array
type PrefillField = (typeof PREFILL_FIELDS)[number];

const BaseSection = ({ form }: SectionProps) => {
  const { data: products = [], isLoading: isLoadingProducts } = useGetProductsQuery();
  const { control, setValue, watch } = form;

  const state = watch();

  const prefillFromProduct = (product: Product) => {
    PREFILL_FIELDS.forEach((field: PrefillField) => {
      if (field in product) {
        setValue(field, product[field]);
      }
    });
  };

  return (
    <FormSection title="Base Information">
      <Grid item xs={12}>
        <FormLabel required>Product</FormLabel>
        <Controller
          control={control}
          name="name"
          rules={{ required: 'Product is required.' }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              autoHighlight
              disableClearable
              freeSolo
              groupBy={() => 'Products'}
              isOptionEqualToValue={(option, value) => option.id === value}
              loading={isLoadingProducts}
              options={products}
              size="small"
              getOptionLabel={(option) => {
                if (typeof option === 'string') return option;

                return (
                  products.find((product) => product?.division === option?.division)?.division ||
                  'Unknown'
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(error)}
                  helperText={error?.message}
                  id="name-input"
                  placeholder="Enter Product name"
                  variant="outlined"
                />
              )}
              renderOption={({ key, ...props }, option, { selected }) => (
                <li key={key} {...props}>
                  {option?.division} ({option?.cost_code}) - {option?.description}
                </li>
              )}
              onChange={(_event, newValue) => {
                if (typeof newValue !== 'string') {
                  prefillFromProduct(newValue);
                }
              }}
              onInputChange={(event, data) => {
                field.onChange(data);
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel required>Estimated Base</FormLabel>
        <Controller
          control={control}
          name="estimate_base"
          rules={{ required: 'Estimate Base is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              error={!!error}
              helperText={error?.message}
              size="small"
              type={'number'}
              InputProps={{
                endAdornment: <InputAdornment position="end">Sqft</InputAdornment>,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel required>Estimate Of Unit</FormLabel>
        <Controller
          control={control}
          name="rom_of_unit"
          rules={{ required: 'Estimate Of Unit is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              error={!!error}
              helperText={error?.message}
              size="small"
              type={'number'}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: <InputAdornment position="end">Sqft</InputAdornment>,
              }}
            />
          )}
        />
      </Grid>
      <NonEditableValue
        label={'Total Estimate'}
        value={state.revenue_total ? formatCurrency(state.revenue_total) : '-'}
      />
    </FormSection>
  );
};

const MaterialSection = ({ form }: SectionProps) => {
  const { control, watch } = form;
  const watchRevenueMaterial = watch('revenue_material');
  const watchCogsMaterial = watch('cogs_material');

  return (
    <FormSection title="Material Information">
      <Grid item xs={12}>
        <FormLabel required>Estimate Split</FormLabel>
        <Controller
          control={control}
          name="material_percent"
          rules={{ required: 'Estimate split is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              error={!!error}
              helperText={error?.message}
              inputProps={{ min: 0, max: 100 }}
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              placeholder={'How much is material'}
              size="small"
              type="number"
              onChange={(event) => {
                let value = event.target.value;
                value = formatInputPercent(value, {
                  min: event.target.getAttribute('min'),
                  max: event.target.getAttribute('max'),
                });
                field.onChange(value);
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel required>Cost %</FormLabel>
        <Controller
          control={control}
          name="cogs_material_percent"
          rules={{ required: 'Cost percent is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              error={!!error}
              helperText={error?.message}
              inputProps={{ min: 0, max: 100 }}
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              size="small"
              type="number"
              onChange={(event) => {
                let value = event.target.value;
                value = formatInputPercent(value, {
                  min: event.target.getAttribute('min'),
                  max: event.target.getAttribute('max'),
                });
                field.onChange(value);
              }}
            />
          )}
        />
      </Grid>
      <Grid container item sm={6} xs={12}>
        <FormLabel required>Curve</FormLabel>
        <Controller
          control={control}
          name="material_curve"
          rules={{ required: 'Curve field is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              select
              error={!!error}
              helperText={error?.message}
              inputProps={{ sx: { display: 'flex', alignItems: 'center' } }}
              size={'small'}
            >
              {CURVE_OPTIONS.map((curveOption) => (
                <MenuItem key={curveOption.id} value={curveOption.id}>
                  <ListItemIcon>{curveOption.icon}</ListItemIcon>
                  <ListItemText sx={{ m: 0 }}>{curveOption.name}</ListItemText>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid container item sm={6} xs={12}>
        <FormLabel required>Distribution</FormLabel>
        <Controller
          control={control}
          name="material_curve_distribution"
          rules={{ required: 'Material Distribution is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              select
              error={!!error}
              helperText={error?.message}
              inputProps={{ sx: { display: 'flex', alignItems: 'center' } }}
              size={'small'}
            >
              {DISTRIBUTION_OPTIONS.map((distributionOption) => (
                <MenuItem key={distributionOption.id} value={distributionOption.id}>
                  <ListItemIcon>{distributionOption.icon}</ListItemIcon>
                  <ListItemText sx={{ m: 0 }}>{distributionOption.name}</ListItemText>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <NonEditableValue
        label={'Material Estimate'}
        value={watchRevenueMaterial ? formatCurrency(watchRevenueMaterial) : '-'}
      />
      <NonEditableValue
        label={'Cost'}
        value={watchCogsMaterial ? formatCurrency(watchCogsMaterial) : '-'}
      />
    </FormSection>
  );
};

const LaborSection = ({ form }: SectionProps) => {
  const { control, watch } = form;

  const watchCogsLabor = watch('cogs_labor');
  const watchRevenueLabor = watch('revenue_labor');
  const watchLaborHours = watch('labor_hours');

  return (
    <FormSection title="Labor Information">
      <Grid item sm={8} xs={12}>
        <FormLabel required>Trade</FormLabel>
        <Controller
          control={control}
          name="trade_id"
          render={({ field, fieldState: { error } }) => (
            <TradesAutocomplete
              {...field}
              fullWidth
              disableCloseOnSelect={false}
              multiple={false}
              value={field.value?.toString() || null}
              TextFieldProps={{
                error: !!error,
                helperText: error?.message,
              }}
              onChange={(_event, newValue) => {
                field.onChange(newValue ?? null);
              }}
            />
          )}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <FormLabel required sx={{ textWrap: 'nowrap' }}>
          Cost Per Hour
        </FormLabel>
        <Controller
          control={control}
          name="cogs_per_hour"
          rules={{ required: 'Cost per hour field is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              error={!!error}
              helperText={error?.message}
              size="small"
              type={'number'}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel required>Estimate Split</FormLabel>
        <Controller
          control={control}
          name="labor_percent"
          rules={{ required: 'Labor Percent is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              error={!!error}
              helperText={error?.message}
              inputProps={{ min: 0, max: 100 }}
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              size="small"
              type="number"
              onChange={(event) => {
                let value = event.target.value;
                value = formatInputPercent(value, {
                  min: event.target.getAttribute('min'),
                  max: event.target.getAttribute('max'),
                });
                field.onChange(value);
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel required>Cost %</FormLabel>
        <Controller
          control={control}
          name="cogs_labor_percent"
          rules={{ required: 'Cost percent is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              error={!!error}
              helperText={error?.message}
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              inputProps={{ min: 0, max: 100 }}
              size="small"
              type="number"
              onChange={(event) => {
                let value = event.target.value;
                value = formatInputPercent(value, {
                  min: event.target.getAttribute('min'),
                  max: event.target.getAttribute('max'),
                });
                field.onChange(value);
              }}
            />
          )}
        />
      </Grid>

      <Grid container item sm={6} xs={12}>
        <FormLabel required>Curve</FormLabel>
        <Controller
          control={control}
          name="labor_curve"
          rules={{ required: 'Curve field is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              select
              inputProps={{ sx: { display: 'flex', alignItems: 'center' } }}
              size={'small'}
              {...field}
            >
              {CURVE_OPTIONS.map((curveOption) => (
                <MenuItem key={curveOption.id} value={curveOption.id}>
                  <ListItemIcon>{curveOption.icon}</ListItemIcon>
                  <ListItemText sx={{ m: 0 }}>{curveOption.name}</ListItemText>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid container item sm={6} xs={12}>
        <FormLabel required>Distribution</FormLabel>
        <Controller
          control={control}
          name="labor_curve_distribution"
          rules={{ required: 'Distribution is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              select
              inputProps={{ sx: { display: 'flex', alignItems: 'center' } }}
              size={'small'}
              {...field}
            >
              {DISTRIBUTION_OPTIONS.map((distributionOption) => (
                <MenuItem dense key={distributionOption.id} value={distributionOption.id}>
                  <ListItemIcon>{distributionOption.icon}</ListItemIcon>
                  <ListItemText sx={{ m: 0 }}>{distributionOption.name}</ListItemText>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid container item alignItems={'stretch'} spacing={1}>
        <NonEditableValue
          label="Labor Estimate"
          value={watchRevenueLabor ? formatCurrency(watchRevenueLabor) : '-'}
        />
        <NonEditableValue
          label="Cost"
          value={watchCogsLabor ? formatCurrency(watchCogsLabor) : '-'}
        />
        <NonEditableValue label="Total Hours" value={watchLaborHours || '-'} />
      </Grid>
    </FormSection>
  );
};

export const ProductLineForm = ({ onSubmit, form }: { onSubmit: any; form: any }) => {
  useFormCalculations(form.watch, form.setValue);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <BaseSection form={form} />
        <Divider />
        <MaterialSection form={form} />
        <Divider />
        <LaborSection form={form} />
      </Stack>
    </form>
  );
};
