import { keyframes } from '@mui/material';
import { PropertyMappedTask } from 'features/imports/hooks/useScheduleEntries';
import React, { useRef, useEffect } from 'react';
import { FixedSizeList } from 'react-window';

const flashAnimation = keyframes`
  0% { background-color: #ff9800; }
  100% { background-color: #fff3e0; }
`;

const formatDateTime = (value: string | Date): string => {
  if (value instanceof Date) {
    return value.toLocaleDateString();
  }
  return value;
};

interface TaskItemProps {
  tasks: PropertyMappedTask[];
  selection: number[];
  onToggleSelection: (taskId: number, event: React.MouseEvent) => void;
  scrollToTaskId?: number;
}

const ITEM_HEIGHT = 36;

const TaskRow = ({
  index,
  style,
  data,
}: {
  index: number;
  style: React.CSSProperties;
  data: {
    tasks: PropertyMappedTask[];
    selection: number[];
    onToggleSelection: Function;
    highlightedTaskId: number;
  };
}) => {
  const { tasks, selection, onToggleSelection } = data;
  const task = tasks[index];
  const nextTask = tasks[index + 1];
  const isParent = nextTask?.indent_level === task.indent_level + 1;
  const isSelected = selection.includes(task.id);
  const isHighlighted = task.id === data.highlightedTaskId;

  return (
    <label
      style={{
        ...style,
        userSelect: 'none',
        height: ITEM_HEIGHT,
        boxSizing: 'border-box',
        padding: '0 16px',
        paddingLeft: `${task.indent_level * 24 + 16}px`,
        borderBottom: '1px solid #ddd',
        display: 'flex',
        alignItems: 'center',
        animation: isHighlighted ? `${flashAnimation} 1s ease-out forwards` : 'none',
        backgroundColor: isHighlighted ? '#fff3e0' : 'inherit',
      }}
    >
      <input
        checked={isSelected}
        style={{ marginRight: '12px' }}
        type="checkbox"
        onChange={(e) => onToggleSelection(task.id, e)}
      />
      <span
        style={{
          flex: 1,
          fontWeight: isParent ? 600 : 400,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {task.identifier} {task.name}
      </span>
      <div style={{ display: 'flex', gap: '48px', flexShrink: 0 }}>
        <span style={{ color: '#666', minWidth: '80px', flex: 1, textAlign: 'right' }}>
          {formatDateTime(task.scheduled_start)}
        </span>
        <span style={{ color: '#666', minWidth: '80px', flex: 1, textAlign: 'right' }}>
          {formatDateTime(task.scheduled_end)}
        </span>
      </div>
    </label>
  );
};

export function WBSTaskIndentor({
  tasks,
  selection,
  onToggleSelection,
  scrollToTaskId,
}: TaskItemProps) {
  const listRef = useRef<FixedSizeList>(null);

  useEffect(() => {
    if (scrollToTaskId && listRef.current) {
      const taskIndex = tasks.findIndex((task) => task.id === scrollToTaskId);
      if (taskIndex !== -1) {
        listRef.current.scrollToItem(taskIndex, 'center');
      }
    }
  }, [scrollToTaskId, tasks]);

  return (
    <>
      <div style={{ border: '1px solid #ddd' }}>
        <div
          style={{
            padding: '12px 16px',
            borderBottom: '1px solid #ddd',
            backgroundColor: '#f5f5f5',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ fontWeight: 600 }}>Task Name</span>
          <div style={{ display: 'flex', gap: '48px' }}>
            <span style={{ fontWeight: 600 }}>Start Date</span>
            <span style={{ fontWeight: 600 }}>End Date</span>
          </div>
        </div>

        <FixedSizeList
          height={800}
          itemCount={tasks.length}
          itemData={{ tasks, selection, onToggleSelection, highlightedTaskId: scrollToTaskId }}
          itemSize={ITEM_HEIGHT}
          overscanCount={20}
          ref={listRef}
          width="100%"
        >
          {TaskRow}
        </FixedSizeList>
      </div>
    </>
  );
}
