import { useState } from 'react';
import { Popover } from 'components/Popover';
import IconButton from '@mui/material/IconButton';
import { ConfirmDeleteDialog } from '@blackhyve/common';
import { theme } from '@blackhyve/common/src/AppTheme';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { ResourceRequest } from '../api/resources.models';
import { ResourceRequestDialog } from './ResourceRequestDialog';
import {
  useDeleteResourceRequestMutation,
  useUpdateResourceRequestMutation,
} from '../api/resourceRequest.api';
import { ListItemButton, ListItemIcon, ListItemText, MenuList, ThemeProvider } from '@mui/material';
import { format } from 'date-fns';

export function ResourceRequestMoreOptions({
  resourceRequest,
}: {
  resourceRequest: ResourceRequest;
}) {
  const [deleteResourceRequest] = useDeleteResourceRequestMutation();
  const [updateResourceRequest, { isLoading }] = useUpdateResourceRequestMutation();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

  const handleDelete = () => {
    deleteResourceRequest(Number(resourceRequest.id));
  };

  const handleUpdate = (data) => {
    const requestPayload = {
      ...data,
      id: resourceRequest.id,
      start_date: format(data.start_date, 'yyyy-MM-dd'),
      end_date: format(data.end_date, 'yyyy-MM-dd'),
    };
    updateResourceRequest(requestPayload).then(() => {
      setEditDialogOpen(false);
    });
  };

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        trigger={
          <IconButton edge="end" size={'small'} sx={{ color: 'inherit' }}>
            <MoreVert />
          </IconButton>
        }
      >
        {({ handleClose }) => {
          return (
            <MenuList>
              <ListItemButton
                onClick={() => {
                  setEditDialogOpen(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={'Edit'} />
              </ListItemButton>
              {!resourceRequest?.assignments?.length ? (
                <ListItemButton
                  onClick={() => {
                    setConfirmDeleteDialogOpen(true);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Delete color={'error'} />
                  </ListItemIcon>
                  <ListItemText color={'error'} primary={'Delete'} />
                </ListItemButton>
              ) : (
                ''
              )}
            </MenuList>
          );
        }}
      </Popover>
      <ThemeProvider theme={theme}>
        <ConfirmDeleteDialog
          handleClose={() => setConfirmDeleteDialogOpen(false)}
          handleDelete={handleDelete}
          id={resourceRequest?.id}
          item={'resource'}
          open={confirmDeleteDialogOpen}
          plural={false}
        />
        <ResourceRequestDialog
          handleClose={() => setEditDialogOpen(false)}
          isLoading={isLoading}
          open={editDialogOpen}
          resourceRequest={resourceRequest}
          onSubmit={handleUpdate}
        />
      </ThemeProvider>
    </>
  );
}
