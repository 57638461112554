import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';

export const ProductLineTableSkeleton = ({
  numberOfRows = 5,
  numberOfColumns = 6,
  headerGroups = 3,
}) => {
  return (
    <>
      {/* Header Skeleton */}
      <Skeleton height={48} sx={{ mb: 2 }} variant="rectangular" width="100%" />

      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            {/* Column Group Headers */}
            <TableRow>
              {[...Array(headerGroups)].map((_, index) => (
                <TableCell
                  colSpan={Math.ceil(numberOfColumns / headerGroups)}
                  key={`group-${index}`}
                >
                  <Skeleton height={24} variant="text" width="80%" />
                </TableCell>
              ))}
            </TableRow>

            {/* Column Headers */}
            <TableRow>
              {[...Array(numberOfColumns)].map((_, index) => (
                <TableCell key={`header-${index}`}>
                  <Skeleton height={20} variant="text" width="90%" />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {[...Array(numberOfRows)].map((_, rowIndex) => (
              <TableRow hover key={`row-${rowIndex}`}>
                {[...Array(numberOfColumns)].map((_, colIndex) => (
                  <TableCell key={`cell-${rowIndex}-${colIndex}`}>
                    <Skeleton height={20} variant="text" width={colIndex === 0 ? '60%' : '80%'} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
