import Popover from '@mui/material/Popover';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import TagDropdown from 'features/tags/components/TagDropdown';
import isEqual from 'lodash/isEqual';
import { createRef, forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { useSyncTagsOnRecipeTaskMutation } from '../api/recipeTask.api';

export const loadRecipeTagsMenuType = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  let tagEditorRef;
  gantt.config.editor_types.tags = {
    show: function (id, column, config, placeholder) {
      const task = gantt.getTask(id);
      tagEditorRef = createRef();
      gantt.config.external_render.renderElement(
        <TagMenuPopover
          anchorEl={placeholder}
          ganttId={ganttId}
          initialSelected={task?.tags}
          key={`${task.id}-tags-menu`}
          ref={tagEditorRef}
          onClose={() => gantt.ext.inlineEditors.save()}
        />,
        placeholder
      );
    },
    hide: function () {
      // called when input is hidden
      // destroy any complex editors or detach event listeners from here
    },
    set_value: function (value, id, column, node) {
      if (tagEditorRef.current) {
        tagEditorRef?.current?.setValue(new Set(value || []));
      }
    },
    get_value: function (id, column, node) {
      return tagEditorRef?.current?.getValue();
    },
    is_changed: function (value, id, column, node) {
      const currentValue = this.get_value() || [];
      const oldValue = value || [];
      if (currentValue.length !== oldValue.length) {
        return true;
      }
      currentValue?.slice().sort();
      oldValue?.slice().sort();
      return !currentValue.every((element, index) => element === oldValue[index]);
    },
    is_valid: function (value, id, column, node) {
      return Array.isArray(this.get_value());
    },
    focus: function (node) {},
    save: function (id, column, node) {
      const tags = this.get_value(id, column, node);
      const task = gantt.getTask(id);
      task.tags = tags;
      gantt.refreshTask(task.id);
      tagEditorRef?.current?.saveTags(id, tags);
    },
  };
};

const TagMenuPopover = forwardRef(({ initialSelected, anchorEl, onClose, ganttId }, ref) => {
  const [open, setOpen] = useState(true);
  const gantt = ganttStore.getGantt(ganttId);
  const [tags, setTags] = useState(initialSelected || []);
  const filteredTags = useMemo(() => tags?.filter((tag) => tag?.type !== 'generated'), [tags]);

  const handleClose = () => {
    setOpen(false);
    onClose(tags);
  };

  const [syncTagsOnRecipeTask] = useSyncTagsOnRecipeTaskMutation();

  const handleSaveTags = (id, updatedTags) => {
    if (isEqual(updatedTags, initialSelected)) {
      return;
    }

    syncTagsOnRecipeTask({ taskId: id, tags: updatedTags?.map((tag) => tag.id) || [] })
      .unwrap()
      .then((data) => {
        gantt.batchUpdate(() => {
          data?.forEach(({ id, tags }) => {
            if (gantt.isTaskExists(id)) {
              const task = gantt.getTask(id);
              task.tags = tags;
              gantt.refreshTask(id);
            }
          });
        });
      });
  };

  useImperativeHandle(ref, () => ({
    getValue: () => [...tags],
    setValue: setTags,
    saveTags: handleSaveTags,
  }));

  return (
    <>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          open={open}
          slotProps={{
            paper: {
              sx: {
                width: 400,
                overflowY: 'auto',
                borderRadius: 1,
                padding: '10px 10px',
              },
            },
          }}
          onClose={handleClose}
        >
          <TagDropdown selectedTags={filteredTags} setSelectedTags={setTags} />
        </Popover>
      )}
    </>
  );
});
