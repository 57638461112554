import { GanttStatic } from '@blackhyve/dhtmlx-gantt';
import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type GanttPortalProps = {
  gantt: GanttStatic | undefined;
  containerId: string;
  children: ReactNode;
};

export const GanttPortal = ({ gantt, containerId, children }: GanttPortalProps) => {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!gantt?.destroyed) {
      const eventId = `get-${containerId}`;

      gantt?.attachEvent(
        'onGanttRender',
        () => {
          setContainer(document.getElementById(containerId));
        },
        { id: eventId }
      );

      return () => {
        gantt?.detachEvent(eventId);
      };
    }
  }, [containerId, gantt]);

  return container ? createPortal(children, container) : null;
};
