import { SvgIcon, SvgIconProps } from '@mui/material';

export const FlatDistribution = (props: SvgIconProps) => {
  return (
    <SvgIcon
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path fill={'none'} d="M2 12 L22 12" />
    </SvgIcon>
  );
};
