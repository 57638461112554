import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

export const SectionLayout = ({ sections, header }) => {
  const sectionRefs = useRef({});
  const [activeSections, setActiveSections] = useState({ 'details-tab': true });

  const handleScrollToTab = (sectionId) => {
    const element = sectionRefs.current[sectionId];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const activeSectionId = sections.find(({ id }) => activeSections[id])?.id;

  return (
    <Stack direction={'row'}>
      <Box display={{ xs: 'none', md: 'block' }} flexGrow={1} flexShrink={0}>
        <Box position={'sticky'} top={0}>
          <List>
            {sections.map((section) => {
              const isActive = activeSectionId === section.id;
              return (
                <ListItem
                  disablePadding
                  key={section.id}
                  sx={{ borderRightColor: isActive ? 'primary' : 'none' }}
                >
                  <ListItemButton selected={isActive} onClick={() => handleScrollToTab(section.id)}>
                    <ListItemIcon>{section.icon}</ListItemIcon>
                    <ListItemText
                      primary={section.name}
                      sx={{
                        '& .MuiTypography-root': {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
      <Box flex display={'flex'} flexDirection={'column'} overflow={'hidden'} paddingX={1} gap={2}>
        {header}
        {sections.map((section) => (
          <Grid
            item
            id={section.id}
            key={section.id}
            ref={(el) => (sectionRefs.current[section.id] = el)}
            xs={12}
          >
            <ActiveSectionObserver id={section.id} setActiveSections={setActiveSections}>
              {section.component}
            </ActiveSectionObserver>
          </Grid>
        ))}
      </Box>
    </Stack>
  );
};

const ActiveSectionObserver = ({ id, setActiveSections, children }) => {
  const { ref } = useIntersectionObserver({
    threshold: 0.75,
    onChange: (isIntersecting) => {
      setActiveSections((prev) => ({ ...prev, [id]: isIntersecting }));
    },
  });

  return (
    <div ref={ref}>
      {/* <div ref={ref} /> */}
      {children}
    </div>
  );
};
