import { useEffect, useCallback } from 'react';

type PrintCallback = (event: MediaQueryListEvent) => void;

export const useOnPrint = (callback: PrintCallback) => {
  const memoizedCallback = useCallback(callback, [callback]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('print');

    mediaQuery.addEventListener('change', memoizedCallback);
    return () => {
      mediaQuery.removeEventListener('change', memoizedCallback);
    };
  }, [memoizedCallback]);
};
