import { api, providesList } from 'api';
import { TASK_TAG } from 'features/tasks/store/task.api';

export const TARGET_CACHE_TYPE = 'target';
export const WEEKLY_WORK_PLAN_CACHE_TYPE = 'weeklyWorkPlan';

export const targetsAPI = api
  .enhanceEndpoints({ addTagTypes: [TARGET_CACHE_TYPE, WEEKLY_WORK_PLAN_CACHE_TYPE] })
  .injectEndpoints({
    endpoints: (build) => ({
      getWeeklyWorkPlan: build.query({
        query: ({ date, projectId, parentId = 0 } = {}) => ({
          url: `projects/${projectId}/wwp/published`,
          params: { date, parent_id: parentId },
        }),
        providesTags: (result, error, args) =>
          result
            ? [
                { type: WEEKLY_WORK_PLAN_CACHE_TYPE, id: result.id },
                ...providesList(result.targets, TARGET_CACHE_TYPE),
                ...providesList(
                  result?.targets?.map(({ task }) => task),
                  TASK_TAG
                ),
              ]
            : [],
        transformResponse: (response) => response?.data,
      }),
      getForecastedWeeklyWorkPlan: build.query({
        query: ({ date, projectId, parentId = 0 } = {}) => ({
          url: `projects/${projectId}/wwp/forecasted`,
          params: { date, parent_id: parentId },
        }),
        providesTags: (result, error, args) =>
          result
            ? [
                { type: WEEKLY_WORK_PLAN_CACHE_TYPE, id: result.id },
                ...providesList(result.targets, TARGET_CACHE_TYPE),
                ...providesList(
                  result?.targets?.map(({ task }) => task),
                  TASK_TAG
                ),
              ]
            : [],
        transformResponse: (response) => response?.data,
      }),
      getUnpublishedWeeklyWorkPlan: build.query({
        query: ({ date, projectId, parentId = 0 } = {}) => ({
          url: `projects/${projectId}/wwp/unpublished`,
          params: { date, parent_id: parentId },
        }),
        providesTags: (result, error, args) =>
          result
            ? [
                { type: WEEKLY_WORK_PLAN_CACHE_TYPE, id: result.id },
                ...providesList(result.targets, TARGET_CACHE_TYPE),
                ...providesList(
                  result?.targets?.map(({ task }) => task),
                  TASK_TAG
                ),
              ]
            : [],
        transformResponse: (response) => response?.data,
      }),
      getWeeklyWorkPlanPreview: build.query({
        query: ({ date, parentId, projectId }) => ({
          url: `projects/${projectId}/wwp`,
          params: { date, parent_id: parentId },
        }),
        providesTags: (result, error, args) =>
          result
            ? [
                { type: WEEKLY_WORK_PLAN_CACHE_TYPE, id: result.id },
                ...providesList(result.targets, TARGET_CACHE_TYPE),
                ...providesList(
                  result?.targets?.map(({ task }) => task),
                  TASK_TAG
                ),
              ]
            : [],
        transformResponse: (response) => response?.data,
      }),
      publishWeeklyWorkPlan: build.mutation({
        query: ({ date, projectId, parentId = 0, targets, message } = {}) => ({
          url: `projects/${projectId}/wwp/publish`,
          body: { targets, message },
          method: 'post',
          params: { date, parent_id: parentId },
        }),
        invalidatesTags: (result, error, args) =>
          result
            ? [
                { type: WEEKLY_WORK_PLAN_CACHE_TYPE, id: result.id },
                { type: TARGET_CACHE_TYPE, id: 'LIST' },
              ]
            : [],
      }),
      getTarget: build.query({
        query: ({ targetId }) => ({
          url: `/targets/${targetId}`,
        }),
        transformResponse: (response) => response?.data,
        providesTags: (result, error, args) => [
          { type: TARGET_CACHE_TYPE, id: result.id },
          { type: TASK_TAG, id: result.task.id },
        ],
      }),
      updateTarget: build.mutation({
        query: ({ targetId, target }) => ({
          url: `/targets/${targetId}`,
          method: 'PATCH',
          body: target,
        }),
        invalidatesTags: (result, error, args) =>
          result ? [{ type: TARGET_CACHE_TYPE, id: args.targetId }] : [],
      }),
      deleteTarget: build.mutation({
        query: ({ targetId }) => ({
          url: `/targets/${targetId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, args) =>
          result ? [{ type: TARGET_CACHE_TYPE, id: args.targetId }] : [],
      }),
    }),
  });

export const {
  useGetWeeklyWorkPlanQuery,
  useGetForecastedWeeklyWorkPlanQuery,
  useGetUnpublishedWeeklyWorkPlanQuery,
  useGetWeeklyWorkPlanPreviewQuery,
  usePublishWeeklyWorkPlanMutation,
  useGetTargetQuery,
  useUpdateTargetMutation,
  useDeleteTargetMutation,
} = targetsAPI;
