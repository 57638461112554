import { RouteObject, Navigate } from 'react-router-dom';
import { Estimates } from './Estimates';
import { EstimateDetails } from './EstimateDetails';

export const estimateRoutes: RouteObject[] = [
  {
    path: '',
    children: [
      { index: true, element: <Estimates /> },
      { path: ':estimateId', element: <EstimateDetails /> },
    ],
  },
  { path: '*', element: <Navigate to={'.'} /> },
];
