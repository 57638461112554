import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as serviceWorker from './serviceWorker';
import store from './store';

import { injectStore as injectApiErrorHandlerStore } from 'helpers/apiErrorHandler';
import { injectStore as injectApiHelperStore } from 'helpers/apiHelper';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import { PostHogProvider } from 'posthog-js/react';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const persistor = persistStore(store);
injectApiErrorHandlerStore(store);
injectApiHelperStore(store);

const AppContainer = document.getElementById('root');
const root = ReactDOM.createRoot(AppContainer);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
        <App />
      </PostHogProvider>
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
