import { useCallback, useMemo, useState } from 'react';

// Type for a single filter configuration
type FilterDef<T> = {
  defaultValue: T;
  matcher?: (value: T, item: any, extra: any) => boolean;
};

// Type for the complete filter configuration
export type FiltersConfig<TFilters> = {
  [K in keyof TFilters]: FilterDef<TFilters[K]>;
};

export function useFilters<TFilters>(config: FiltersConfig<TFilters>) {
  // Create default filters from config
  const defaultFilters = useMemo(() => {
    const defaults = {} as TFilters;
    for (const key in config) {
      defaults[key] = config[key].defaultValue;
    }
    return defaults;
  }, [config]);

  // Initialize state with defaults
  const [filters, setFilters] = useState<TFilters>(defaultFilters);

  // Create type-safe handler creator
  const createHandler = useCallback(<K extends keyof TFilters>(key: K) => {
    return (value: TFilters[K]) => {
      setFilters((prev) => ({ ...prev, [key]: value }));
    };
  }, []);

  // Create handlers for each filter
  const handlers = useMemo(() => {
    const result = {} as {
      [K in keyof TFilters as `handle${Capitalize<string & K>}Change`]: (
        value: TFilters[K]
      ) => void;
    };

    for (const key in config) {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      result[`handle${capitalizedKey}Change`] = createHandler(key);
    }

    return result;
  }, [createHandler, config]);

  // Create shouldShow function
  const shouldShow = useCallback(
    (item: any, extra): boolean => {
      for (const key in filters) {
        const matcher = config[key].matcher;
        if (matcher && !matcher(filters[key], item, extra)) {
          return false;
        }
      }
      return true;
    },
    [filters, config]
  );

  return {
    filters,
    ...handlers,
    shouldShow,
  };
}
