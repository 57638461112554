import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Alert, Grid } from '@mui/material';
import {
  uploadFileToS3,
  useCreateImportMutation,
  useUpdateImportMutation,
} from '../api/imports.api';
import { ByImportType, ImportStatus, ImportType } from '../api/imports.models';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

export function ImportDropBox({ projectId }: { projectId: number }) {
  const [files, setFiles] = useState<ByImportType<File | null>>({
    [ImportType.Schedule]: null,
    [ImportType.LaborEstimate]: null,
  });
  const [dragActive, setDragActive] = useState<ByImportType<boolean>>({
    [ImportType.Schedule]: false,
    [ImportType.LaborEstimate]: false,
  });
  const [error, setError] = useState<ByImportType<string | null>>({
    [ImportType.Schedule]: null,
    [ImportType.LaborEstimate]: null,
  });
  const [uploading, setUploading] = useState<ByImportType<boolean>>({
    [ImportType.Schedule]: false,
    [ImportType.LaborEstimate]: false,
  });
  const [uploadStatus, setUploadStatus] = useState<ByImportType<ImportStatus | null>>({
    [ImportType.Schedule]: null,
    [ImportType.LaborEstimate]: null,
  });
  const [createImport] = useCreateImportMutation();
  const [updateImport] = useUpdateImportMutation();

  const handleFileUpload = async (file: File, type: ImportType) => {
    let backendImportId: number | null = null;

    try {
      setUploadStatus((prev) => ({ ...prev, [type]: ImportStatus.UploadProcessing }));
      // Create import and get presigned url
      const name = file.name;
      const { url, imported } = await handleCreateImport({ projectId, name, type });

      // Upload the file to S3
      await uploadFileToS3(url, file);

      // Update backend status to success
      await handleUpdateImport(imported.id, ImportStatus.UploadSucceeded);
      setUploadStatus((prev) => ({ ...prev, [type]: ImportStatus.UploadSucceeded }));
    } catch (error) {
      // If there's an error, update the backend status to failed
      if (backendImportId) {
        await handleUpdateImport(backendImportId, ImportStatus.UploadFailed);
      }
      setError((prev) => ({
        ...prev,
        [type]: 'Upload failed. Please try again.',
      }));
      throw error;
    }
  };

  const handleCreateImport = async ({
    projectId,
    type,
    name,
  }: {
    projectId: number;
    type: ImportType;
    name: string;
  }): Promise<{ url: string; imported: { id: number } }> => {
    const response = await createImport({ projectId, type, name }).unwrap();
    if (!response) {
      throw new Error('Failed to create import. No response received.');
    }

    const { url, data: imported } = response;
    if (!url || !imported) {
      throw new Error('Missing required data for import creation.');
    }

    return { url, imported };
  };

  const handleUpdateImport = async (backendImportId: number, status: ImportStatus) => {
    try {
      await updateImport({ backendImportId, updateData: { status } }).unwrap();
    } catch (error) {
      console.error('Failed to update import status:', error);
      throw error;
    }
  };

  const handleDrag = (e, type: ImportType, dragType: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (dragType === 'dragenter' || dragType === 'dragover') {
      setDragActive((prev) => ({ ...prev, [type]: true }));
    } else if (dragType === 'dragleave') {
      setDragActive((prev) => ({ ...prev, [type]: false }));
    }
  };

  async function handlePerformUpload(file: File, type: ImportType) {
    setFiles((prev) => ({ ...prev, [type]: file }));
    setUploading((prev) => ({ ...prev, [type]: true }));
    try {
      await handleFileUpload(file, type);
    } catch (error) {
      console.error('File upload failed:', error);
    } finally {
      setUploading((prev) => ({ ...prev, [type]: false }));
    }
  }

  const handleDrop = async (e, type: ImportType) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive((prev) => ({ ...prev, [type]: false }));

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const [file] = e.dataTransfer.files;
      await handlePerformUpload(file, type);
    }
  };

  const handleChange = async (e, type: ImportType) => {
    if (e.target.files && e.target.files[0]) {
      const [file] = e.target.files;
      await handlePerformUpload(file, type);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <DropZone
          acceptedFileType=".pdf"
          dragActive={dragActive[ImportType.Schedule]}
          error={error[ImportType.Schedule]}
          file={files[ImportType.Schedule]}
          type={ImportType.Schedule}
          onChange={(e) => handleChange(e, ImportType.Schedule)}
          onDrag={(e, dragType) => handleDrag(e, ImportType.Schedule, dragType)}
          onDrop={(e) => handleDrop(e, ImportType.Schedule)}
        >
          <CalendarMonthOutlinedIcon sx={{ fontSize: 48, color: 'grey.400' }} />
          <Typography
            variant="subtitle1"
            sx={{
              mt: 2,
            }}
          >
            <strong>Upload Schedule</strong>
            {!files[ImportType.Schedule] && ' or drag & drop'}
          </Typography>
          {!files[ImportType.Schedule] && (
            <Typography sx={{ mt: 1, color: 'text.secondary' }} variant="body2">
              Extract schedule data from a PDF document
            </Typography>
          )}
          {uploading[ImportType.Schedule] && (
            <Box sx={{ mt: 2 }}>
              <CircularProgress size={24} />
              <Typography sx={{ mt: 1 }} variant="body2">
                {uploadStatus[ImportType.Schedule] === ImportStatus.UploadProcessing
                  ? 'Processing...'
                  : 'Uploading...'}
              </Typography>
            </Box>
          )}
        </DropZone>
      </Grid>

      <Grid item md={6}>
        <DropZone
          acceptedFileType=".csv"
          dragActive={dragActive[ImportType.LaborEstimate]}
          error={error[ImportType.LaborEstimate]}
          file={files[ImportType.LaborEstimate]}
          type={ImportType.LaborEstimate}
          onChange={(e) => handleChange(e, ImportType.LaborEstimate)}
          onDrag={(e, dragType) => handleDrag(e, ImportType.LaborEstimate, dragType)}
          onDrop={(e) => handleDrop(e, ImportType.LaborEstimate)}
        >
          <DescriptionOutlinedIcon sx={{ fontSize: 48, color: 'grey.400' }} />
          <Typography
            variant="subtitle1"
            sx={{
              mt: 2,
            }}
          >
            <strong>Upload Labor Estimates</strong>
            {!files[ImportType.LaborEstimate] && ' or drag & drop'}
          </Typography>
          {!files[ImportType.LaborEstimate] && (
            <Typography sx={{ mt: 1, color: 'text.secondary' }} variant="body2">
              Extract labor data from Quick Bid&reg;
            </Typography>
          )}
          {uploading[ImportType.LaborEstimate] && (
            <Box sx={{ mt: 2 }}>
              <CircularProgress size={24} />
              <Typography sx={{ mt: 1 }} variant="body2">
                {uploadStatus[ImportType.LaborEstimate] === ImportStatus.UploadProcessing
                  ? 'Processing...'
                  : 'Uploading...'}
              </Typography>
            </Box>
          )}
        </DropZone>
      </Grid>
    </Grid>
  );
}

interface DropZoneProps {
  type: ImportType;
  acceptedFileType: string;
  children: React.ReactNode;
  dragActive: boolean;
  file: File | null;
  error: string | null;
  onDrag: (e, dragType: string) => void;
  onDrop: (e) => void;
  onChange: (e) => void;
}

function DropZone({
  acceptedFileType,
  children,
  dragActive,
  file,
  error,
  onDrag,
  onDrop,
  onChange,
}: DropZoneProps) {
  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          p: 3,
          borderRadius: 1,
          border: '2px dashed',
          borderColor: dragActive ? 'primary.main' : 'grey.300',
          bgcolor: dragActive ? 'primary.50' : file ? 'success.50' : 'background.paper',
          transition: 'all 0.2s ease',
          '&:hover': {
            bgcolor: file ? 'success.50' : 'grey.50',
          },
        }}
        onDragEnter={(e) => onDrag(e, 'dragenter')}
        onDragLeave={(e) => onDrag(e, 'dragleave')}
        onDragOver={(e) => onDrag(e, 'dragover')}
        onDrop={(e) => onDrop(e)}
      >
        <input
          accept={acceptedFileType}
          type="file"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            cursor: 'pointer',
          }}
          onChange={onChange}
        />
        <Box sx={{ textAlign: 'center' }}>
          {children}
          {file && (
            <Typography sx={{ mt: 1, color: 'success.main' }} variant="body2">
              ✓ {file.name}
            </Typography>
          )}
        </Box>
      </Box>
      {error && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
}
