import format from 'date-fns/format';
import { drawFloatLayer } from './float';
import { addBaselineLayer } from './baseline';
import { addWeekdaysLayer } from 'features/gantt/layers/addWeekdaysLayer';
import { drawCompanyColorLayer } from 'features/tasksGantt/utils/gantt/taskGanttLayers';
import { addForecastedDatesLayer } from 'features/gantt/layers/addForecastedDatesLayer';
import {
  isBefore, isAfter,
  addYears, addDays, subDays,
  endOfYear, endOfQuarter, endOfMonth, endOfWeek, endOfDay,
  startOfDay, startOfMonth, startOfYear, startOfQuarter, startOfWeek,
  eachMonthOfInterval,
  differenceInDays, differenceInMonths, differenceInQuarters, differenceInWeeks, differenceInYears,
  addWeeks,
} from 'date-fns';

const mmToPixel = 96 / 25.4;

export const formatsWidth = {
  portrait: {
    A3: 297 * mmToPixel,
    A4: 210 * mmToPixel,
    A5: 148 * mmToPixel,
    Legal: 216 * mmToPixel,
    Letter: 210 * mmToPixel,
    Tabloid: 279 * mmToPixel
  },
  landscape: {
    A3: 420 * mmToPixel,
    A4: 297 * mmToPixel,
    A5: 210 * mmToPixel,
    Legal: 356 * mmToPixel,
    Letter: 279 * mmToPixel,
    Tabloid: 432 * mmToPixel,
  }
}


export const initializeCustomLayers = ({ ganttId }) => {
  addWeekdaysLayer(ganttId);
  drawFloatLayer(ganttId);
  drawCompanyColorLayer(ganttId);
  addForecastedDatesLayer(ganttId);
  addBaselineLayer(ganttId);
};

/**
 * Get timescale config
 * @param {string} layout 
 * @param {string} pageFormat
 * @param {gantt} gantt
 * @returns 
 */
export const getTimescaleConfig = ({ layout, pageFormat, gantt }) => {
  let pageWidth = formatsWidth[layout][pageFormat];
  // Subtracting margins
  pageWidth -= 103.2

  const visibleColumns = gantt.config.columns.filter((column) => !column.hide && column.name !== "action");
  const gridWidth = visibleColumns.reduce((sum, col) => parseInt(sum + col.width), 0)

  const calculateTotalCells = (zoomLevel) => {
    if (zoomLevel === -1) return 0;

    const startDate = new Date(gantt.config.start_date);
    const endDate = new Date(gantt.config.end_date);

    switch (zoomLevel) {
      case 0: // Years
        return differenceInYears(addYears(endOfYear(endDate), 1), startOfYear(startDate))
      case 1: // Quarters
        const startQuarter = startOfQuarter(startDate)
        const endQuarter = addDays(endOfQuarter(endDate), 1)
        return differenceInQuarters(endQuarter, startQuarter);
      case 2: // Months
        const interval = {
          start: startOfMonth(startDate),
          end: endOfMonth(endDate)
        };
        return eachMonthOfInterval(interval)?.length
      case 3:  // Weeks
        return differenceInWeeks(endOfWeek(endDate, { weekStartsOn: 1 }),
          startOfWeek(startDate, { weekStartsOn: 1 }), { roundingMethod: 'ceil' });;
      case 4: // Days
        return differenceInDays(endOfDay(endDate), startOfDay(startDate));
      default:
        return differenceInDays(endDate, startDate)
    }
  }

  let zoomLevel = gantt?.ext?.zoom.getCurrentLevel();
  let totalCells = calculateTotalCells(zoomLevel);
  const remainingWidth = (pageWidth - gridWidth);
  let columnWidth = (remainingWidth / totalCells);

  // console.log('remainingWidth  =>', remainingWidth, 'gridWidth =>', gridWidth, 'columnWidth =>', columnWidth, 'totalCells =>', totalCells)

  if (columnWidth >= 15 && zoomLevel === 4) {
    return { zoomLevel: zoomLevel, columnWidth }
  }

  // Adjust zoom level if columnWidth is too small
  while (columnWidth < 25 && zoomLevel > 0) {
    zoomLevel--;
    totalCells = calculateTotalCells(zoomLevel);
    columnWidth = (remainingWidth / totalCells);
  }

  if (columnWidth < 25 || columnWidth === Infinity) {
    return null
  }

  // console.log('remainingWidth  =>', remainingWidth, 'gridWidth =>', gridWidth, 'columnWidth =>', columnWidth, 'totalCells =>', totalCells)

  return { zoomLevel, columnWidth }
}

/**
 * Create pdf payload
 * @param {string} printTitle 
 * @param {string} logo 
 * @param {string} startDate 
 * @param {string} endDate 
 * @param {string} layout 
 * @param {string} pageFormat 
 * @param {string} isFitToPage 
 * @returns 
 */
export const createPdfPayload = ({ printTitle, logo, startDate, endDate, layout, pageFormat, isFitToPage }) => {
  const todayDate = `${format(new Date(), 'yyyy-MM-dd')}-${new Date().getTime()}`;

  let style = `${printStyle}`
  return {
    server: `${process.env.REACT_APP_EXPORT_SERVICE_URL}/gantt`,
    name: `${printTitle.replace(/\s+/g, '-')}-${todayDate}`,
    header: getPageHeaderTemplate({ logo, title: printTitle }) + style,
    footer: getPageFooterTemplate(),
    raw: false,
    skin: "material",
    start: startDate,
    end: endDate,
    additional_settings: {
      landscape: layout === "landscape",
      format: pageFormat,
      fixed_headers: true,
      merge_pages: true,
      margins: {
        top: 0.5,
        bottom: 0.5,
        left: 0.5,
        right: 0.5
      },
      fitToPage: isFitToPage,
    }
  }
}


export const resetGantt = ({ gantt, ganttId }) => {

  gantt.$container.parentNode.style.width = '100%';
  gantt.getGridColumn("action").hide = false
  // gantt.resetLayout();
  resetGanttDates({ gantt })
  // initializeCustomLayers({ ganttId })
  gantt.render();

}

export const setTimeFrameDates = ({ gantt, timeFrameFilters }) => {
  const defaultStartDate = gantt.constants?.start_date
    ? new Date(gantt.constants?.start_date.getTime())
    : null;
  const defaultEndDate = gantt.constants?.end_date
    ? new Date(gantt.constants?.end_date?.getTime())
    : null;

  let newStartDate, startDate, newEndDate, endDate = undefined;

  timeFrameFilters?.forEach((timeFrame) => {
    if (timeFrame.type === "customTimeFrameFilter") {
      newStartDate = timeFrame.value.startDate;
      newEndDate = timeFrame.value.endDate
    } else {
      const filteredDates = getTimeFrameFilterDates(
        timeFrame.value.value,
        defaultStartDate,
        defaultEndDate
      );
      newStartDate = filteredDates.newStartDate;
      newEndDate = filteredDates.newEndDate;
    }
    if (startDate === undefined || isBefore(newStartDate, startDate)) {
      startDate = newStartDate;
    }
    if (endDate === undefined || isAfter(newEndDate, endDate)) {
      endDate = newEndDate;
    }
  });

  if (startDate) {
    gantt.config.start_date = subDays(startDate, 1);
  }
  if (endDate) {
    gantt.config.end_date = addDays(endDate, 1);
  }
}

export const resetGanttDates = ({ gantt }) => {
  if (gantt.constants?.start_date && gantt.constants?.end_date) {
    gantt.config.start_date = gantt.constants?.start_date;
    gantt.config.end_date = gantt.constants?.end_date;
  } else {
    gantt.config.start_date = null;
    gantt.config.end_date = null;
    gantt.config.fit_tasks = true;
  }
}

export const getPageHeaderTemplate = ({ logo, title }) => {

  return `<style>
  .header {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    font-family: Arial, sans-serif;
  }
  .header .name {
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px
  }
  .header .logo {
    height: 30px;
    padding: 12px;
  }
</style>
<div class="header">
  <div class="name">${title}</div>
  ${logo ? `<img class="logo" src="${logo}" />` : ''}
  </div>
</div>`;
}

export const getPageFooterTemplate = () => {

  return `<style>
            .footer{
                display: flex;
                height: 100%;
                justify-content: space-between;
                align-items: center;
                font-family: Arial, sans-serif;
            }

            .footer .logo{
                height: 30px;
                padding: 12px;
            }

            .footer .pagination{
                display: flex;
                line-height: 15px;
                flex-direction: column;
                text-align: start;
                gap: 10px;
                font-size: 15px;
                padding-right: 12px;
            }

            .page-info,
            .print-info{
                color: #cecece;
            }

            .totalPages,
            .date{
                color: black;
            }
        </style>
        <div class="footer">
           <img class="logo" src="https://cdn.prod.website-files.com/653d0ee90d6263a720c57864/653d0ee90d6263a720c57873_wide%20logo%20black.png" />
            <div class="pagination">
                <div class="print-info">
                    Printed on: <span class="date"></span>
                </div>
                <div class="page-info">
                    Page: <span class="pageNumber"></span> of <span class="totalPages"></span>
                </div>
            </div>
        </div>`
}

export const extractChunkNo = (url) => {
  const match = url.match(/-(\d+)$/);
  return match ? Number(match[1]) : null;
}


export const printStyle = `
<style>
  .empty_row,
  .gantt_task_row[data-task-id*="_empty_row"],
  .gantt_row[data-task-id*="_empty_row"]{
    visibility: hidden !important;
  }

  .gantt_task_line{
    background-color: #448aff;
  }
  .gantt_task_progress{
    background-color: rgba(0,0,0,.15);
  }

  .gantt_tree_icon.gantt_file{
    background-image: none;
  }

  .gantt_project_grid_row .gantt_cell_tree {
    font-weight: 900;
  }

  .company-avatar {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #bdbdbd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fafafa !important;
    font-size: 9px;
    display: inline-flex;
    position: relative;
    vertical-align: middle;
    text-transform: uppercase;
  }

  .company-grid-icon{
    aspect-ratio: 1;
    height: max(60%, 1em);
    width: auto;
    border-radius: 50%;
    position: relative;
    vertical-align: middle;
    font-size: 0.7rem;
  }

  .workday-circle {
    background-color: rgb(99, 113, 135);
    border-radius: 50%;
    height: 13px;
    width: 13px;
    z-index: 1;
    pointer-events: none;
  }

  .crew-size {
    z-index: 1;
    font-size: 12px;
    font-weight: bold;
    color: white;
  }

  .task-bar-label {
    font-size: 10px;
    color: black;
    line-height: 1.5;
    display: flex;
    align-items: center;
    letter-spacing: 0.00938em;
  }

  .targetEndDate{
    position: absolute;
    border-radius: 2px;
    border: 0px;
  }

  .baseline {
    position: absolute;
    margin-top: -7px;
    height: 5px;
    z-index: 4;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.40);
  }

  .green{
    background-color: rgb(30, 185, 128) !important;
    opacity: 0.6;
  }

  .orange{
    background-color: rgb(254, 192, 105) !important;
    opacity: 0.8;
  }

  .gantt_task_line.gantt_project .gantt_task_progress{
    background: rgba(0,0,0,.15)
  }

  .gantt_grid_data .gantt_project_grid_row .gantt_cell{
    font-weight: 900
  } 
</style>`;


export const getTimeFrameFilterDates = (timeFrameId, defaultStartDate, defaultEndDate) => {
  const currentDate = new Date();
  const adjustments = {
    2: { start: -7 }, // Past 7 Days & All Future
    3: { start: -7, end: 7 }, // Past 7 Days and Next 7 Days
    4: { start: -14, end: 14 }, // Past 14 Days and Next 14 Days
    5: { start: 0 }, // Today
    6: { start: 0 }, // Today Onward
    7: { start: -7, end: 28 }, // 4 Week lookahead
    8: { start: -7, end: 42 }, // 6 Week lookahead
  };

  const adjust = adjustments[timeFrameId] || {};
  const newStartDate = adjust.start ? addDays(currentDate, adjust.start) : defaultStartDate;
  const newEndDate = adjust.end ? addDays(currentDate, adjust.end) : defaultEndDate;

  return { newStartDate, newEndDate };
};



export const transformCamelCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between lowercase and uppercase
    .replace(/^./, (char) => char.toUpperCase()); // Capitalize the first letter
};

export const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string' || string.length === 0) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};


export const calculateColumnWidth = ({ startDate, lookaheadWeeks, layout, pageFormat }) => {
  const endDate = addWeeks(startDate, lookaheadWeeks);
  const pageWidth = formatsWidth[layout][pageFormat];
  const remainingWidth = (pageWidth - 660);
  const totalCells = differenceInDays(endOfDay(endDate), startOfDay(startDate));
  const lookaheadWeekColumnWidth = { 9: 15.55 }
  return lookaheadWeekColumnWidth[lookaheadWeeks] || Math.ceil((remainingWidth / totalCells))

};

