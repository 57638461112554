import { useEffect } from 'react';

const getLayoutScrollStates = (gantt) => {
  const scrollStates = {};

  // Helper function to process a layout cell
  const processCell = (cell) => {
    // Handle views with scroll capabilities
    if (cell.view) {
      const view = gantt.getLayoutView(cell.view);
      if (view && view.$config) {
        scrollStates[view.$config.scrollX] =
          gantt.$ui.getView(view.$config.scrollX).getScrollState().position || 0;
        scrollStates[view.$config.scrollY] =
          gantt.$ui.getView(view.$config.scrollY).getScrollState().position || 0;
      }
    }

    // Recursively process nested structures
    if (cell.cols) {
      cell.cols.forEach(processCell);
    }
    if (cell.rows) {
      cell.rows.forEach(processCell);
    }
  };

  // Start processing from root layout config
  if (gantt.$layout && gantt.$layout.$config) {
    processCell(gantt.$layout.$config);
  }
  return scrollStates;
};

const restoreLayoutScrollStates = (gantt, scrollStates) => {
  Object.entries(scrollStates).forEach(([scrollBarId, value]) => {
    const view = gantt.$ui.getView(scrollBarId);
    if (view) {
      view.scroll(value);
    }
  });
};

export const useGanttLoadTaskAndLinks = ({
  gantt,
  tasks,
  links,
  resources,
  assignments,
  options = {},
}) => {
  const { skip = false } = options;

  useEffect(() => {
    if (
      gantt &&
      !gantt.$destroyed &&
      (!gantt.hasPendingUpdates || !gantt.hasPendingUpdates()) &&
      !skip
    ) {
      // Get Task Timeline state
      const scrollStates = getLayoutScrollStates(gantt);

      // Save open state of tasks
      const openTasks = gantt.getTaskByTime().reduce((obj, task) => {
        obj[task.id] = task.$open;
        return obj;
      }, {});

      gantt.clearAll();
      gantt.parse({
        tasks,
        links,
        resources,
        assignments,
      });

      // Restore open state of tasks
      Object.entries(openTasks).forEach(([taskId, open]) => {
        gantt.getTask(taskId).$open = open;
      });
      gantt.refreshData();

      // Restore scroll states
      restoreLayoutScrollStates(gantt, scrollStates);
    }
  }, [assignments, gantt, links, resources, skip, tasks]);
};
