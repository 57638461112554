import { CrewSizeGraph } from '../components/CrewSizeGraph';

export const analyticsRoutes = [
  {
    path: '',
    lazy: async () => {
      const { AnalyticsWindow } = await import('./AnalyticsWindow');
      return { element: <AnalyticsWindow /> };
    },
  },
  {
    path: 'crew-size',
    element: <CrewSizeGraph />,
  },
];
