import { DatePicker } from '@mui/x-date-pickers';
import { Grid, InputLabel, TextField } from '@mui/material';
import { UseFormReturn, Controller } from 'react-hook-form';
import { SectionFormInterface } from 'features/estimates/types/section.models';
import { ColorSelect } from 'components/form/ColorSelect';

interface SectionFromPropsTypes {
  form: UseFormReturn<SectionFormInterface>;
  onSubmit: any;
}

export const SectionFrom = ({ onSubmit, form }: SectionFromPropsTypes) => {
  const { control, setValue, watch } = form;

  const startDate = watch('start_date');
  const colorInputProps = { size: 'small', fullWidth: true };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <InputLabel htmlFor="name">Name*</InputLabel>
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Section Name is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="Section Name"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid container item xs={2}>
          <InputLabel htmlFor="name">Color*</InputLabel>
          <Controller
            control={control}
            name="color"
            render={({ field }) => <ColorSelect {...field} {...colorInputProps} />}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="name">Start Date*</InputLabel>
          <Controller
            control={control}
            name="start_date"
            rules={{ required: 'Start Date is required' }}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
                onChange={(newValue) => {
                  setValue('start_date', newValue || undefined);
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputLabel htmlFor="name">End Date*</InputLabel>
          <Controller
            control={control}
            name="end_date"
            rules={{ required: 'End Date is required' }}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                minDate={startDate ? startDate : undefined}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
                onChange={(newValue) => {
                  setValue('end_date', newValue || undefined);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="name">Notes</InputLabel>
          <Controller
            control={control}
            name="notes"
            render={({ field }) => (
              <TextField {...field} fullWidth multiline rows={2} size="small" variant="outlined" />
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};
