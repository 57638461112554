import { orderBy } from 'lodash';

const { api, providesList } = require('api');

export const TAGS_CACHE_KEY = 'Tag';

const processTagsWithParents = (tagsWithTasks) => {
  if (!tagsWithTasks?.length) return [];

  const tagsByTaskId = tagsWithTasks.reduce((acc, tag) => {
    acc[tag.task?.id] = tag;
    return acc;
  }, {});

  const tagsWithConcatenatedNames = tagsWithTasks.map((tag) => {
    const taskParentId = tag.task?.parent_id;
    const parentTag = tagsByTaskId[taskParentId];

    if (parentTag?.type === 'generated') {
      return {
        ...tag,
        concatenatedName: `${parentTag.name} - ${tag.name}`,
      };
    } else {
      return {
        ...tag,
        concatenatedName: tag.name,
      };
    }
  });

  return orderBy(tagsWithConcatenatedNames, 'concatenatedName');
};

const tagAPI = api.enhanceEndpoints({ addTagTypes: ['Tag'] }).injectEndpoints({
  endpoints: (build) => ({
    createTag: build.mutation({
      query: ({ taskId, ...body }) => ({
        url: '/tags',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, args) => (result ? [{ id: 'LIST', type: 'Tag' }] : []),
    }),
    updateTag: build.mutation({
      query: ({ id, ...tag }) => ({
        url: `/tags/${id}`,
        method: 'PATCH',
        body: tag,
      }),
      invalidatesTags: (result, error, params) => (result ? [{ type: 'Tag', id: params.id }] : []),
    }),
    getTags: build.query({
      query: () => ({
        url: '/tags',
      }),
      providesTags: (result, error, args) => (result?.length ? providesList(result, 'Tag') : []),
      transformResponse: (response) => response.data,
    }),
    deleteTag: build.mutation({
      query: (id) => ({
        url: `/tags/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, params) => (result ? [{ type: 'Tag', id: params.id }] : []),
    }),
    getProjectGeneratedTags: build.query({
      query: (projectId) => ({
        url: `/projects/${projectId}/generated_tags`,
        method: 'GET',
      }),
      providesTags: (result, error, args) => (result?.length ? providesList(result, 'Tag') : []),
      transformResponse: (response) => response.data,
    }),
    getProjectGeneratedTagsWithTasks: build.query({
      async queryFn({ projectId, smartTags }, _queryApi, _extraOptions, baseQuery) {
        const tagsResult = await baseQuery({
          url: `/projects/${projectId}/generated_tags`,
          method: 'GET',
        });

        if (tagsResult.error) {
          return { error: tagsResult.error };
        }

        const tags = tagsResult.data.data;
        const uniqueTaskIds = [...new Set(tags.map((tag) => tag.smart_task_id))];

        const taskResults = await Promise.all(
          uniqueTaskIds.map((taskId) =>
            baseQuery({
              url: `/tasks/${taskId}`,
            })
          )
        );

        const tasksById = Object.fromEntries(
          taskResults
            .filter((result) => !result.error && result.data?.data)
            .map((result) => [result.data.data.id, result.data.data])
        );

        const tagsWithTasks = tags
          .map((tag) => ({
            ...tag,
            task: tasksById[tag.smart_task_id],
          }))
          .filter((t) => (smartTags.length ? smartTags.includes(t.smart_key) : true));

        return { data: processTagsWithParents(tagsWithTasks) };
      },
      providesTags: (result, error, args) => {
        if (!result?.length) return [];

        return [
          ...providesList(result, 'Tag'),
          ...result.map((tag) => ({ type: 'Task', id: tag.smart_task_id })),
        ];
      },
    }),
  }),
});

export const {
  useCreateTagMutation,
  useGetTagsQuery,
  useUpdateTagMutation,
  useDeleteTagMutation,
  useGetProjectGeneratedTagsQuery,
  useGetProjectGeneratedTagsWithTasksQuery,
} = tagAPI;
