import { useEffect } from 'react';
import { StyledDialog } from '@blackhyve/common';
import { Product } from '../types/product.models';
import { Controller, useForm } from 'react-hook-form';
import { TradesAutocomplete } from 'features/trades/components/TradesAutocomplete';
import { useCreateProductMutation, useUpdateProductMutation } from '../api/products.api';
import { formatDecimals, PercentInput } from 'features/budgets/components/NumericControls';
import {
  CURVE_TYPE,
  CURVE_DISTRIBUTION,
  CURVE_OPTIONS,
  DISTRIBUTION_OPTIONS,
} from '../constants/curves';
import {
  Box,
  InputAdornment,
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

interface ProductFormDialogProps {
  open: boolean;
  handleClose: () => void;
  product?: Partial<Product>;
  creating?: boolean;
}

const initialState: Omit<Product, 'id'> = {
  division: '',
  cost_code: '',
  description: '',
  trade_id: undefined,
  percent: 0,
  labor_percent: '50',
  cogs_labor_percent: 0,
  cogs_material_percent: 0,
  cogs_per_hour: 0,
  labor_curve: CURVE_TYPE.BELL,
  labor_curve_distribution: CURVE_DISTRIBUTION.NORMAL,
  material_percent: '50',
  material_curve: CURVE_TYPE.BELL,
  material_curve_distribution: CURVE_DISTRIBUTION.NORMAL,
};

export const ProductFormDialog: React.FC<ProductFormDialogProps> = ({
  open,
  handleClose,
  product,
  creating = false,
}) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm<Product>({
    defaultValues: { ...initialState, ...product },
  });

  const [createProduct, { isLoading: isLoadingCreate }] = useCreateProductMutation();
  const [updateProduct, { isLoading: isLoadingUpdate }] = useUpdateProductMutation();

  const isLoading = isLoadingCreate || isLoadingUpdate;

  const handleStore = async (data: Product) => {
    try {
      if (product?.id) {
        await updateProduct({ id: product.id, ...data })
          .unwrap()
          .then(closeDialog);
      } else {
        await createProduct(data).unwrap().then(closeDialog);
      }
    } catch (error) {
      console.log('error =>', error);
    }
  };

  const closeDialog = () => {
    handleClose();
    reset();
  };

  useEffect(() => {
    const percentWatcher = watch((state: Product, { name, type }) => {
      if (!type) return;

      if (name === 'labor_percent') {
        setValue('material_percent', formatDecimals(100 - Number(state.labor_percent)));
      }

      if (name === 'material_percent') {
        setValue('labor_percent', formatDecimals(100 - Number(state.material_percent)));
      }
    });

    return () => {
      percentWatcher.unsubscribe();
    };
  }, [watch, setValue]);

  useEffect(() => {
    reset({ ...initialState, ...product });
  }, [product, reset]);

  return (
    <StyledDialog
      dialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={closeDialog}
      open={open}
      title={creating ? 'Create Product' : 'Edit Product'}
      actions={
        <>
          <Button disabled={isLoading} onClick={closeDialog}>
            Close
          </Button>
          <Button
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={'1rem'} />}
            variant={'contained'}
            onClick={handleSubmit(handleStore)}
          >
            Save
          </Button>
        </>
      }
    >
      <Grid container item spacing={2} xs={12}>
        <Grid container item xs={6}>
          <FormLabel>Division*</FormLabel>
          <Controller
            control={control}
            name="division"
            rules={{ required: 'Division field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="Division"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid container item xs={6}>
          <FormLabel>Cost Code*</FormLabel>
          <Controller
            control={control}
            name="cost_code"
            rules={{ required: 'Cost Code field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="Cost Code"
                size="small"
              />
            )}
          />
        </Grid>

        <Grid container item xs={12}>
          <FormLabel>Description*</FormLabel>
          <Controller
            control={control}
            name="description"
            rules={{ required: 'Description field is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="Add Description"
                size="small"
              />
            )}
          />
        </Grid>

        <Grid container item spacing={1} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Trade*</FormLabel>
            <Box width={'100%'}>
              <Controller
                control={control}
                name="trade_id"
                rules={{ required: 'Trade field is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TradesAutocomplete
                    {...field}
                    fullWidth={true}
                    multiple={false}
                    value={field.value?.toString() || null}
                    TextFieldProps={{
                      error: !!error,
                      helperText: error?.message,
                    }}
                    onChange={(_event, newValue) => {
                      field.onChange(newValue ?? null);
                    }}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Estimated Amount (%)</FormLabel>
            <Controller
              control={control}
              name="percent"
              render={({ field, fieldState }) => <PercentInput {...field} {...fieldState} />}
              rules={{ required: 'Estimated Amount field is required' }}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={1} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Estimated Labor (%)</FormLabel>
            <Controller
              control={control}
              name="labor_percent"
              render={({ field, fieldState }) => <PercentInput {...field} {...fieldState} />}
              rules={{ required: 'Estimated Labor field is required' }}
            />
          </Grid>
          <Grid container item xs={6}>
            <FormLabel>Estimated Material (%)</FormLabel>
            <Controller
              control={control}
              name="material_percent"
              render={({ field, fieldState }) => <PercentInput {...field} {...fieldState} />}
              rules={{ required: 'Estimated Material field is required' }}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={1} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>COGS Labor (%)</FormLabel>
            <Controller
              control={control}
              name="cogs_labor_percent"
              render={({ field, fieldState }) => <PercentInput {...field} {...fieldState} />}
              rules={{ required: 'COGS Labor Percent field is required' }}
            />
          </Grid>
          <Grid container item xs={6}>
            <FormLabel>COGS Material (%)</FormLabel>
            <Controller
              control={control}
              name="cogs_material_percent"
              render={({ field, fieldState }) => <PercentInput {...field} {...fieldState} />}
              rules={{ required: 'COGS Material Percent field is required' }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Box display="flex" gap={0.5}>
            <FormLabel>COGS Per Hour</FormLabel>
            <Tooltip placement="top" title="Cost of goods sold per hour">
              <InfoOutlined color="action" fontSize="small" />
            </Tooltip>
          </Box>
          <Controller
            control={control}
            name="cogs_per_hour"
            rules={{ required: 'COGS Per Hour field is required' }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                size="small"
                type="number"
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 0.01,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value.split('.').length > 2) {
                    value = value.replace(/\.+$/, '');
                  }
                  field.onChange(value);
                }}
                onKeyDown={(e) => {
                  if (
                    e.key === '.' ||
                    e.key === 'Backspace' ||
                    e.key === 'Delete' ||
                    e.key === 'Tab' ||
                    e.key === 'Escape' ||
                    e.key === 'Enter' ||
                    // Allow: numbers
                    (e.key >= '0' && e.key <= '9')
                  ) {
                    return;
                  }
                  e.preventDefault();
                }}
              />
            )}
          />
        </Grid>

        <Grid container item spacing={1} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Labor Curve</FormLabel>
            <Controller
              control={control}
              name="labor_curve"
              rules={{ required: 'Labor Curve field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth name={'name'} size={'small'}>
                  {CURVE_OPTIONS.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Material Curve</FormLabel>
            <Controller
              control={control}
              name="material_curve"
              rules={{ required: 'Material Curve field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth name={'name'} size={'small'}>
                  {CURVE_OPTIONS.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={1} xs={12}>
          <Grid container item xs={6}>
            <FormLabel>Labor Curve Distribution</FormLabel>
            <Controller
              control={control}
              name="labor_curve_distribution"
              rules={{ required: 'Labor Curve Distribution field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth size={'small'}>
                  {DISTRIBUTION_OPTIONS.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          <Grid container item xs={6}>
            <FormLabel>Material Curve Distribution</FormLabel>
            <Controller
              control={control}
              name="material_curve_distribution"
              rules={{ required: 'Material Curve Distribution field is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select {...field} fullWidth size={'small'}>
                  {DISTRIBUTION_OPTIONS.map((obj) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};
