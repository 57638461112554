import FeatureFlag, { features } from '@blackhyve/common/src/components/FeatureFlag';
import { Grid, MenuItem } from '@mui/material';
import { FormTextField } from 'assets/style-components/textfield';
import { FormLabel } from 'assets/style-components/typography';
import { Controller, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';

const featureOptions = [
  { value: false, label: 'Off' },
  { value: true, label: 'On' },
];

const defaultValues = {
  check_task_dependencies: false,
  budgets_feature: false,
  imports_feature: false,
  production_schedule: false,
};

export const FeaturesForm = ({ onSubmit, initialData, canEdit }) => {
  const { control, handleSubmit } = useForm({
    defaultValues,
    values: initialData,
  });

  const debouncedSaveFormState = useDebounceCallback(() => {
    handleSubmit(onSubmit)();
  });

  return (
    <form>
      <Grid container spacing={2}>
        <FeatureFlag name={features.BUDGETS}>
          <Grid item xs={6}>
            <FormLabel>Budgets</FormLabel>
            <Controller
              control={control}
              name={'budgets_feature'}
              rules={{ onChange: debouncedSaveFormState }}
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  select
                  disabled={!canEdit}
                  size="small"
                  variant="outlined"
                >
                  {featureOptions.map((option) => (
                    <MenuItem
                      key={option.label}
                      sx={{ textTransform: 'capitalize' }}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </FormTextField>
              )}
            />
          </Grid>
        </FeatureFlag>

        <FeatureFlag name={features.IMPORTS}>
          <Grid item xs={6}>
            <FormLabel>Imports</FormLabel>
            <Controller
              control={control}
              name={'imports_feature'}
              rules={{ onChange: debouncedSaveFormState }}
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  select
                  disabled={!canEdit}
                  size="small"
                  variant="outlined"
                >
                  {featureOptions.map((option) => (
                    <MenuItem
                      key={option.label}
                      sx={{ textTransform: 'capitalize' }}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </FormTextField>
              )}
            />
          </Grid>
        </FeatureFlag>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormLabel>Production Board</FormLabel>
              <Controller
                control={control}
                name="production_schedule"
                rules={{ onChange: debouncedSaveFormState }}
                render={({ field }) => (
                  <FormTextField
                    {...field}
                    fullWidth
                    select
                    disabled={!canEdit}
                    size="small"
                    variant="outlined"
                  >
                    {featureOptions.map((option) => (
                      <MenuItem
                        key={option.label}
                        sx={{ textTransform: 'capitalize' }}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </FormTextField>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
