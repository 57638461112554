import { GanttStatic } from '@blackhyve/dhtmlx-gantt';
import { ResourceTask } from 'features/resources/api/resources.models';
import { FiltersConfig, useFilters } from 'hooks/useFilters';

interface ResourceFilters {
  trade: number | null;
  name: string;
}

const resourceFiltersConfig: FiltersConfig<ResourceFilters> = {
  trade: {
    defaultValue: null,
    matcher: (value, resource: ResourceTask, { gantt }: { gantt: GanttStatic }) => {
      const resourceStore = gantt.getDatastore(gantt.config.resource_store);
      if (!value) return true;

      // If it's a task, only return true if it's visible
      if (resource.$role === 'task') {
        return resource.parent !== undefined && resourceStore.isVisible(resource.parent);
      }

      // Otherwise check trades
      return resource.trades.some((tradeRole) => tradeRole.trade_id === value);
    },
  },
  name: {
    defaultValue: '',
    matcher: (value, resource: ResourceTask, { gantt }: { gantt: GanttStatic }) => {
      const resourceStore = gantt.getDatastore(gantt.config.resource_store);
      if (!value) return true;

      // If it's a task, only return true if it's visible
      if (resource.$role === 'task') {
        return resource.parent !== undefined && resourceStore.isVisible(resource.parent);
      }

      // Otherwise check name
      return resource.text?.toLowerCase()?.includes(value.toLowerCase());
    },
  },
};

export function useResourceFilters() {
  return useFilters(resourceFiltersConfig);
}
