import { parseDate } from '@blackhyve/utilities/dates';
import {
  Box,
  Button,
  Stack,
  Select,
  FormControl,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
  LinearProgress,
} from '@mui/material';
import {
  CategoryScale,
  ChartData,
  ChartDataset,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { addDays, format } from 'date-fns';
import { useGetEstimateGraphDataQuery } from 'features/estimates/api/estimate.api';
import { EstimateGraphData } from 'features/estimates/types/estimate.models';
import { useOnPrint } from 'hooks/useOnPrint';
import React, { useCallback, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';

const PERIOD_OPTIONS = [
  { name: 'Day', value: 'day' },
  { name: 'Week', value: 'week' },
  { name: 'Month', value: 'month' },
];

// Register ChartJS components once
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

// Types
type ViewMode = 'trade' | 'section';
type DataPoint = { x: string; y: number };
type Dataset = ChartDataset<'line', DataPoint[]>;
type Period = 'day' | 'week' | 'month';

interface ChartHeaderProps {
  viewMode: ViewMode;
  onViewModeChange: (newMode: ViewMode) => void;
  period: Period;
  onPeriodChange: (newPeriod: Period) => void;
}

// Component for the chart header
const ChartHeader: React.FC<ChartHeaderProps> = ({
  viewMode,
  onViewModeChange,
  period,
  onPeriodChange,
}) => (
  <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
    <Box>
      <Typography gutterBottom component={'h2'} variant={'h6'}>
        Labor Requirements Estimate
      </Typography>
      <Typography color={'text.secondary'} variant={'body2'}>
        Projected workers needed on site
      </Typography>
    </Box>

    <Box display={'flex'} gap={2}>
      <Box sx={{ width: 100 }}>
        <FormControl fullWidth size="small">
          <Select
            name="period"
            value={period}
            variant="outlined"
            onChange={(event) => onPeriodChange(event.target.value as Period)}
          >
            {PERIOD_OPTIONS?.map((period) => {
              return (
                <MenuItem key={period.value} value={period.value}>
                  {period.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <ToggleButtonGroup
        exclusive
        color={'primary'}
        value={viewMode}
        onChange={(e, newMode) => newMode && onViewModeChange(newMode as ViewMode)}
      >
        <ToggleButton size={'small'} value={'trade'}>
          By Trade
        </ToggleButton>
        <ToggleButton size={'small'} value={'section'}>
          By Section
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  </Stack>
);

const transformApiData = (data: EstimateGraphData): ChartDataset<'line', DataPoint[]>[] => {
  return data?.datasets
    ? Object.entries(data.datasets).map(([key, dataset]) => {
        const color = dataset?.group_object?.color || '#FFD700';

        return {
          label: dataset?.group_object?.name || key,
          data: dataset.data.map((point) => ({
            x: point.label,
            y: point.value ?? 0,
          })),
          borderColor: color,
          backgroundColor: color,
          fill: false,
          pointRadius: 0,
        };
      })
    : [];
};

// Main component
export const EstimateGraph = ({
  estimateId,
  startDate,
  endDate,
}: {
  estimateId: number;
  startDate: string;
  endDate: string;
}) => {
  const theme = useTheme();

  const [viewMode, setViewMode] = useState<ViewMode>('trade');
  const [period, setPeriod] = useState<Period>('day');

  const { data: estimateGraphData = {} as EstimateGraphData, isFetching } =
    useGetEstimateGraphDataQuery({
      estimateId,
      params: { groupBy: viewMode, period: 'day' },
    });

  const chartRef = useRef<ChartJS<'line', DataPoint[]>>();
  const containerRef = useRef<HTMLDivElement>(null);

  useOnPrint(
    useCallback(() => {
      if (chartRef.current) {
        const container = containerRef.current;
        chartRef.current.resize(container?.clientWidth, container?.clientHeight);
      }
    }, [])
  );

  const data: Dataset[] = transformApiData(estimateGraphData);

  const chartData: ChartData<'line', DataPoint[]> = {
    datasets: data,
  };

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    normalized: true,

    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    spanGaps: true,

    scales: {
      x: {
        type: 'time',
        suggestedMin: +addDays(parseDate(startDate), -3),
        suggestedMax: +addDays(parseDate(endDate), 3),
        time: {
          unit: period,
          parser: 'yyyy-MM-dd',
          minUnit: 'day',
          displayFormats: {
            day: 'PP',
            week: 'wo, y',
          },
          isoWeekday: true,
        },
        grid: {
          display: true,
          color: theme.palette.divider,
        },
        ticks: {
          minRotation: 45,
          maxRotation: 45,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number of Workers Required',
        },
        grid: {
          color: theme.palette.divider,
        },
        ticks: {
          stepSize: 2,
        },
      },
    },
    plugins: {
      decimation: {
        enabled: true,
        algorithm: 'min-max',
      },
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const date = new Date(tooltipItems[0].parsed.x);
            return format(date, 'PP');
          },
        },
      },
      annotation: {
        annotations: {
          projectStart: {
            type: 'line',
            xMin: startDate,
            xMax: startDate,
            borderColor: theme.palette.text.secondary,
            borderWidth: 2,
            borderDash: [3, 3],
            label: {
              content: 'Project Start',
              display: true,
              position: 'end',
              font: { size: 10 },
            },
          },
          projectEnd: {
            type: 'line',
            xMin: endDate,
            xMax: endDate,
            borderColor: theme.palette.text.secondary,
            borderWidth: 2,
            borderDash: [3, 3],
            label: {
              content: 'Project End',
              display: true,
              position: 'end',
              font: { size: 10 },
            },
          },
        },
      },
    },
  };

  return (
    <>
      {isFetching && <LinearProgress />}
      <ChartHeader
        period={period}
        viewMode={viewMode}
        onPeriodChange={setPeriod}
        onViewModeChange={setViewMode}
      />
      <Box height={400} ref={containerRef}>
        <Line data={chartData} options={chartOptions} ref={chartRef} />
      </Box>
    </>
  );
};
