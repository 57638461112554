import { Box, Button, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { format } from 'date-fns';
import { useCreateJobwalkMutation } from 'features/jobwalk/store/jobwalk.api';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NotesStep } from '../components/NotesStep';
import { PresetStep } from '../components/PresetStep';
import { SubmitStep } from '../components/SubmitStep';

const defaultValues = {
  percent_complete: 0,
  earned_days: 0,
  earned_planned_days: 0,
  pr: 0,
  pace: 0,
  days_remaining: 0,
  forecasted_end: new Date(),
  actual_start: new Date(),
  effective_date: new Date(),
  actual_crew_size: '',
  notes: '',
};

export const JobwalkInput = ({ task, onSuccess, shouldAutoschedule = false, holidays = [] }) => {
  const form = useForm({
    defaultValues,
    mode: 'onChange',
  });
  const {
    formState: { isValid },
    handleSubmit,
    reset,
  } = form;

  const [currentStep, setCurrentStep] = useState(0);
  const [preset, setPreset] = useState(undefined);

  const [createJobwalk, { isLoading, isSuccess, isError, reset: resetMutation }] =
    useCreateJobwalkMutation();

  const submit = async (formData) => {
    await createJobwalk({
      ...formData,
      taskId: task.id,
      forecasted_end: formData.forecasted_end && format(formData.forecasted_end, 'yyyy/MM/dd'),
      effective_date: formData.effective_date && format(formData.effective_date, 'yyyy/MM/dd'),
      actual_start: formData.actual_start && format(formData.actual_start, 'yyyy/MM/dd'),
      should_autoschedule: shouldAutoschedule,
    })
      .unwrap()
      .then(({ data }) => {
        if (onSuccess) {
          onSuccess(data.updated_tasks[0]);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleReset = () => {
    setPreset(undefined);
    setCurrentStep(0);
    reset(defaultValues);
    resetMutation();
  };

  const steps = [
    {
      label: 'Select Preset',
      component: (
        <PresetStep
          form={form}
          holidays={holidays}
          task={task}
          value={preset}
          onChange={(event) => {
            const preset = event.target.value;
            setPreset(preset);
          }}
        />
      ),
      onBeforeNext: () => {
        if (preset.action) preset.action();
      },
      validate: () => Boolean(preset),
    },
    {
      label: 'Enter Details',
      component: preset?.detailsStep,
      validate: () => isValid,
    },
    { label: 'Enter Notes', component: <NotesStep form={form} task={task} />, optional: true },
    {
      label: 'Review Jobwalk',
      component: (
        <SubmitStep
          form={form}
          handleReset={handleReset}
          handleSubmit={handleSubmit(submit)}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          task={task}
        />
      ),
    },
  ];

  const handleBack = () => {
    if (currentStep !== 0) {
      if (currentStep - 1 === 1 && !preset.detailsStep) {
        setCurrentStep(currentStep - 2);
      } else {
        setCurrentStep(currentStep - 1);
      }
    }
  };

  const handleNext = () => {
    if (steps[currentStep].onBeforeNext) steps[currentStep].onBeforeNext();
    if (currentStep !== steps.length - 1) {
      if (currentStep + 1 === 1 && !preset.detailsStep) {
        setCurrentStep(currentStep + 2);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  return (
    <Stack useFlexGap p={1} spacing={1}>
      <Stepper alternativeLabel activeStep={currentStep} sx={{ paddingTop: 1 }}>
        {steps.map(({ label, optional }) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <>
        <Box alignItems={'center'} display={'flex'} height={{ md: 300 }} px={1}>
          <Box flex={1}>{steps[currentStep].component}</Box>
        </Box>
        <Stack alignItems={'center'} direction={'row'} width={1}>
          {currentStep !== 0 && (
            <Button disabled={isLoading} onClick={handleBack}>
              Back
            </Button>
          )}
          {currentStep !== steps.length - 1 && (
            <Button
              disabled={steps[currentStep]?.validate && !steps[currentStep]?.validate()}
              sx={{ ml: 'auto' }}
              variant={'contained'}
              onClick={handleNext}
            >
              Next
            </Button>
          )}
        </Stack>
      </>
    </Stack>
  );
};
