import { Divider, Stack, Box } from '@mui/material';
import { Skeleton } from '@mui/material';

export const MetricsSkeleton = () => {
  return (
    <>
      <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={2}>
          <Box>
            <Skeleton variant="text" width={80} />
            <Skeleton variant="text" width={100} />
          </Box>
          <Box>
            <Skeleton variant="text" width={80} />
            <Skeleton variant="text" width={100} />
          </Box>
        </Stack>
        <Box>
          <Skeleton variant="text" width={120} />
          <Skeleton variant="text" width={100} />
        </Box>
      </Stack>

      <Divider sx={{ my: 2 }} />

      {/* Metrics Groups */}
      <Stack
        useFlexGap
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={'stretch'}
        spacing={{ xs: 3, sm: 4 }}
      >
        {/* Total Metrics */}
        <Box flex={1}>
          <Skeleton variant="text" width={120} />
          <Stack spacing={1} sx={{ mt: 1 }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
          </Stack>
        </Box>

        <Divider flexItem orientation={'vertical'} sx={{ display: { xs: 'none', sm: 'block' } }} />

        {/* Labor Metrics */}
        <Box flex={1}>
          <Skeleton variant="text" width={120} />
          <Stack spacing={1} sx={{ mt: 1 }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
          </Stack>
        </Box>

        <Divider flexItem orientation={'vertical'} />

        {/* Material Metrics */}
        <Box flex={1}>
          <Skeleton variant="text" width={120} />
          <Stack spacing={1} sx={{ mt: 1 }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
          </Stack>
        </Box>
      </Stack>
    </>
  );
};
