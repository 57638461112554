import { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
  TableContainer,
  Container,
} from '@mui/material';
import { Dropdown } from '../Dropdown';
import { GanttColumnLabels } from 'components/projectOverview/gantt/ganttConfig/column/GanttColumnLabels';
import { useGetImportQuery, useUpdateImportMutation } from '../../api/imports.api';
import { ScheduleColumn, ScheduleEntry } from '../../api/imports.models';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentLayout } from 'components/layouts';

export function ImportColumnMapper() {
  const [mapping, setMapping] = useState<Record<number, string>>({});
  const [updateImport] = useUpdateImportMutation();
  const importId = Number(useParams().importId) || undefined;
  const navigate = useNavigate();

  const { data: backendImport } = useGetImportQuery(
    { backendImportId: importId },
    {
      skip: !importId,
      refetchOnMountOrArgChange: true,
    }
  );

  const ganttColumnOptions = Object.entries(GanttColumnLabels).map(([key, value]) => ({
    id: value,
    label: value,
  }));

  useEffect(() => {
    if (backendImport?.schedule_columns) {
      const initialMapping = backendImport.schedule_columns.reduce(
        (acc: Record<number, string>, col: ScheduleColumn) => {
          acc[col.id] = col.map_to || '';
          return acc;
        },
        {}
      );
      setMapping(initialMapping);
    }
  }, [backendImport]);

  const handleChange = (columnId: number) => (event: { target: { value: string } }) => {
    const { value } = event.target;
    setMapping((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  };

  const selectedColumns = Object.values(mapping).filter((value) => value);

  const getAvailableOptions = (currentValue: string) => {
    const noneOption = { id: '', label: 'None' };
    const allowedColumns = [
      GanttColumnLabels.UUID,
      GanttColumnLabels.NAME,
      GanttColumnLabels.START_DATE,
      GanttColumnLabels.END_DATE,
      GanttColumnLabels.DURATION,
    ];

    const filteredOptions = ganttColumnOptions.filter(
      (option) =>
        allowedColumns.includes(option.id as GanttColumnLabels) &&
        (option.id === currentValue || !selectedColumns.includes(option.id))
    );

    return [noneOption, ...filteredOptions];
  };

  if (!backendImport?.schedule_columns || !backendImport?.schedule_entries) {
    return null;
  }

  // Transform entries into row-based format
  const groupedEntries = backendImport.schedule_entries.reduce(
    (acc: Record<number, Record<number, string>>, entry: ScheduleEntry) => {
      if (!acc[entry.row]) {
        acc[entry.row] = {};
      }
      acc[entry.row][entry.column] = entry.text || '';
      return acc;
    },
    {}
  );

  // Convert to array, sort by row number, and limit to 5 rows for preview
  const rows = Object.entries(groupedEntries)
    .map(([rowNum, rowData]) => ({
      rowNum: parseInt(rowNum, 10),
      data: rowData,
    }))
    .sort((a, b) => a.rowNum - b.rowNum)
    .slice(0, 5);

  const columnStyle = {
    maxWidth: 200,
    whiteSpace: 'nowrap' as const,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '8px 16px',
    fontSize: '0.875rem',
  };

  const headerStyle = {
    ...columnStyle,
    fontWeight: 600,
    backgroundColor: '#f5f5f5',
  };

  const dropdownCellStyle = {
    ...columnStyle,
    padding: '4px 16px',
    backgroundColor: '#fafafa',
  };

  const handleColumnMappingComplete = async (columnMapping: Record<number, string>) => {
    try {
      if (!importId) {
        throw new Error('No import selected');
      }

      const schedule_columns = Object.entries(columnMapping).map(([id, map_to]) => ({
        id: parseInt(id),
        map_to: map_to || null, // Convert empty strings to null
      }));

      await updateImport({
        backendImportId: importId,
        updateData: {
          schedule_columns,
        },
      });

      navigate(`../confirm-wbs`, { relative: 'path' });
    } catch (e) {
      console.error('Failed to update column mapping:', e);
    }
  };

  return (
    <ContentLayout>
      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TableContainer sx={{ border: '1px solid #e0e0e0', borderRadius: 1 }}>
            <Table size="small" sx={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  {backendImport.schedule_columns.map((column: ScheduleColumn) => (
                    <TableCell key={column.id} sx={headerStyle}>
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {backendImport.schedule_columns.map((column: ScheduleColumn) => (
                    <TableCell key={column.id} sx={dropdownCellStyle}>
                      <Dropdown
                        list={getAvailableOptions(mapping[column.id])}
                        name={column.name}
                        value={mapping[column.id]}
                        onChange={handleChange(column.id)}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any) => (
                  <TableRow
                    hover
                    key={row.rowNum}
                    sx={{
                      '&:last-child td': { borderBottom: 0 },
                      '&:hover': { backgroundColor: '#f5f5f5' },
                    }}
                  >
                    {backendImport.schedule_columns.map((column: ScheduleColumn) => (
                      <TableCell key={`${row.rowNum}-${column.index}`} sx={columnStyle}>
                        {row.data[column.index] || ''}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              color="primary"
              disabled={Object.keys(mapping).length === 0}
              variant="contained"
              sx={{
                textTransform: 'none',
                minWidth: 120,
              }}
              onClick={() => handleColumnMappingComplete(mapping)}
            >
              Confirm Columns
            </Button>
          </Box>
        </Box>
      </Container>
    </ContentLayout>
  );
}
