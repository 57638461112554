import { Collapse, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { MetricItem } from './MetricItem';

interface MetricGroupProps {
  title: string;
  rom: number;
  cost: number;
  margin: number;
  hideSensitive: boolean;
}

export const MetricGroup: React.FC<MetricGroupProps> = ({
  title,
  rom,
  cost,
  margin,
  hideSensitive = false,
}) => (
  <Stack flex={1} minWidth={200} spacing={1} sx={{ breakInside: 'avoid' }}>
    <Typography fontWeight={'bold'} variant={'subtitle2'}>
      {title}
    </Typography>
    <Stack spacing={1}>
      <MetricItem label={'Estimate'} value={rom} />
      <Collapse in={!hideSensitive}>
        <Stack spacing={1}>
          <MetricItem label={'Cost'} value={cost} valueColor={'error.main'} />
          <Divider />
          <MetricItem
            label="Margin"
            margin={margin ? (margin / rom) * 100 : undefined}
            value={margin}
            valueColor={margin > 0 ? 'success.main' : 'error.main'}
          />
        </Stack>
      </Collapse>
    </Stack>
  </Stack>
);
