import { OptionsMenu } from '@blackhyve/common';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Estimate } from 'features/estimates/types/estimate.models';
import { useState } from 'react';
import { EstimateFormDialog } from './EstimateFormDialog';
import { useDeleteEstimateMutation } from 'features/estimates/api/estimate.api';
import ConfirmDeleteDialog from 'components/common/popovers/ConfirmDeleteDialog';

type EstimateActionMenuProps = {
  estimate: Estimate;
};

export function EstimateActionMenu({ estimate }: EstimateActionMenuProps) {
  const [deleteEstimate, { isLoading: isDeletingEstimate }] = useDeleteEstimateMutation();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  return (
    <>
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        triggerButton={
          <IconButton size={'small'}>
            <MoreVertIcon />
          </IconButton>
        }
      >
        {(handleClose) => (
          <List dense>
            <ListItemButton
              onClick={() => {
                handleClose();
                setIsEditOpen(true);
              }}
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                handleClose();
                setIsDeleteOpen(true);
              }}
            >
              <ListItemIcon>
                <DeleteIcon color={'error'} />
              </ListItemIcon>
              <ListItemText sx={{ color: 'error' }}>Delete</ListItemText>
            </ListItemButton>
          </List>
        )}
      </OptionsMenu>
      <EstimateFormDialog
        estimate={estimate}
        handleClose={() => setIsEditOpen(false)}
        open={isEditOpen}
        projectId={estimate.project_id}
      />
      <ConfirmDeleteDialog
        handleClose={() => setIsDeleteOpen(false)}
        id={undefined}
        isLoading={isDeletingEstimate}
        item={'estimate'}
        itemNames={undefined}
        open={isDeleteOpen}
        plural={undefined}
        handleDelete={async () => {
          await deleteEstimate({ id: estimate.id })
            .unwrap()
            .then(() => {
              setIsDeleteOpen(false);
            });
        }}
      />
    </>
  );
}
