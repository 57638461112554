import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  FormControlLabel,
  LinearProgress,
  Link,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { ContentLayout } from 'components/layouts';
import { useGetProjectQuery } from 'features/projects/store/project.api';
import { times } from 'lodash';
import { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useGetEstimateQuery } from '../api/estimate.api';
import { useGetSectionsQuery } from '../api/section.api';
import { EstimateActionMenu } from '../components/estimates/EstimateActionMenu';
import { EstimateGraph } from '../components/estimates/EstimateGraph';
import { Metrics } from '../components/metrics/Metrics';
import { MetricsSkeleton } from '../components/metrics/MetricsSkeleton';
import { Section } from '../components/sections/Section';
import { SectionFormDialog } from '../components/sections/SectionFormDialog';
import { SectionSkeleton } from '../components/sections/SectionSkeleton';
import { Estimate } from '../types/estimate.models';

export const EstimateDetails: React.FC = () => {
  const projectId = Number(useParams().projectId);
  const estimateId = Number(useParams().estimateId);

  const [openSectionDialog, setOpenSectionDialog] = useState(false);
  const [hideSensitive, setHideSensitive] = useState(false);

  const { data: project } = useGetProjectQuery(projectId, {
    skip: !projectId,
  });

  const {
    data: estimate = {} as Estimate,
    isLoading: isLoadingEstimates,
    isFetching: isFetchingEstimates,
  } = useGetEstimateQuery({ id: estimateId });

  const {
    data: sections = [],
    isLoading: isLoadingSections,
    isFetching: isFetchingSections,
  } = useGetSectionsQuery({ estimateId });

  return (
    <ContentLayout>
      <Container
        maxWidth={'xl'}
        sx={{
          py: 2,
        }}
      >
        <Stack spacing={2}>
          <Stack direction={'row'}>
            <Stack alignItems={'center'} direction={'row'} flex={1} justifyContent={'flex-start'}>
              <Breadcrumbs sx={{ displayPrint: 'none' }}>
                <Link component={RouterLink} sx={{ display: 'flex' }} to={'../'} underline="hover">
                  <ArrowBackIcon sx={{ mr: 0.5 }} />
                  <Typography>Back to List</Typography>
                </Link>
              </Breadcrumbs>
            </Stack>
            <Stack alignItems={'center'} direction={'row'} flex={1} justifyContent={'center'}>
              <Typography variant={'h5'}>{estimate?.name}</Typography>
            </Stack>
            <Stack alignItems={'center'} direction={'row'} flex={1} justifyContent={'flex-end'}>
              <Box displayPrint={'none'}>
                <FormControlLabel
                  checked={hideSensitive}
                  control={<Switch />}
                  label="Hide Sensitive Data"
                  onChange={() => setHideSensitive(!hideSensitive)}
                />
                <EstimateActionMenu estimate={estimate} />
              </Box>
            </Stack>
          </Stack>

          <Card>
            <CardContent>
              {estimateId && (
                <EstimateGraph
                  endDate={project.sub_completion_date}
                  estimateId={estimateId}
                  startDate={project.start_date}
                />
              )}
            </CardContent>
          </Card>

          <Card>
            {isFetchingEstimates && <LinearProgress />}
            <CardContent>
              {isLoadingEstimates ? (
                <MetricsSkeleton />
              ) : (
                estimate.rollup && (
                  <Metrics
                    endDate={estimate.end_date || project.sub_completion_date}
                    hideSensitive={hideSensitive}
                    rollup={estimate.rollup}
                    startDate={estimate.start_date || project.start_date}
                  />
                )
              )}
            </CardContent>
          </Card>

          <Stack spacing={1}>
            <Stack direction={'row'} displayPrint={'none'}>
              <Button
                size={'small'}
                variant={'contained'}
                onClick={() => setOpenSectionDialog(true)}
              >
                + Add Section
              </Button>
              <SectionFormDialog
                handleClose={() => setOpenSectionDialog(false)}
                open={openSectionDialog}
              />
            </Stack>
            <Stack spacing={3}>
              {isLoadingSections
                ? times(5).map((i) => {
                    return <SectionSkeleton key={i} />;
                  })
                : sections?.map((section) => {
                    return (
                      <Section
                        estimateId={section.estimate_id}
                        hideSensitive={hideSensitive}
                        id={section.id}
                        key={section.id}
                      />
                    );
                  })}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </ContentLayout>
  );
};
