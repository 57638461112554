import {
  Box,
  LinearProgress,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  TableCellProps,
  TableHeadProps,
  TableBodyProps,
  TableProps as MuiTableProps,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { useState, ReactNode } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

type Order = 'asc' | 'desc';
type SortMode = 'client' | 'server';

interface BaseRow {
  id: string | number;
}

export interface Column<T extends BaseRow> {
  field: (keyof T & string) | (string & {});
  label: string;
  sort?: boolean;
  comparator?: (a: any, b: any, order: Order, rowA?: T, rowB?: T) => number;
  render?: (row: T, meta: any) => ReactNode;
  cellProps?: TableCellProps;
  skeleton?: ReactNode;
}

export interface TableProps<T extends BaseRow> {
  columns: Column<T>[];
  rows: (T & BaseRow)[];
  defaultOrder?: Order;
  defaultOrderBy?: string;
  handleSort?: (property: string, order: Order) => void;
  sortMode?: SortMode;
  rowProps?: ((row: T) => object) | object;
  headerProps?: TableHeadProps;
  bodyProps?: TableBodyProps;
  isLoading?: boolean;
  isFetching?: boolean;
  disableHeader?: boolean;
  meta?: any;
  isCollapse?: boolean;
  rowCollapse?: (row: T, isOpen: boolean) => ReactNode;
  tableProps?: MuiTableProps;
}

interface HeaderProps<T extends BaseRow> {
  order: Order;
  orderBy: string;
  handleSort: (
    event: React.MouseEvent<unknown>,
    property: string,
    comparator?: Column<T>['comparator']
  ) => void;
  columns: Column<T>[];
  headerProps?: TableHeadProps;
  isCollapse?: boolean;
}

interface RowProps<T extends BaseRow> {
  row: T;
  columns: Column<T>[];
  rowProps?: TableProps<T>['rowProps'];
  meta?: any;
  isCollapse?: boolean;
  rowCollapse?: (row: T, isOpen: boolean) => ReactNode;
}

const isFunction = (obj: unknown): obj is Function => typeof obj === 'function';
const isString = (obj: unknown): obj is string => typeof obj === 'string';

export const Table = <T extends BaseRow>({
  columns,
  rows,
  defaultOrder = 'desc',
  defaultOrderBy = columns[0]?.field,
  rowProps,
  headerProps,
  bodyProps,
  isLoading,
  isFetching,
  disableHeader,
  meta,
  isCollapse = false,
  rowCollapse,
  handleSort: externalHandleSort,
  ...tableProps
}: TableProps<T>): JSX.Element => {
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);
  const [comparator, setComparator] = useState<Column<T>['comparator']>();

  const onSortChange = (
    event: React.MouseEvent<unknown>,
    property: string,
    compare?: Column<T>['comparator']
  ) => {
    const isDesc = orderBy === property && order === 'desc';
    const newSortOrder: Order = isDesc ? 'asc' : 'desc';
    setOrder(newSortOrder);
    setOrderBy(property);
    if (externalHandleSort) {
      externalHandleSort(property, newSortOrder);
    } else {
      setComparator(compare);
    }
  };

  const sortedRows = externalHandleSort
    ? rows
    : [...rows].sort((a, b) => {
        const aValue = a[orderBy as keyof typeof a];
        const bValue = b[orderBy as keyof typeof b];
        let comparison = 0;
        if (comparator) {
          comparison = comparator(aValue, bValue, order, a, b);
        } else {
          if (isString(aValue) && isString(bValue)) {
            comparison = -aValue.localeCompare(bValue);
          } else {
            if (aValue < bValue) comparison = -1;
            else if (aValue > bValue) comparison = 1;
          }
        }
        return order === 'asc' ? comparison : -comparison;
      });

  return (
    <MuiTable {...tableProps} sx={{ height: '100%' }}>
      {!disableHeader && (
        <Header
          columns={columns}
          handleSort={onSortChange}
          headerProps={headerProps}
          isCollapse={isCollapse}
          order={order}
          orderBy={orderBy}
        />
      )}
      <TableBody {...bodyProps} sx={{ position: 'relative', overflow: 'auto' }}>
        {isFetching && (
          <tr>
            <Box colSpan={columns.length + 1} component={'td'} position={'relative'}>
              <Box left={0} position={'absolute'} top={0} width={'100%'}>
                <LinearProgress />
              </Box>
            </Box>
          </tr>
        )}
        {isLoading ? (
          <RowsLoading columns={columns} />
        ) : sortedRows.length > 0 ? (
          sortedRows.map((row) => (
            <Row
              columns={columns}
              isCollapse={isCollapse}
              key={row.id}
              meta={meta}
              row={row}
              rowCollapse={rowCollapse}
              rowProps={rowProps}
            />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} sx={{ textAlign: 'center' }}>
              No Data
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </MuiTable>
  );
};

const Header = <T extends BaseRow>({
  order,
  orderBy,
  handleSort,
  columns,
  headerProps,
  isCollapse,
}: HeaderProps<T>) => {
  const createSortHandler =
    (property: string, comparator?: Column<T>['comparator']) =>
    (event: React.MouseEvent<unknown>) => {
      handleSort(event, property, comparator);
    };

  return (
    <TableHead {...headerProps}>
      <TableRow>
        {isCollapse ? <TableCell /> : null}
        {columns.map((column) => (
          <TableCell
            align={'left'}
            key={`header-${column.field}`}
            padding={'normal'}
            sortDirection={orderBy === column.field ? order : false}
            sx={{ fontWeight: 600 }}
            {...column?.cellProps}
          >
            {column.label &&
              (column.sort ? (
                <TableSortLabel
                  active={orderBy === column.field}
                  direction={orderBy === column.field ? order : 'desc'}
                  onClick={createSortHandler(column.field, column?.comparator)}
                >
                  {column.label}
                </TableSortLabel>
              ) : (
                column.label
              ))}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const Row = <T extends BaseRow>({
  row,
  columns,
  rowProps,
  meta,
  isCollapse,
  rowCollapse,
}: RowProps<T>) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box hover component={TableRow} {...(isFunction(rowProps) ? rowProps(row) : rowProps)}>
        {isCollapse ? (
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : null}
        {columns.map((column) => (
          <TableCell align={'left'} key={`${row.id}-${column.field}`} {...column?.cellProps}>
            {column.render
              ? column.render(row, meta)
              : Object.hasOwn(row, column.field)
                ? String(row[column.field as keyof T])
                : null}
          </TableCell>
        ))}
      </Box>
      {isCollapse && open && rowCollapse ? rowCollapse(row, open) : null}
    </>
  );
};

interface RowsLoadingProps<T extends BaseRow> {
  columns: Column<T>[];
  rows?: number;
}

const RowsLoading = <T extends BaseRow>({ columns, rows = 10 }: RowsLoadingProps<T>) => {
  return Array.from({ length: rows }, (_, index) => (
    <TableRow key={index}>
      {columns.map((column) => (
        <TableCell key={`${index}-loading-${column.field}`}>
          {column.skeleton ? column.skeleton : <Skeleton variant="text" />}
        </TableCell>
      ))}
    </TableRow>
  ));
};
