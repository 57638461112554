import { useModal } from 'components/common/ModalProvider';
import { ResourceAvailabilityDialog } from './ResourceAvailabilityDialog';
import { ResourceRequestUpdateDialog } from './ResourceRequestUpdateDialog';

export const OPERATION_MODALS = {
  RESOURCE_AVAILABILITY_DIALOG: 'resourceAvailability',
  RESOURCE_REQUEST_UPDATE_DIALOG: 'resourceRequestUpdate',
};

export const OperationModals = () => {
  //@ts-ignore
  const { openModalId, openModalMeta, closeModal } = useModal();

  return (
    <>
      {openModalId === OPERATION_MODALS.RESOURCE_AVAILABILITY_DIALOG && (
        <ResourceAvailabilityDialog handleClose={closeModal} open={true} {...openModalMeta} />
      )}
      {openModalId === OPERATION_MODALS.RESOURCE_REQUEST_UPDATE_DIALOG && (
        <ResourceRequestUpdateDialog handleClose={closeModal} open={true} {...openModalMeta} />
      )}
    </>
  );
};
