import { useState } from 'react';
import List from '@mui/material/List';
import Chip from '@mui/material/Chip';
import Check from '@mui/icons-material/Check';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { StyledMenu } from 'features/activityFeeds/assets/activityFeedStyle';

interface Option {
  id: number;
  name: string;
  value: string;
}

interface SingleSelectMenuProps {
  selected: string | undefined;
  label: string;
  options: Option[];
  handleSelect: (newValue: string | undefined) => void;
}

const SingleSelectMenu: React.FC<SingleSelectMenuProps> = ({
  selected,
  label,
  options = [],
  handleSelect,
}) => {
  const [openMenu, setOpenMenu] = useState<HTMLElement | null>(null);

  return (
    <>
      <Chip
        label={`${label}: ${selected ? options?.find((menu) => menu.value === selected)?.name : 'None'} `}
        // sx={{ '& .MuiChip-label': { mt: '2px' } }}
        variant={selected ? 'filled' : 'outlined'}
        onClick={(event) => setOpenMenu(event.currentTarget)}
      />
      <StyledMenu
        anchorEl={openMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={Boolean(openMenu)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => setOpenMenu(null)}
      >
        <List dense>
          {options?.map((option, index) => {
            const isSelected = selected === option.value;
            return (
              <ListItemButton key={index} onClick={() => handleSelect(option.value)}>
                <ListItemIcon>{isSelected ? <Check /> : null}</ListItemIcon>
                <ListItemText primary={option.name} />
              </ListItemButton>
            );
          })}
        </List>
      </StyledMenu>
    </>
  );
};

export default SingleSelectMenu;
