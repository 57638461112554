import { Search } from '@mui/icons-material';
import { Autocomplete, InputAdornment, Stack, TextField } from '@mui/material';
import { Trade } from 'features/trades/api/trades.models';

interface ResourceFilters {
  trade: number | null;
  name: string;
}

interface ResourceAssignmentHeaderProps {
  trades: Trade[];
  filters: ResourceFilters;
  handleTradeFilterChange: (tradeId: number | null) => void;
  handleNameFilterChange: (name: string) => void;
}

export const ResourceAssignmentHeader = ({
  trades,
  filters,
  handleTradeFilterChange,
  handleNameFilterChange,
}: ResourceAssignmentHeaderProps) => {
  const selectedTrade = trades.find((trade) => trade.id === filters.trade) || null;

  return (
    <Stack direction={'row'} p={1} spacing={1}>
      <TextField
        label={'Search Resources'}
        placeholder={'Enter resource name...'}
        size={'small'}
        sx={{ width: 250 }}
        value={filters.name}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <Search color={'action'} />
            </InputAdornment>
          ),
        }}
        onChange={(e) => handleNameFilterChange(e.target.value)}
      />
      <Autocomplete
        getOptionLabel={(option) => option.name}
        options={trades}
        sx={{ width: 250 }}
        value={selectedTrade}
        renderInput={(params) => (
          <TextField
            {...params}
            label={'Filter by Trade'}
            placeholder={'Select trade...'}
            size={'small'}
          />
        )}
        onChange={(_, newValue) => handleTradeFilterChange(newValue?.id || null)}
      />
    </Stack>
  );
};
