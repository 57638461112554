import {
  Card,
  Container,
  TableContainer,
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Chip,
  TablePagination,
  Tooltip,
  IconButton,
  TableCellProps,
} from '@mui/material';
import { Column, Table } from 'components/table/Table';
import { useMemo, useState } from 'react';
import { ResourceRequestDialog } from './ResourceRequestDialog';
import AddIcon from '@mui/icons-material/Add';
import {
  useCreateResourceRequestMutation,
  useGetResourceRequestQuery,
} from '../api/resourceRequest.api';
import { format } from 'date-fns/format';
import { useParams } from 'react-router-dom';
import { useGetTradesQuery } from 'features/trades/api/trades.api';
import { isEmpty } from 'lodash';
import { useGetRolesQuery } from 'features/roles/api/role.api';
import { Notes } from '@mui/icons-material';
import { ResourceRequestMoreOptions } from './ResourceRequestMoreOptions';
import { parseDate } from '@blackhyve/utilities/dates';
import { ResourceRequestStatus } from '../api/resources.models';
import { generateResourceRequestUUID } from 'features/operations/utils/formatter';

type FilterOptions = {
  status?: ResourceRequestStatus | string;
  trade?: number;
  role?: number;
  page: number;
  per_page?: number;
};

const initialState = {
  status: '-1',
  trade: -1,
  role: -1,
  page: 0,
  per_page: 10,
};

export const ResourceRequest = () => {
  const projectId = Number(useParams().projectId);
  const [filters, setFilters] = useState<FilterOptions>(initialState);
  const [orderBy, setOrderBy] = useState('status,desc');
  const [openResourceRequestDialog, setOpenResourceRequestDialog] = useState(false);

  const { data: { entities: tradeEntities = {}, ids: tradeIds = [] } = {} } =
    useGetTradesQuery(undefined);
  const { data: { entities: roleEntities = {}, ids: roleIds = [] } = {} } =
    useGetRolesQuery(undefined);

  const {
    data: { data: resourceRequestList = [], meta = {} } = {},
    isLoading,
    isFetching,
  } = useGetResourceRequestQuery({ project: projectId, order_by: orderBy, ...filters });

  const [createResourceRequest, { isLoading: isCreatingResourceRequest }] =
    useCreateResourceRequestMutation();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFilters({ ...filters, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFilters({ ...filters, page: 1, per_page: parseInt(event.target.value, 10) });
  };

  const handleMenuFilters = (filterName, value) => {
    setFilters((filters) => ({ ...filters, [filterName]: value, page: 1 }));
  };

  const handleCreateResourceRequest = (data) => {
    const requestPayload = {
      ...data,
      start_date: format(data.start_date, 'yyyy-MM-dd'),
      end_date: format(data.end_date, 'yyyy-MM-dd'),
    };
    createResourceRequest({ project_id: projectId, resourceRequest: requestPayload }).then(() => {
      setOpenResourceRequestDialog(false);
    });
  };

  const tradeOptions = useMemo(() => {
    return tradeIds?.map((id) => tradeEntities[id]) || [];
  }, [tradeIds, tradeEntities]);

  const roleOptions = useMemo(() => {
    return roleIds?.map((id) => roleEntities[id]) || [];
  }, [roleIds, roleEntities]);

  return (
    <Container sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5">Resource Requests</Typography>
        <Button
          size="small"
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => setOpenResourceRequestDialog(true)}
        >
          Add Resource Request
        </Button>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <Select
            value={filters.trade}
            onChange={(event) => handleMenuFilters('trade', event.target.value)}
          >
            <MenuItem value={-1}>All Trades</MenuItem>
            {tradeOptions?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <Select
            value={filters.role}
            onChange={(event) => handleMenuFilters('role', event.target.value)}
          >
            <MenuItem value={-1}>All Roles</MenuItem>
            {roleOptions?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 110 }}>
          <Select
            autoWidth
            value={filters.status}
            onChange={(event) =>
              handleMenuFilters('status', event.target.value as ResourceRequestStatus)
            }
          >
            <MenuItem value={'-1'}>All Status</MenuItem>
            <MenuItem value={ResourceRequestStatus.Open}>Open</MenuItem>
            <MenuItem value={ResourceRequestStatus.Closed}>Closed</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Card} sx={{ mt: 2 }}>
        <Table
          columns={columns}
          defaultOrder={'desc'}
          defaultOrderBy={'status'}
          handleSort={(property, newSortOrder) => setOrderBy(`${property},${newSortOrder}`)}
          isFetching={isFetching}
          isLoading={isLoading}
          meta={{ trades: tradeEntities, roles: roleEntities }}
          rows={resourceRequestList}
        />
        <TablePagination
          component="div"
          count={meta.total}
          page={filters.page}
          rowsPerPage={10}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {openResourceRequestDialog && (
        <ResourceRequestDialog
          handleClose={() => setOpenResourceRequestDialog(false)}
          isLoading={isCreatingResourceRequest}
          open={openResourceRequestDialog}
          onSubmit={handleCreateResourceRequest}
        />
      )}
    </Container>
  );
};

const columns = [
  {
    field: 'uuid',
    label: 'UUID',
    sort: true,
    render: (resourceRequest) => {
      return generateResourceRequestUUID(resourceRequest.id);
    },
  },
  {
    field: 'trade',
    label: 'Trade',
    sort: true,
    render: (resourceRequest, meta) => {
      const trade = !isEmpty(meta.trades) ? meta.trades[resourceRequest.trade_id] : {};
      return trade?.name || '';
    },
  },
  {
    field: 'role',
    label: 'Role',
    sort: true,
    render: (resourceRequest, meta) => {
      const role = !isEmpty(meta.roles) ? meta.roles[resourceRequest.role_id] : {};
      return role?.name || '';
    },
  },
  {
    field: 'start_date',
    label: 'Start Date',
    sort: true,
    render: (resourceRequest) => {
      return parseDate(resourceRequest.start_date).toLocaleDateString();
    },
  },
  {
    field: 'end_date',
    label: 'End Date',
    sort: true,
    render: (resourceRequest) => {
      return parseDate(resourceRequest.end_date).toLocaleDateString();
    },
  },
  {
    field: 'resource_count',
    label: 'Resource Count',
  },
  {
    field: 'assigned_count',
    label: 'Assigned Count',
    render: (resourceRequest) => {
      return resourceRequest?.assignments?.length ? resourceRequest?.assignments?.length : '-';
    },
  },
  {
    field: 'status',
    label: 'Status',
    sort: true,
    render: (resourceRequest) => {
      const isClosed = resourceRequest.status === 'closed';
      return (
        <Chip
          color={isClosed ? 'success' : 'error'}
          label={isClosed ? 'Closed' : 'Open'}
          variant="outlined"
        />
      );
    },
  },
  {
    field: 'notes',
    label: 'Notes',
    sort: false,
    render: (resourceRequest) => {
      return (
        <Tooltip
          arrow
          placement="top"
          title={
            <Typography maxHeight={500} overflow={'auto'}>
              {resourceRequest?.notes || 'NA'}
            </Typography>
          }
        >
          <IconButton>
            <Notes />
          </IconButton>
        </Tooltip>
      );
    },
  },
  {
    field: 'moreOptions',
    label: '',
    cellProps: { align: 'right' } satisfies TableCellProps, // No idea why TS was yelling at me `Type 'string' is not assignable to type '"right" | "center" | "left" | "inherit" | "justify" | undefined'`
    render: (resourceRequest) => (
      <div onClick={(event) => event.stopPropagation()}>
        <ResourceRequestMoreOptions resourceRequest={resourceRequest} />
      </div>
    ),
  },
];
