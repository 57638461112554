import FeatureFlag, { features } from '@blackhyve/common/src/components/FeatureFlag';
import SvgBlackhyveIcon from '@blackhyve/common/src/icons/BlackhyveIcon';
import { BlackHyveLogoWithText } from '@blackhyve/common/src/icons/BlackHyveLogoWithText';
import ProductIcon from '@blackhyve/common/src/icons/Product';
import CompanyIcon from '@blackhyve/common/src/icons/CompanyIcon';
import SvgPortfolioIcon from '@blackhyve/common/src/icons/Portfolio';
import RecipeIconComponent from '@blackhyve/common/src/icons/Recipe';
import SvgTaskIcon from '@blackhyve/common/src/icons/Task';
import {
  Analytics,
  Close,
  Engineering,
  Menu,
  Notifications,
  PendingActions,
  QuestionMark,
  Timeline,
} from '@mui/icons-material';
import LabelIcon from '@mui/icons-material/Label';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  ThemeProvider,
  Tooltip,
} from '@mui/material';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import { RequireRole } from 'features/auth/components/RequireRole';
import { Label as LabelComponent } from 'features/labels/components/Label';
import { UserMenu } from 'features/users/components/UserMenu';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { navTheme } from './Nav';

export function MobileNav() {
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);

  const toggleNavDrawer = () => setIsNavDrawerOpen((prev) => !prev);

  return (
    <ThemeProvider theme={navTheme}>
      <Box
        alignItems={'center'}
        bgcolor={'#2C2C2C'}
        direction={'row'}
        display={'flex'}
        displayPrint={'none'}
        justifyContent={'space-between'}
        width={1}
      >
        {/* Left Section */}
        <Box alignItems="center" display="flex">
          <IconButton size={'large'} onClick={toggleNavDrawer}>
            <Menu fontSize={'inherit'} />
          </IconButton>
        </Box>

        {/* Center Section */}
        <Box
          alignItems="center"
          display="flex"
          flex={1}
          justifyContent="center"
          left={'50%'}
          position={'absolute'}
          sx={{ translate: '-50%' }}
        >
          <SvgIcon fontSize={'large'}>
            <SvgBlackhyveIcon />
          </SvgIcon>
        </Box>

        {/* Right Section */}
        <Box alignItems="center" display="flex">
          <UserMenu
            PopoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }}
          />
        </Box>
      </Box>
      <MobileNavDrawer open={isNavDrawerOpen} onClose={toggleNavDrawer} />
    </ThemeProvider>
  );
}

const MobileNavDrawer = ({ onClose, open }) => {
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));

  const handleOpenHelp = () => {
    window.Beacon('config', {
      display: {
        position: 'left',
        horizontalOffset: 60,
      },
      enableFabAnimation: false,
    });
    window.Beacon('toggle');
    window.Beacon('navigate', '/answers/');
  };

  return (
    <Drawer open={open} PaperProps={{ sx: { width: 'min(300px, 100%)' } }} onClose={onClose}>
      <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'} px={2} py={1}>
        <BlackHyveLogoWithText height={'32px'} />

        <IconButton onClick={onClose}>
          <Tooltip title={'Close'}>
            <Close />
          </Tooltip>
        </IconButton>
      </Stack>
      <Divider />
      <List>
        <NavDrawerLink
          text={'Projects'}
          to={`/workspace/${workspaceId}/projects`}
          icon={
            <SvgIcon>
              <SvgPortfolioIcon color={'currentColor'} height={undefined} width={undefined} />
            </SvgIcon>
          }
          onClick={onClose}
        />
        <NavDrawerLink
          text={'Task Portfolio'}
          to={`/workspace/${workspaceId}/tasks/gantt`}
          icon={
            <SvgIcon>
              <SvgTaskIcon />
            </SvgIcon>
          }
          onClick={onClose}
        />
        <NavDrawerLink
          icon={<Notifications />}
          text={'Notifications'}
          to={`/workspace/${workspaceId}/notifications`}
          onClick={onClose}
        />
        <NavDrawerLink
          icon={<PendingActions color={'currentColor'} />}
          text={<LabelComponent labelKey="stuck_point" plural={true} />}
          to={`/workspace/${workspaceId}/stuck-points`}
          onClick={onClose}
        />
        <NavDrawerLink
          icon={<LabelIcon color={'currentColor'} />}
          text={'Tags'}
          to={`/workspace/${workspaceId}/tags`}
          onClick={onClose}
        />
        <RequireRole exclude roles={['contractor_contact']}>
          <NavDrawerLink
            icon={<Timeline />}
            text={'Activity Feed'}
            to={`/workspace/${workspaceId}/activity-feeds`}
            onClick={onClose}
          />
        </RequireRole>
        <FeatureFlag name={features.ANALYTICS}>
          <RequireRole exclude roles={['contractor_contact']}>
            <NavDrawerLink
              icon={<Analytics />}
              text={'Analytics'}
              to={{ pathname: `/workspace/${workspaceId}/analytics` }}
              onClick={onClose}
            />
          </RequireRole>
        </FeatureFlag>
        <RequireRole exclude roles={['contractor_contact']}>
          <NavDrawerLink
            text={'Recipes'}
            to={`/workspace/${workspaceId}/recipes`}
            icon={
              <SvgIcon>
                <RecipeIconComponent color="currentColor" />
              </SvgIcon>
            }
            onClick={onClose}
          />
        </RequireRole>
        <RequireRole exclude roles={['contractor_contact']}>
          <NavDrawerLink
            text={'Companies'}
            to={`/workspace/${workspaceId}/companies`}
            icon={
              <SvgIcon>
                <CompanyIcon />
              </SvgIcon>
            }
            onClick={onClose}
          />
        </RequireRole>

        <NavDrawerLink
          text={'Resources'}
          to={`/workspace/${workspaceId}/resources`}
          icon={
            <SvgIcon>
              <Engineering color={'currentColor'} />
            </SvgIcon>
          }
          onClick={onClose}
        />

        <FeatureFlag name={features.BUDGETS}>
          <RequireRole exclude roles={['contractor_contact']}>
            <NavDrawerLink
              text={'Products'}
              to={`/workspace/${workspaceId}/products`}
              icon={
                <SvgIcon>
                  <ProductIcon color="currentColor" />
                </SvgIcon>
              }
              onClick={onClose}
            />
          </RequireRole>
        </FeatureFlag>
      </List>
      <Divider sx={{ mt: 'auto' }} />
      <ListItem disablePadding>
        <ListItemButton onClick={handleOpenHelp}>
          <ListItemIcon>
            <QuestionMark />
          </ListItemIcon>
          <ListItemText primary={'Help'} />
        </ListItemButton>
      </ListItem>
    </Drawer>
  );
};

const NavDrawerLink = ({ to, icon, text, onClick }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        end
        component={to && NavLink}
        to={to}
        sx={{
          '&.active, &.active svg': { color: (theme) => theme.palette.primary.main },
        }}
        onClick={onClick}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};
